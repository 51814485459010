import React, { useEffect, useState } from "react";
import "../LandingPage/LandingPage.css";
import "../Team/CreateTeam.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import mainlogo from "../../assets/img/banner.jpg";
import NewCibona from "../../assets/img/Cibona-New-logo.png";
import { Link, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsDot, BsFilterLeft } from "react-icons/bs";
import { IoFastFoodOutline, IoLocationOutline } from "react-icons/io5";
import { MdDateRange } from "react-icons/md";
import { FaCar } from "react-icons/fa";
import CibonaZagreb from "../../assets/img/CIBONA-ZAGREB-logo.png";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import moment from "moment";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import { IoIosSearch } from "react-icons/io";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function TeamPage() {
  const [searchParams] = useSearchParams();
  const teamid = searchParams.get("teamid");
  console.log(teamid);

  const navigate = useNavigate();
  const [allData, setAllData] = useState(false);
  const [seasonPassbtn, setSeasonPassbtn] = useState(false);
  const [bundlePassbtn, setBundlePassbtn] = useState(false);
  const [calendarbtn, setCalendarbtn] = useState(false);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [startDate, setStartDate] = useState(tomorrow);
  const tomorrowsingle = new Date();
  tomorrowsingle.setDate(tomorrowsingle.getDate() + 1);
  const [startDatesingle, setStartDatesingle] = useState(tomorrowsingle);
  const [events, setevents] = useState([]);
  const [AllEvents, setAllEvents] = useState([]);
  const [AllEventssingle, setAllEventssingle] = useState([]);
  const [SeasonalData, setSeasonalData] = useState([]);
  const [BundleData, setBundleData] = useState([]);
  const [TeamDeatils, setTeamDeatils] = useState([]);
  const [allEventData, setAllEventData] = useState([]);
  const [TeamList, setTeamList] = useState([]);
  const [dataforfilter, setDataforfilter] = useState([]);
  const [eventsallgame, seteventsallgame] = useState([]);
  const [calendarview, setCalendarview] = useState(false);

  // language variable
  const [text_emptyseason, setText_emptyseason] = useState(
    "No Season Pass Found"
  );
  const [text_nobundlefound, setText_nobundlefound] =
    useState("No Bundle Found");

  // new language variable
  const [text_single_match, setText_single_match] = useState(
    "Single Match Tickets"
  );
  const [text_seasons_pass, setText_seasons_pass] = useState("Seasons Pass");
  const [text_bundlepackages, setText_bundlepackages] =
    useState("Bundle Packages");
  const [text_calendar, setText_calendar] = useState("Calendar");
  const [text_about, setText_about] = useState("About");
  const [text_location, setText_location] = useState("Location");
  const [text_teams, setText_teams] = useState("Teams");
  const [text_calendarview, setText_calendarview] = useState("Calendar View");
  const [text_Sort, setText_Sort] = useState("Sort");
  const [text_Upcoming, setText_Upcoming] = useState("Upcoming");
  const [text_mostpopular, setText_mostpopular] = useState("Most Popular");
  const [text_Recommended, setText_Recommended] = useState("Recommended");
  const [text_noeventfound, setText_noeventfound] = useState("No Event Found");
  const [text_buytickets, setText_buytickets] = useState("Buy Tickets");
  const [text_seasons_passes, setText_seasons_passes] =
    useState("Seasons Passes");
  const [text_events, setText_events] = useState("Events");
  const [text_fooddrink_included, setText_fooddrink_included] = useState(
    "Food and drink included"
  );
  const [text_na, setText_na] = useState("NA");
  const [text_parkingpass, setText_parkingpass] = useState("Parking Pass");
  const [text_viewdetails, setText_viewdetails] = useState("View Details");
  const [text_upcoming_games, setText_upcoming_games] =
    useState("Upcoming Games");
  const [text_viewMore, setText_viewMore] = useState("View More");
  const [text_all_games, setText_all_games] = useState("All Games");
  const [text_home, setText_home] = useState("Home");
  const [text_search, setText_search] = useState("Search");

  const Breadcrumbs = ({ breadcrumbs }) => {
    return (
      <div className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <span key={index}>
            {index > 0 && <span className="breadcrumbs-separator">/</span>}
            {breadcrumb.link ? (
              <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
            ) : (
              <span>{breadcrumb.label}</span>
            )}
          </span>
        ))}
      </div>
    );
  };

  const breadcrumbs = [
    { label: text_home, link: "/" },
    { label: text_teams, link: `/team-page?teamid=${teamid}` },
    { label: TeamDeatils[0]?.teamname },
  ];
  const getUpcomingEvent = () => {
    var API_URL = API_DOMAIN + "event/get/futureevent";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("upcomingevents ", response.data.data.event);
        var res = response.data.data;
        const sortdata = res.event;
        sortdata.sort(
          (a, b) => new Date(a?.StartDateSort) - new Date(b?.StartDateSort)
        );
        console.log("sortdata", sortdata);
        setAllEventData(sortdata);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const getTeamDetails = () => {
    var API_URL = API_DOMAIN + `team/select?teamid=${teamid}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("Team details", response.data.data);
        setTeamDeatils(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const getAllEvent = () => {
    var API_URL = API_DOMAIN + `event/get/byteamid?teamid=${teamid}&limit=10`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("all events ", response.data);
        var res = response.data.data.events;
        setAllEvents(res);
        setAllEventssingle(res);
        setDataforfilter(res);
        console.log(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const handleDateSetAllgame = (dateInfo) => {
    const startDate = new Date(dateInfo.start); // Start of visible range
    const formattedMonth = moment(startDate).format("MM YYYY"); // Format for the API
    console.log(formattedMonth);

    const url = API_DOMAIN + `event/get/byteamid?teamid=${teamid}`;
    console.log(url);

    getCalendarDataAllgame(url);
  };
  const getCalendarDataAllgame = (url) => {
    var API_URL = url;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("month wise events ", response.data.data.events);

        var res = response.data.data.events;
        const formattedEvents = res.map((event) => ({
          id: event.event_unique_id,
          title: event.EventTitle, // Adjust based on your API response
          start: event.StartDate, // Ensure date is in ISO 8601 format
          end: event.EndDate,
          starttime: event.StartTime, // Ensure date is in ISO 8601 format
          endtime: event.EndTime, // Optional
          url: `/event-details/${event.event_unique_id}`,
        }));
        console.log(formattedEvents);

        seteventsallgame(formattedEvents);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const handleEventClickAllgame = (info) => {
    info.jsEvent.preventDefault(); // Prevent default behavior
    const eventUrl = info.event.url; // Access the custom URL
    console.log(eventUrl);

    if (eventUrl) {
      navigate(eventUrl); // Redirect to the event details page
    }
  };

  const getspecificdateData = () => {
    var API_URL =
      API_DOMAIN +
      "event/get/byteamid/specificdate?teamid=fd683cf5-b7b4-4f5b-af1f-5e7c26f793a3&specificDate=07-10-2025";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("specific date wise event ", response.data.data);

        var res = response.data.data;
        console.log(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const handleDatesSet = (dateInfo) => {
    const startDate = new Date(dateInfo.start); // Start of visible range
    const formattedMonth = moment(startDate).format("MM YYYY"); // Format for the API
    console.log(formattedMonth);

    const url =
      API_DOMAIN +
      `event/get/byteamid/calendar?teamid=${teamid}&month=${formattedMonth}`;
    console.log(url);

    getCalendarData(url);
  };
  const getCalendarData = (url) => {
    var API_URL = url;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("month wise events ", response.data.data[0].events);

        var res = response.data.data[0].events;
        const formattedEvents = res.map((event) => ({
          id: event.event_unique_id,
          title: event.EventTitle, // Adjust based on your API response
          start: event.StartDate, // Ensure date is in ISO 8601 format
          end: event.EndDate,
          starttime: event.StartTime, // Ensure date is in ISO 8601 format
          endtime: event.EndTime, // Optional
          url: `/event-details/${event.event_unique_id}`,
        }));
        console.log(formattedEvents);

        setevents(formattedEvents);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const SeasonalCard = () => {
    var API_URL = API_DOMAIN + `seasonal/getall/byteamid?teamid=${teamid}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("seasonal data ", response.data.data);
        var res = response.data.data;
        setSeasonalData(res);
        console.log(response.data.data?.event_details);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const BundlegetData = () => {
    var API_URL = API_DOMAIN + `bundle/getall/byteamid?teamid=${teamid}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("bundle data ", response.data.data);
        var res = response.data.data;
        setBundleData(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    getUpcomingEvent();
    getTeamDetails();
    getAllEvent();
    getCalendarData();
    SeasonalCard();
    BundlegetData();
    getTeamList();
  }, [teamid]);
  const handleEventClick = (info) => {
    info.jsEvent.preventDefault(); // Prevent default behavior
    const eventUrl = info.event.url; // Access the custom URL
    console.log(eventUrl);

    if (eventUrl) {
      navigate(eventUrl); // Redirect to the event details page
    }
  };
  const getTeamList = () => {
    var API_URL = API_DOMAIN + "team/getall";
    axios
      .get(API_URL)
      .then(function (response) {
        var res = response.data.data;
        console.log("Team data", res);
        setTeamList(res);
      })
      .catch(function (error) {
        console.log(error);
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const dateSelectFilter = (date) => {
    console.log(moment.utc(date).format("DD MMM, yyyy"));
    const arr = dataforfilter.filter(
      (e) =>
        moment.utc(e?.StartDate).format("DD MMM, yyyy") ===
        moment.utc(date).format("DD MMM, yyyy")
    );
    setAllEvents(arr);
    console.log(arr);
  };
  const dateSelectFiltersingle = (date) => {
    console.log(moment.utc(date).format("DD MMM, yyyy"));
    const arr = dataforfilter.filter(
      (e) =>
        moment.utc(e?.StartDate).format("DD MMM, yyyy") ===
        moment.utc(date).format("DD MMM, yyyy")
    );
    setAllEventssingle(arr);
    console.log(arr);
  };
  const filterDataTeam = (selteamid) => {
    // teamid;
    const arr = dataforfilter.filter(
      (e) => e.team02 === selteamid || e.team01 === selteamid
    );
    console.log(arr);
    setAllEvents(arr);
  };
  const filterSingleDataTeam = (selteamid) => {
    // teamid;
    const arr = dataforfilter.filter(
      (e) => e.team02 === selteamid || e.team01 === selteamid
    );
    console.log(arr);
    setAllEventssingle(arr);
  };

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_emptyseason(
        xpath.select1("/resources/string[@name='text_emptyseason']", doc)
          .firstChild.data
      );
      setText_nobundlefound(
        xpath.select1("/resources/string[@name='text_nobundlefound']", doc)
          .firstChild.data
      );
      setText_single_match(
        xpath.select1("/resources/string[@name='text_single_match']", doc)
          .firstChild.data
      );
      setText_seasons_pass(
        xpath.select1("/resources/string[@name='text_seasons_pass']", doc)
          .firstChild.data
      );
      setText_bundlepackages(
        xpath.select1("/resources/string[@name='text_bundlepackages']", doc)
          .firstChild.data
      );
      setText_calendar(
        xpath.select1("/resources/string[@name='text_calendar']", doc)
          .firstChild.data
      );
      setText_about(
        xpath.select1("/resources/string[@name='text_about']", doc).firstChild
          .data
      );
      setText_location(
        xpath.select1("/resources/string[@name='text_location']", doc)
          .firstChild.data
      );
      setText_teams(
        xpath.select1("/resources/string[@name='text_teams']", doc).firstChild
          .data
      );
      setText_calendarview(
        xpath.select1("/resources/string[@name='text_calendarview']", doc)
          .firstChild.data
      );
      setText_Sort(
        xpath.select1("/resources/string[@name='text_Sort']", doc).firstChild
          .data
      );
      setText_Upcoming(
        xpath.select1("/resources/string[@name='text_Upcoming']", doc)
          .firstChild.data
      );
      setText_mostpopular(
        xpath.select1("/resources/string[@name='text_mostpopular']", doc)
          .firstChild.data
      );
      setText_Recommended(
        xpath.select1("/resources/string[@name='text_Recommended']", doc)
          .firstChild.data
      );
      setText_noeventfound(
        xpath.select1("/resources/string[@name='text_noeventfound']", doc)
          .firstChild.data
      );
      setText_buytickets(
        xpath.select1("/resources/string[@name='text_buytickets']", doc)
          .firstChild.data
      );
      setText_seasons_passes(
        xpath.select1("/resources/string[@name='text_seasons_passes']", doc)
          .firstChild.data
      );
      setText_events(
        xpath.select1("/resources/string[@name='text_events']", doc).firstChild
          .data
      );
      setText_fooddrink_included(
        xpath.select1("/resources/string[@name='text_fooddrink_included']", doc)
          .firstChild.data
      );
      setText_na(
        xpath.select1("/resources/string[@name='text_na']", doc).firstChild.data
      );
      setText_parkingpass(
        xpath.select1("/resources/string[@name='text_parkingpass']", doc)
          .firstChild.data
      );
      setText_viewdetails(
        xpath.select1("/resources/string[@name='text_viewdetails']", doc)
          .firstChild.data
      );
      setText_upcoming_games(
        xpath.select1("/resources/string[@name='text_upcoming_games']", doc)
          .firstChild.data
      );
      setText_viewMore(
        xpath.select1("/resources/string[@name='text_viewMore']", doc)
          .firstChild.data
      );
      setText_all_games(
        xpath.select1("/resources/string[@name='text_all_games']", doc)
          .firstChild.data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_search(
        xpath.select1("/resources/string[@name='text_search']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };

  return (
    <>
      <Header />
      <div className="wrapper" id="otherPages">
        <div className="hero-banner-landing">
          <img className="team-landing-main-img" src={mainlogo} alt="" />
          <div className="container">
            <div className="league-page-search league-page-search_league">
              <input
                id="outlined-basic"
                variant="outlined"
                className="league-page-search-input"
                fullWidth
                placeholder={text_search}
                label="Search"
              />
              <div className="league-page-search-icon">
                <IoIosSearch />
              </div>
            </div>
            <div className="league-page-search-breadcrumb league-page-search-breadcrumb-legue">
              {/* Home / Leagues / ABA League */}
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          </div>
          {TeamDeatils.map((v) => {
            return (
              <>
                <div className="container">
                  <div className="league-page-search-logo">
                    <img
                      src={v.teamLogo === "" ? CibonaZagreb : v.teamLogo}
                      alt=""
                      className="team-logo"
                    />
                  </div>
                </div>
                <div>
                  {" "}
                  <h2 className="container mt-2">
                    <img
                      src={v.teamLogo === "" ? NewCibona : v.teamLogo}
                      alt=""
                      className="team-logo"
                    />{" "}
                    <b>{v.teamname}</b>
                    <p className="">{v.teamDescription}</p>
                  </h2>
                </div>
              </>
            );
          })}
        </div>
        <div className="container">
          <div className="d-flex flex-wrap gap-3">
            <button
              className={
                allData === true ? "new-main-tab selected " : "new-main-tab "
              }
              onClick={() => [
                setAllData(true),
                setSeasonPassbtn(false),
                setBundlePassbtn(false),
                setCalendarbtn(false),
              ]}
            >
              {text_single_match}
            </button>
            <button
              className={
                seasonPassbtn === true
                  ? "new-main-tab selected "
                  : "new-main-tab "
              }
              onClick={() => [
                setSeasonPassbtn(true),
                setBundlePassbtn(false),
                setAllData(false),
                setCalendarbtn(false),
              ]}
            >
              {text_seasons_pass}
            </button>
            <button
              className={
                bundlePassbtn === true
                  ? "new-main-tab selected "
                  : "new-main-tab "
              }
              onClick={() => [
                setSeasonPassbtn(false),
                setBundlePassbtn(true),
                setAllData(false),
                setCalendarbtn(false),
              ]}
            >
              {text_bundlepackages}
            </button>
            <button
              className={
                calendarbtn === true
                  ? "new-main-tab  calendar-tab selected "
                  : "new-main-tab  calendar-tab"
              }
              onClick={() => [
                setSeasonPassbtn(false),
                setBundlePassbtn(false),
                setAllData(false),
                setCalendarbtn(true),
              ]}
            >
              {text_calendar}
            </button>
            <button className="new-main-tab calendar-tab">{text_about}</button>
          </div>
          {allData === true ? (
            <>
              <div className="main-title position-relative mt-4">
                <h4 className="fs-2 fw-bold">{text_single_match}</h4>
                <div className="d-flex justify-content-between flex-wrap gap-2">
                  <div className="d-flex gap-3">
                    <div className="form-control filter-team d-flex">
                      <MdDateRange className="icon-team" />
                      <DatePicker
                        className="h_40 mx-1 form-control form-control-BR w-100"
                        selected={startDatesingle}
                        dateFormat="dd MMM,yyyy"
                        onChange={(date) => [
                          setStartDatesingle(date),
                          dateSelectFiltersingle(date),
                        ]}
                      />
                    </div>
                    <div className="form-control filter-team d-flex">
                      <IoLocationOutline className="icon-team" />
                      <input
                        type="text"
                        className="h_40 form-control form-control-BR w-100"
                        name=""
                        id=""
                        placeholder={text_location}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <div className="form-control filter-team d-flex">
                      <BsFilterLeft className="icon-team" />
                      <select
                        name=""
                        id=""
                        onChange={(e) => filterSingleDataTeam(e.target.value)}
                        className="h_40 form-control form-control-BR w-100"
                      >
                        <option value=""> {text_teams}</option>
                        {TeamList?.filter((value) => {
                          return value.teamid !== teamid;
                        }).map((v) => {
                          return (
                            <>
                              <option
                                value={v.teamid}
                                className="form-control-BR"
                              >
                                {" "}
                                {v.teamname}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-control filter-team d-flex">
                      <BsFilterLeft className="icon-team" />
                      {calendarview === true ? (
                        <>
                          <button
                            className="h_40 form-control form-control-BR w-100 text-start"
                            onClick={() => setCalendarview(false)}
                          >
                            {text_single_match}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="h_40 form-control form-control-BR w-100 text-start"
                            onClick={() => setCalendarview(true)}
                          >
                            {text_calendarview}
                          </button>
                        </>
                      )}
                    </div>
                    <div className="form-control filter-team d-flex">
                      <BsFilterLeft className="icon-team" />
                      <select
                        name=""
                        id=""
                        className="h_40 form-control form-control-BR w-100"
                      >
                        <option
                          value=""
                          // className="form-control-BR"
                        >
                          {text_Sort}
                        </option>
                        <option value="upcoming" className="form-control-BR">
                          {" "}
                          {text_Upcoming}
                        </option>
                        <option value="mostpopular" className="form-control-BR">
                          {" "}
                          {text_mostpopular}
                        </option>
                        <option value="recommended" className="form-control-BR">
                          {" "}
                          {text_Recommended}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {calendarview === true ? (
                  <>
                    <div className="month-calendar-style">
                      {/* <div> */}
                      <FullCalendar
                        // className="home-month-calendar"
                        // showNonCurrentDates={false}
                        timeZone="UTC"
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        events={eventsallgame}
                        // eventContent={renderEventContent} // Customize event display
                        eventClick={handleEventClickAllgame}
                        datesSet={handleDateSetAllgame}
                        // headerToolbar={{
                        //   left: "prev,next today",
                        //   center: "title",
                        //   right: "dayGridMonth",
                        // }}
                        dayHeaderContent={(args) => {
                          // Format weekdays as 'Mon', 'Tue', etc.
                          return new Intl.DateTimeFormat("en", {
                            weekday: "short",
                          }).format(args.date);
                        }}
                        dayCellContent={(args) => {
                          // Format and display only the day number
                          return args.date.getDate(); // Get only the day (e.g., 1, 2, 3, etc.)
                        }}
                        weekends={true} // Show weekends
                        eventContent={(eventInfo) => (
                          <div className="calendar-event">
                            <h4 className="calendar-event-title mt-2">
                              {eventInfo.event.title}
                              {/* <br />
                              <p>
                                {" "}
                                {moment(eventInfo.event.starttime).format(
                                  "h:mmA"
                                )}
                              </p> */}
                            </h4>
                          </div>
                        )}
                      />
                      {/* </div> */}
                    </div>
                  </>
                ) : (
                  <>
                    {AllEventssingle.length === 0 ? (
                      <>
                        <div className="container text-center mt-5">
                          <h2>{text_noeventfound}</h2>{" "}
                        </div>
                      </>
                    ) : (
                      <>
                        {AllEventssingle.map((e) => {
                          return (
                            <>
                              <div className="new-card-team d-flex flex-wrap justify-content-between  mt-3">
                                <div className="d-flex gap-2 flex-wrap">
                                  <img
                                    src={
                                      e.EventImagePath === "path"
                                        ? img1
                                        : e.EventImagePath
                                    }
                                    alt=""
                                    className="card-img-team"
                                  />
                                  <div className="m-2 responsive-team-card">
                                    <h4> {e.EventTitle}</h4>
                                    <span>
                                      {moment
                                        .utc(e?.StartDate)
                                        .format("DD MMM, yyyy")}{" "}
                                      • {moment.utc(e.StartDate).format("ddd")},{" "}
                                      {moment.utc(e?.StartTime).format("h:mmA")}
                                    </span>{" "}
                                    <br />
                                    <div className="d-flex gap-1">
                                      {e.venuedetails.length === 0 ? (
                                        ""
                                      ) : (
                                        <>
                                          <h4>
                                            <IoLocationOutline className="location-icon" />
                                          </h4>
                                          <span>
                                            {e?.venuedetails[0].venuename} ({" "}
                                            {e?.venuedetails[0].address},{" "}
                                            {e?.venuedetails[0].city},{" "}
                                            {e?.venuedetails[0].state},{" "}
                                            {e?.venuedetails[0].country},{" "}
                                            {e?.venuedetails[0].zipcode})
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <button
                                  className="buybtn-team mx-3"
                                  onClick={() =>
                                    navigate(
                                      `/event-details/${e?.event_unique_id}`
                                    )
                                  }
                                >
                                  {text_buytickets}
                                </button>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              {seasonPassbtn === true ? (
                <>
                  <div className="main-title position-relative mt-4">
                    <h4 className="fs-2 fw-bold">{text_seasons_passes}</h4>
                    {SeasonalData.length === 0 ? (
                      <>
                        <div className="main-card mt-4">
                          <h3 className="text-center font-16 mt-3 fw-bold">
                            {text_emptyseason}
                          </h3>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="row"
                          data-ref="event-filter-content"
                          id="otherPages"
                        >
                          {SeasonalData.map((e, index) => {
                            return (
                              <>
                                <div
                                  className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                                  data-ref="mixitup-target"
                                >
                                  <div className="main-card mt-4">
                                    <div className="event-thumbnail">
                                      <Link to={"#"} className="thumbnail-img">
                                        <img
                                          src={
                                            e?.url === "" ||
                                            e?.url === undefined
                                              ? img1
                                              : e?.url
                                          }
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                    <div className="event-content">
                                      <Link
                                        to={"#"}
                                        className="event-title event-css"
                                      >
                                        {e?.ticketname}
                                      </Link>

                                      <div className="publish-date">
                                        <p className="season-details-team">
                                          {e.event_count} {text_events}{" "}
                                          <BsDot
                                            style={{
                                              width: "23px",
                                              height: "23px",
                                            }}
                                          />
                                          {e?.currency} {e?.price}
                                          <br />{" "}
                                          {e?.isFoodAndBeverages === true ? (
                                            <>
                                              <IoFastFoodOutline
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />{" "}
                                              {text_fooddrink_included}
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <IoFastFoodOutline
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />{" "}
                                              {text_na}
                                            </>
                                          )}
                                          <br />
                                          {e.isParking === true ? (
                                            <>
                                              <FaCar
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />{" "}
                                              {text_parkingpass} (
                                              {e.ParkingLevel === ""
                                                ? ""
                                                : e.ParkingLevel}
                                              )
                                            </>
                                          ) : (
                                            <>
                                              <FaCar
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />{" "}
                                              {text_na}
                                            </>
                                          )}
                                        </p>
                                      </div>
                                      <button
                                        className="main-btn btn-hover w-100 mt-4"
                                        onClick={() =>
                                          navigate(
                                            `/seasonalevent/${e?.seasonalticketid}`,
                                            {
                                              state: {
                                                seasonalticketid:
                                                  e?.seasonalticketid,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        {text_viewdetails}{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {bundlePassbtn === true ? (
                    <>
                      <div className="main-title position-relative mt-4">
                        <h4 className="fs-2 fw-bold">{text_bundlepackages}</h4>
                        {BundleData.length === 0 ? (
                          <>
                            <div className="main-card mt-4">
                              <h3 className="text-center font-16 mt-3 fw-bold">
                                {text_nobundlefound}
                              </h3>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="row"
                              data-ref="event-filter-content"
                              id="otherPages"
                            >
                              {BundleData.map((e, index) => {
                                return (
                                  <>
                                    <div
                                      className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                                      data-ref="mixitup-target"
                                    >
                                      <div className="main-card mt-4">
                                        <div className="event-thumbnail">
                                          <Link
                                            to={"#"}
                                            className="thumbnail-img"
                                          >
                                            <img
                                              src={
                                                e?.url === "" ||
                                                e?.url === undefined
                                                  ? img1
                                                  : e?.url
                                              }
                                              alt=""
                                            />
                                          </Link>
                                        </div>
                                        <div className="event-content">
                                          <Link
                                            to={"#"}
                                            className="event-title event-css"
                                          >
                                            {e?.ticketname}
                                          </Link>

                                          <div className="publish-date">
                                            <p className="season-details-team">
                                              {e.event_count} {text_events}{" "}
                                              <BsDot
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />
                                              {e?.currency} {e?.price}
                                              <br />{" "}
                                              {e?.isFoodAndBeverages ===
                                              true ? (
                                                <>
                                                  <IoFastFoodOutline
                                                    style={{
                                                      width: "23px",
                                                      height: "23px",
                                                    }}
                                                  />{" "}
                                                  {text_fooddrink_included}
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  <IoFastFoodOutline
                                                    style={{
                                                      width: "23px",
                                                      height: "23px",
                                                    }}
                                                  />{" "}
                                                  {text_na}
                                                </>
                                              )}
                                              <br />
                                              {e.isParking === true ? (
                                                <>
                                                  <FaCar
                                                    style={{
                                                      width: "23px",
                                                      height: "23px",
                                                    }}
                                                  />{" "}
                                                  {text_parkingpass} (
                                                  {e.ParkingLevel === ""
                                                    ? ""
                                                    : e.ParkingLevel}
                                                  )
                                                </>
                                              ) : (
                                                <>
                                                  <FaCar
                                                    style={{
                                                      width: "23px",
                                                      height: "23px",
                                                    }}
                                                  />{" "}
                                                  {text_na}
                                                </>
                                              )}
                                            </p>
                                          </div>
                                          <button
                                            className="main-btn btn-hover w-100 mt-4"
                                            onClick={() =>
                                              navigate(
                                                `/bundleevent/${e?.bundleticketid}`,
                                                {
                                                  state: {
                                                    bundleticketid:
                                                      e?.bundleticketid,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            {text_viewdetails}{" "}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {calendarbtn === true ? (
                        <>
                          <h1 className="mt-4">{text_calendar}</h1>
                          <div className="month-calendar-style">
                            <FullCalendar
                              // showNonCurrentDates={false}
                              events={events}
                              defaultView="dayGridMonth"
                              plugins={[
                                dayGridPlugin,
                                interactionPlugin,
                                momentPlugin,
                              ]}
                              dayHeaderContent={(args) => {
                                // Format weekdays as 'Mon', 'Tue', etc.
                                return new Intl.DateTimeFormat("en", {
                                  weekday: "short",
                                }).format(args.date);
                              }}
                              dayCellContent={(args) => {
                                // Format and display only the day number
                                return args.date.getDate(); // Get only the day (e.g., 1, 2, 3, etc.)
                              }}
                              weekends={true}
                              eventClick={handleEventClick}
                              datesSet={handleDatesSet}
                              eventContent={(eventInfo) => (
                                <div className="calendar-event">
                                  <h4>
                                    <span className="calendar-event-title pb-3 mt-2">
                                      {eventInfo.event.title}
                                    </span>
                                    {/* <br />
                                    <span className="calendar-event-time">
                                      {moment(eventInfo.event.starttime).format(
                                        "h:mmA"
                                      )}
                                    </span> */}
                                  </h4>
                                </div>
                              )} // Customize event rendering if needed
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="main-title position-relative mt-4">
                            <h4 className="fs-2 fw-bold">
                              {text_upcoming_games}
                            </h4>
                            <Link
                              to={`/team-page?teamid=${teamid}`}
                              className="view-all-link text-decoration-underline"
                            >
                              {text_viewMore}
                            </Link>
                          </div>
                          <div className="row">
                            {allEventData.length > 0 &&
                              allEventData.map((e, index) => {
                                return (
                                  <>
                                    <div
                                      className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                                      data-ref="mixitup-target"
                                      id={index}
                                    >
                                      <div className="main-card mt-4">
                                        <div
                                          className="event-thumbnail"
                                          onClick={() =>
                                            navigate(
                                              `/event-details/${e?.event_unique_id}`
                                            )
                                          }
                                        >
                                          <Link
                                            to={`/event-details/${e?.event_unique_id}`}
                                            className="thumbnail-img"
                                          >
                                            <img
                                              src={
                                                e.EventImagePath === "path"
                                                  ? img1
                                                  : e.EventImagePath
                                              }
                                              alt=""
                                            />
                                          </Link>
                                        </div>
                                        <div className="event-content">
                                          <Link
                                            to={`/event-details/${e?.event_unique_id}`}
                                            className="event-title event-css"
                                          >
                                            {e.EventTitle}
                                          </Link>
                                          <div className="publish-date mb-2">
                                            <span>
                                              {moment
                                                .utc(e?.StartDate)
                                                .format("DD MMM, yyyy")}
                                              &nbsp;
                                            </span>
                                            <span className="dot dot-size-allevent">
                                              <BsDot
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />
                                            </span>
                                            <span>
                                              {moment
                                                .utc(e.StartDate)
                                                .format("ddd")}
                                              ,{" "}
                                              {moment
                                                .utc(e?.StartTime)
                                                .format("h:mmA")}{" "}
                                            </span>
                                          </div>
                                          <div className="d-flex gap-1">
                                            {e.venuedetails.length === 0 ? (
                                              ""
                                            ) : (
                                              <>
                                                <IoLocationOutline className="location-icon" />
                                                <span>
                                                  {e?.venuedetails[0].venuename}{" "}
                                                  ( {e?.venuedetails[0].address}
                                                  , {e?.venuedetails[0].city},{" "}
                                                  {e?.venuedetails[0].state},{" "}
                                                  {e?.venuedetails[0].country},{" "}
                                                  {e?.venuedetails[0].zipcode})
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </>
                                );
                              })}
                          </div>
                          <div className="main-title position-relative mt-5">
                            <h4 className="fs-2 fw-bold">{text_all_games}</h4>
                            <div className="d-flex justify-content-between flex-wrap gap-2">
                              <div className="d-flex gap-3">
                                <div className="form-control filter-team d-flex">
                                  <MdDateRange className="icon-team" />
                                  <DatePicker
                                    //   showIcon
                                    className="h_40 mx-1 form-control form-control-BR w-100"
                                    selected={startDate}
                                    dateFormat="dd MMM,yyyy"
                                    onChange={(date) => [
                                      setStartDate(date),
                                      dateSelectFilter(date),
                                    ]}
                                  />
                                </div>
                                <div className="form-control filter-team d-flex">
                                  <IoLocationOutline className="icon-team" />
                                  <input
                                    type="text"
                                    className="h_40 form-control form-control-BR w-100"
                                    name=""
                                    id=""
                                    placeholder={text_location}
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-wrap justify-content-end gap-3">
                                <div className="form-control filter-team d-flex">
                                  <BsFilterLeft className="icon-team" />
                                  <select
                                    name=""
                                    id=""
                                    onChange={(e) =>
                                      filterDataTeam(e.target.value)
                                    }
                                    className="h_40 form-control form-control-BR w-100"
                                  >
                                    <option value="">{text_teams}</option>
                                    {TeamList?.filter((value) => {
                                      return value.teamid !== teamid;
                                    }).map((v) => {
                                      return (
                                        <>
                                          <option
                                            value={v.teamid}
                                            className="form-control-BR"
                                          >
                                            {" "}
                                            {v.teamname}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className="form-control filter-team d-flex">
                                  <BsFilterLeft className="icon-team" />
                                  {calendarview === true ? (
                                    <>
                                      <button
                                        className="h_40 form-control form-control-BR w-100 text-start"
                                        onClick={() => setCalendarview(false)}
                                      >
                                        {text_all_games}
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="h_40 form-control form-control-BR w-100 text-start"
                                        onClick={() => setCalendarview(true)}
                                      >
                                        {text_calendarview}
                                      </button>
                                    </>
                                  )}
                                </div>
                                <div className="form-control filter-team d-flex">
                                  <BsFilterLeft className="icon-team" />
                                  <select
                                    name=""
                                    id=""
                                    className="h_40 form-control form-control-BR w-100"
                                  >
                                    <option
                                      value=""
                                      // className="form-control-BR"
                                    >
                                      {text_Sort}
                                    </option>
                                    <option
                                      value="upcoming"
                                      className="form-control-BR"
                                    >
                                      {" "}
                                      {text_Upcoming}
                                    </option>
                                    <option
                                      value="mostpopular"
                                      className="form-control-BR"
                                    >
                                      {" "}
                                      {text_mostpopular}
                                    </option>
                                    <option
                                      value="recommended"
                                      className="form-control-BR"
                                    >
                                      {" "}
                                      {text_Recommended}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            {calendarview === true ? (
                              <>
                                <div className="month-calendar-style">
                                  {/* <div> */}
                                  <FullCalendar
                                    // className="home-month-calendar"
                                    // showNonCurrentDates={false}
                                    timeZone="UTC"
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    events={eventsallgame}
                                    // eventContent={renderEventContent} // Customize event display
                                    eventClick={handleEventClickAllgame}
                                    datesSet={handleDateSetAllgame}
                                    // headerToolbar={{
                                    //   left: "prev,next today",
                                    //   center: "title",
                                    //   right: "dayGridMonth",
                                    // }}
                                    dayHeaderContent={(args) => {
                                      // Format weekdays as 'Mon', 'Tue', etc.
                                      return new Intl.DateTimeFormat("en", {
                                        weekday: "short",
                                      }).format(args.date);
                                    }}
                                    dayCellContent={(args) => {
                                      // Format and display only the day number
                                      return args.date.getDate(); // Get only the day (e.g., 1, 2, 3, etc.)
                                    }}
                                    weekends={true} // Show weekends
                                    eventContent={(eventInfo) => (
                                      <div className="calendar-event">
                                        <h4 className="calendar-event-title mt-2">
                                          {eventInfo.event.title}
                                          {/* <br />
                                          <p>
                                            {" "}
                                            {moment(
                                              eventInfo.event.starttime
                                            ).format("h:mmA")}
                                          </p> */}
                                        </h4>
                                      </div>
                                    )}
                                  />
                                  {/* </div> */}
                                </div>
                              </>
                            ) : (
                              <>
                                {AllEvents.length === 0 ? (
                                  <>
                                    <div className="container text-center mt-5">
                                      <h2>{text_noeventfound}</h2>{" "}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {AllEvents.map((e) => {
                                      return (
                                        <>
                                          <div className="new-card-team d-flex flex-wrap justify-content-between  mt-3">
                                            <div className="d-flex gap-2 flex-wrap">
                                              <img
                                                src={
                                                  e.EventImagePath === "path"
                                                    ? img1
                                                    : e.EventImagePath
                                                }
                                                alt=""
                                                className="card-img-team"
                                              />
                                              <div className="m-2 responsive-team-card">
                                                <h4> {e.EventTitle}</h4>
                                                <span>
                                                  {moment
                                                    .utc(e?.StartDate)
                                                    .format(
                                                      "DD MMM, yyyy"
                                                    )}{" "}
                                                  •{" "}
                                                  {moment
                                                    .utc(e.StartDate)
                                                    .format("ddd")}
                                                  ,{" "}
                                                  {moment
                                                    .utc(e?.StartTime)
                                                    .format("h:mmA")}
                                                </span>{" "}
                                                <br />
                                                <div className="d-flex gap-1">
                                                  {e.venuedetails.length ===
                                                  0 ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      <h4>
                                                        {" "}
                                                        <IoLocationOutline className="location-icon-team" />
                                                      </h4>
                                                      <span>
                                                        {
                                                          e?.venuedetails[0]
                                                            .venuename
                                                        }{" "}
                                                        ({" "}
                                                        {
                                                          e?.venuedetails[0]
                                                            .address
                                                        }
                                                        ,{" "}
                                                        {
                                                          e?.venuedetails[0]
                                                            .city
                                                        }
                                                        ,{" "}
                                                        {
                                                          e?.venuedetails[0]
                                                            .state
                                                        }
                                                        ,{" "}
                                                        {
                                                          e?.venuedetails[0]
                                                            .country
                                                        }
                                                        ,{" "}
                                                        {
                                                          e?.venuedetails[0]
                                                            .zipcode
                                                        }
                                                        )
                                                      </span>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <button
                                              className="buybtn-team mx-3"
                                              onClick={() =>
                                                navigate(
                                                  `/event-details/${e?.event_unique_id}`
                                                )
                                              }
                                            >
                                              {text_buytickets}
                                            </button>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default TeamPage;
