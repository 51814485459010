import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import Loader from "../../utils/Loader";
import { Box, Divider, Modal } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Currency, timeZonecountries } from "../../utils/data";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../Footer/Footer";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import { API_DOMAIN } from "../../utils/GlobalConst";
import { RxCross2 } from "react-icons/rx";
import { v4 as uuidv4 } from "uuid";

function CreateVirtualEvent() {
  const navigate = useNavigate();
  const data = useLocation();

  const eventIDforUpdate = data.state !== null ? data.state.event_id : 0;

  const [text_ownername, setText_ownername] = useState("Display Owner Name");
  const [text_showAvailableTicket, setText_showAvailableTicket] = useState(
    "Show Available Ticket "
  );
  const [text_allow_transfer, setText_allow_transfer] =
    useState("Allow Transfer");
  const [text_allow_resell, setText_allow_resell] = useState("Allow Resell"); //new

  const [text_percentage_ticketPrice, setText_percentage_ticketPrice] =
    useState("Percentage Of Ticket Price");
  const [text_percentage_profit, setText_percentage_profit] = useState(
    "Percentage Of Profit"
  );
  const [text_percentage_resell, setText_percentage_resell] = useState(
    "Percentage Of Resell Price"
  );
  const [text_fixedPrice, setText_fixedPrice] = useState("Fixed Price");
  const [text_ph_percentage_ticketPrice, setText_ph_percentage_ticketPrice] =
    useState("Enter Percentage of Ticket Price");
  const [text_ph_percentage_profit, setText_ph_percentage_profit] = useState(
    "Enter Percentage of Profit"
  );
  const [text_percentage_resellPrice, setText_percentage_resellPrice] =
    useState("Percentage On Resell Price");
  const [text_ph_percentage_resellPrice, setText_ph_percentage_resellPrice] =
    useState("Enter Percentage On Resell Price");
  const [text_resellFixed, setText_resellFixed] = useState(
    "Fixed Amount On Resell"
  );
  const [text_ph_resellFixed, setText_ph_resellFixed] = useState(
    "Enter Fixed Amount On Resell"
  );
  const [text_ph_currency, setText_ph_currency] = useState("Select Currency");
  const [text_network_currency, setText_network_currency] = useState(
    "Selected Network's Currency"
  );
  const [text_ph_nftNetwork, setText_ph_nftNetwork] =
    useState("Select NFT Network");
  const [text_ph_stableCoin, setText_ph_stableCoin] =
    useState("Select Stable Coin");
  const [text_ph_ticketType, setText_ph_ticketType] =
    useState("Select Ticket Type");
  const [text_nft, setText_nft] = useState("NFT");
  const [text_regular, setText_regular] = useState("Regular");
  const [text_nftNetwork, setText_nftNetwork] = useState("NFT Network");
  const [text_ticket_sellcurrency, setText_ticket_sellcurrency] = useState(
    "Ticket Sell Currency"
  );
  const [text_ph_ticketCyrrency, setText_ph_ticketCyrrency] = useState(
    "Select Ticket Currency"
  );

  const [text_crypto, setText_crypto] = useState("Crypto");
  const [text_Fiat, setText_Fiat] = useState("Fiat");
  const [text_ph_crypto, setText_ph_crypto] = useState("Select Crypto");
  const [text_stableCoin, setText_stableCoin] = useState("Stable Coin");
  const [text_currency, setText_currency] = useState("Currency");
  const [text_upload_image, setText_upload_image] = useState("Upload Image");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_ph_eveVenue, setText_ph_eveVenue] =
    useState("Select Event Venue");
  const [text_addNew, setText_addNew] = useState("Add New");
  const [text_requestvenue, setText_requestvenue] =
    useState("Request New Venue");
  const [text_timeZone, setText_timeZone] = useState("Time Zone");
  const [text_upload, setText_upload] = useState("Upload");
  const [text_add, setText_add] = useState("Add");
  const [text_ph_tags, setText_ph_tags] = useState("Enter Tags");
  const [text_err_eveTitle, setText_err_eveTitle] = useState(
    "Please Enter Event Title!"
  );
  const [text_err_ticketType, setText_err_ticketType] = useState(
    "Please Select Ticket Type"
  );

  const [text_err_currency, setText_err_currency] = useState(
    "Please Select Currency"
  );
  const [text_err_NftNetwork, setText_err_NftNetwork] = useState(
    "Please Select Nft Network"
  );
  const [text_err_sellingCurrency, setText_err_sellingCurrency] = useState(
    "Please Select Ticket Selling Currency"
  );
  const [text_eveImage, setText_eveImage] = useState("Event Image");
  const [text_eveTitle, setText_eveTitle] = useState("Event Title");
  const [text_eveType, setText_eveType] = useState("Event Type");
  const [text_ph_eveTitle, setText_ph_eveTitle] = useState("Enter Event Title");
  const [text_eveID, setText_eveID] = useState("Event ID");
  const [text_startDate, setText_startDate] = useState("Start Date");
  const [text_startTime, setText_startTime] = useState("Start Time");
  const [text_endDate, setText_endDate] = useState("End Date");
  const [text_endTime, setText_endTime] = useState("End Time");
  const [text_eveDesc, setText_eveDesc] = useState("Event Description");
  const [text_ph_eveDesc, setText__ph_eveDesc] = useState(
    "Enter Event Description"
  );
  const [text_eventvenue, setText_eventvenue] = useState("Event Venue");
  const [text_location, setText_location] = useState("Location");
  const [text_eveCreator_taxID, setText_eveCreator_taxID] = useState(
    "Event Creator Tax ID"
  );
  const [text_ph_eveCreator_taxID, setText_ph_eveCreator_taxID] = useState(
    "Enter Tax ID Of Event Creator"
  );
  const [text_eveCreatorName, setText_eveCreatorName] =
    useState("Event Creator Name");
  const [text_ph_eveCreatorName, setText_ph_eveCreatorName] = useState(
    "Enter Name Of Event Creator"
  );
  const [text_sel_TimeZone, setText_sel_TimeZone] =
    useState("Select time zone");
  const [text_title_memorabilia, setText_title_memorabilia] = useState(
    "Add Memorabilia Images"
  );
  const [text_ticketType, setText_ticketType] = useState("Ticket Type");
  const [text_typeNftToken, setText_typeNftToken] =
    useState("Type of NFT Token");
  const [text_eveDetails, setText_eveDetails] = useState("Event Details");
  const [text_configure, setText_configure] = useState("Configure Price");
  const [text_selectleague, setText_selectleague] = useState("Select League");
  const [text_selectteam, setText_selectteam] = useState("Select Team");
  const [text_configureseats, setText_configureseats] = useState(
    "Create and Configure Seats"
  );
  const [text_createonlineevent, setText_createonlineevent] = useState(
    "Create Online Event"
  );
  // new
  const [text_err_select_eveVenue, setText_err_select_eveVenue] = useState(
    "Please Select Event Venue!"
  );
  const [text_update, setText_update] = useState("Update");
  const [text_selteamtype, setText_selteamtype] = useState("Select Team Type");
  const [btn_create_event, setBtn_create_event] = useState("Create Event");
  const [text_orgdashboard, setText_orgdashboard] = useState(
    "Organization Dashboard"
  );
  const [text_home, setText_home] = useState("Home");
  // neww

  const [text_toBeDecided, setText_toBeDecided] = useState("To be decided");
  const [text_away, setText_away] = useState("Away");
  const [text_other, setText_other] = useState("Other");
  const [text_saveandpublish, setText_saveandpublish] =
    useState("Save & Publish");
  const [transferData, setTransferData] = useState("");
  const [resellData, setResellData] = useState("");
  const [standingTicket, setStandingTicket] = useState(false);
  const [standingTicketNumber, setStandingTicketNumber] = useState("");
  const [standingTicketPrice, setStandingTicketPrice] = useState("");
  const [virtualTicket, setVirtualTicket] = useState(false);
  const [virtualTicketNumber, setVirtualTicketNumber] = useState("");
  const [virtualTicketPrice, setVirtualTicketPrice] = useState("");
  const [ownername, setOwnername] = useState(false);
  const [showAvailable, setShowAvailable] = useState("");

  const [resellOnTicketPrice, setResellOnTicketPrice] = useState(false);
  const [resellOnProfit, setResellOnProfit] = useState(false);
  const [resellOnResellPrice, setResellOnResellPrice] = useState(false);
  const [resellOnResellFixedPrice, setResellOnResellFixedPrice] =
    useState(false);
  const [resellTicketPercentage, setResellTicketPercentage] = useState("");
  const [resellProfitPercentage, setResellProfitPercentage] = useState("");
  const [resellPriceProfit, setResellPriceProfit] = useState("");

  const [resellFixedProfit, setResellFixedProfit] = useState("");
  const [currency, setCurrency] = useState("non_crypto");
  const [Cryptocurrency, setCryptocurrency] = useState("");
  const [typeOfToken, setTypeOfToken] = useState("ERC-721");
  const [nonCrypto, setNonCrypto] = useState("");
  const [selectedCryptoCurrency, setSelectedCryptoCurrency] = useState("");
  const [slectedTicketCurrency, setSlectedTicketCurrency] = useState("");
  const [ticketCurrency, setTicketCurrency] = useState("fiat");
  const [eventTitle, setEventTitle] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventID, setEventID] = useState("");
  const [teamType, setTeamType] = useState("");
  const [selectedGameId, setSelectedGameId] = useState("");

  const [utcMomentStartTime, setUtcMomentStartTime] = useState("");
  const [utcMomentEndTime, setUtcMomentEndTime] = useState("");
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [startDate, setStartDate] = useState(tomorrow);

  const [endDate, setEndDate] = useState(tomorrow);
  const [eventDes, setEventDes] = useState("");
  const [startTime, setStartTime] = useState(tomorrow);
  const [endTime, setEndTime] = useState(tomorrow);
  useEffect(() => {
    // Ensure that the end date is after the start date on page load
    setEndTime(new Date(startTime.getTime() + 60 * 60 * 1000)); // Adding 1 hour as an example
  }, [startDate]);
  const filterTime = (time) => {
    const selectedDate = new Date(time);
    return startTime < selectedDate.getTime();
  };
  const [venueList, setVenueList] = useState([]);
  const [venueID, setVenueID] = useState("");
  const [eventVenue, setEventVenue] = useState("");
  const [errEventVenue, setErrEventVenue] = useState("");

  const [errEventTitle, setErrEventTitle] = useState("");
  var timezones = timeZonecountries[0];
  const FinalData = timezones;
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [eventCreatorTaxID, setEventCreatorTaxID] = useState("");
  const [eventCreatorName, setEventCreatorName] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errEventTicketType, setErrEventTicketType] = useState("");
  const [errTypeRegularCurrency, setErrTypeRegularCurrency] = useState("");
  const [errTypeNftNetwork, setErrTypeNftNetwork] = useState("");
  const [errSellCurrencyType, setErrSellCurrencyType] = useState("");
  const [errSellCurrency, setErrSellCurrency] = useState("");
  const [errSelectLeague, setErrSelectLeague] = useState("");
  const [errTeamType, setErrTeamType] = useState("");
  const [errTeam1, setErrTeam1] = useState("");
  const [errTeam2, setErrTeam2] = useState("");
  const [futureEvent, setFutureEvent] = useState(false);
  const [leagueDetailsList, setLeagueDetailsList] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  const [sum, setSum] = useState("");
  const [sumAmount, setSumAmount] = useState(0);

  const TeamList = JSON.parse(sessionStorage.getItem("teamList"));
  console.log(TeamList);

  // Event Image Start
  const [openEveImageUploadPopup, setOpenEveImageUploadPopup] = useState(false);
  const handleOpenEveImagePopup = () => setOpenEveImageUploadPopup(true);
  const handleCloseEveImagePopup = () => setOpenEveImageUploadPopup(false);
  const [Loading, setLoading] = useState(false);
  const [eventImage, setEventImage] = useState("");
  const [eventFilePath, setEventFilePath] = useState("");
  const EventimguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setEventImage(file);
        // You can perform further actions with the selected file here
      } else {
        setEventImage(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };

  const handleEveImgUpload = () => {
    setLoading(true);
    var API_URL = API_DOMAIN + "file/upload";
    var imgdata = new FormData();
    imgdata.append("att_file", eventImage);
    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, imgdata, headerConfig)
      .then(function (response) {
        console.log("Img Upload", response);
        if (response.status === 200) {
          setLoading(false);
          setEventFilePath(response.data.data.Location);
          handleCloseEveImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setTimeout(() => {
            setLoading(false);
            handleCloseEveImagePopup();
            setEventImage("");
          }, 1000);
        }
      })
      .then(function () {});
  };
  // Event Image End
  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const RandomeText = (length) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const StartTimeSelectManually = (date) => {
    const timeString = moment(date).format("HH:mm:ss");
    const today = new Date();
    const year = today.getUTCFullYear();
    const month = String(today.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so we add 1
    const day = String(today.getUTCDate()).padStart(2, "0");

    // Combine today's date with the given time string (assuming it's in UTC)
    //YYYY-MM-DD HH:mm
    const utcDateTimeString = `${year}-${month}-${day} ${timeString}`; // 'Z' indicates UTC
    console.log(utcDateTimeString);

    let utcMomentStartTime_temp = moment.utc(utcDateTimeString).toISOString();
    console.log("utcTime", utcMomentStartTime_temp);
    setStartTime(date);
    setEndTime(date);
    setUtcMomentStartTime(utcMomentStartTime_temp);
  };
  const EndTimeSelectManually = (date) => {
    const timeString = moment(date).format("HH:mm:ss");
    const today = new Date();
    const year = today.getUTCFullYear();
    const month = String(today.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so we add 1
    const day = String(today.getUTCDate()).padStart(2, "0");

    // Combine today's date with the given time string (assuming it's in UTC)
    //YYYY-MM-DD HH:mm
    const utcDateTimeString = `${year}-${month}-${day} ${timeString}`; // 'Z' indicates UTC
    console.log(utcDateTimeString);

    let utcMomentStartTime_temp = moment.utc(utcDateTimeString).toISOString();

    console.log("utcTime", utcMomentStartTime_temp);
    setEndTime(date);
    setUtcMomentEndTime(utcMomentStartTime_temp);
  };

  const [awayTeamsList, setAwayTeamsList] = useState([]);
  const [homeTeamName, setHomeTeamName] = useState("");
  const [homeTeamId, setHomeTeamId] = useState("");
  const [awayTeamId, setAwayTeamId] = useState("");
  const [selectedLeagueGameList, setSelectedLeagueGameList] = useState([]);
  const [selectedleagueId, setSelectedleagueId] = useState("");

  const getAwayTeamsList = (ht) => {
    const filteredData = selectedLeagueGameList.filter(
      (item) => item.home_teamid === ht
    );
    setHomeTeamName(filteredData[0].home);

    console.log("home team game list", filteredData);
    setAwayTeamsList(filteredData);
  };
  const getSelectedLeagueDetails = (selectedId) => {
    const filteredData = leagueDetailsList.filter(
      (item) => item.leagueid === selectedId
    );
    setSelectedLeagueGameList(filteredData[0].gamelist);
    setSelectedleagueId(filteredData[0].leagueid);
    console.log("selected league", filteredData);
  };
  const getLeagueDetails = () => {
    var API_URL = API_DOMAIN + "league/getall";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("league details", response.data.data);
        setLeagueDetailsList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getLeagueDetails();
  }, []);
  useEffect(() => {
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          // update the value of userlocation variable
          setUserLocation([longitude, latitude]);
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
  }, []);
  // Create Event

  const CreateEventValidation = () => {
    var isCreateEvent = true;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!eventTitle) {
      isCreateEvent = false;
      setErrEventTitle(<>{text_err_eveTitle}</>);
    }
    // if (!currency) {
    //   isCreateEvent = false;
    //   setErrEventTicketType(<>{text_err_ticketType}!</>);
    // }
    // if (currency === "non_crypto" && !nonCrypto) {
    //   isCreateEvent = false;
    //   setErrTypeRegularCurrency(<>{text_err_currency}!</>);
    // }
    // if (currency === "crypto" && !Cryptocurrency) {
    //   isCreateEvent = false;
    //   setErrTypeNftNetwork(<>{text_err_NftNetwork}!</>);
    // }
    // if (!ticketCurrency) {
    //   isCreateEvent = false;
    //   setErrSellCurrencyType(<>{text_err_sellingCurrency}!</>);
    // }
    // if (ticketCurrency !== "" && !slectedTicketCurrency) {
    //   isCreateEvent = false;
    //   setErrSellCurrency(<>{text_err_currency}!</>);
    // }
    return isCreateEvent;
  };

  const [inputFields, setInputFields] = useState([
    {
      v_id: uuidv4(),
      name: "virtual_HD",
      price: 0,
      virtual_maxLimit: 0,
      streaming_link: "",
    },
  ]);
  const handleChangeInput = (v_id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (v_id === i.v_id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        v_id: uuidv4(),
        name: "virtual_HD",
        price: 0,
        virtual_maxLimit: 0,
        streaming_link: "",
      },
    ]);
  };
  const handleRemoveFields = (v_id) => {
    if (inputFields.length === 1) {
      toast.info("Minimum one Allocation is required");
    } else {
      const values = [...inputFields];
      values.splice(
        values.findIndex((value) => value.v_id === v_id),
        1
      );
      setInputFields(values);
    }
  };
  const EventCreateHandle = () => {
    console.log("first", selectedFiles, showAvailable);
    if (CreateEventValidation()) {
      var getHours = selectedTimeZone.slice(1, 3);
      var getMinitus = selectedTimeZone.slice(4, 6);
      var hourtoMinitus = Math.floor(getHours * 60);
      var FinalTimezoneOffset = -(Number(hourtoMinitus) + Number(getMinitus));

      var API_URL = API_DOMAIN + "event/create/virtual?type=insert";

      let headerConfig = {
        headers: {
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        EventTitle: eventTitle,
        EventImageKey: "k",
        EventImagePath: eventFilePath === "" ? "path" : eventFilePath,
        EventType: eventType,
        EventID: eventID === "" ? RandomeText(5) : eventID,
        StartDate: moment.utc(startDate).format("yyyy-MM-DD"),
        EndDate: moment.utc(endDate).format("yyyy-MM-DD"),
        EventDescription: eventDes,
        StartTime: utcMomentStartTime,
        EndTime: utcMomentEndTime,
        // venuedetails: selectedVenueList,
        Memorabilia: selectedFiles,
        ticket_type: currency,
        currency: nonCrypto,
        nft_token_type: typeOfToken,
        nft_token: "s",
        nft_network: Cryptocurrency,
        nft_currency: selectedCryptoCurrency,
        sell_currency_type: ticketCurrency,
        sell_currency: slectedTicketCurrency,
        TicketDetailsList: [],
        TotalNumberTickets: sum,
        TotalAmount: sumAmount,
        TransferPercentage: transferData,
        ResellPercentageTicketPrice: resellTicketPercentage,
        ResellPercentageTicketProfit: resellProfitPercentage,
        resellpriceprofit: resellPriceProfit,
        resellfixedprice: resellFixedProfit,
        timezoneoffset: FinalTimezoneOffset,
        EventCreaterTaxId: eventCreatorTaxID,
        EventCreaterName: eventCreatorName,
        OwnerName: ownername,
        showAvailableTicket: showAvailable,
        venueid: venueID,
        leagueid: selectedleagueId,
        gameid: selectedGameId, //
        teamname: homeTeamName,
        teamtype: teamType === "" ? "other" : teamType,
        teamid: homeTeamId,
        isFutureEvent: futureEvent === true ? "true" : "false",
        ispopular: true,
        team01: homeTeamId,
        team02: awayTeamId,
        location: {
          type: "Point",
          coordinates: userLocation,
        },
        isvirtual: true,
        standing_price: standingTicketPrice,
        virtual_category: inputFields,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("create", response.data.data.event_unique_id);
          if (response.status === 200) {
            publishEventHandle(response.data.data.event_unique_id);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };
  const publishEventHandle = (event_unique_id) => {
    var API_URL =
      API_DOMAIN + `event/update/publish?event_unique_id=${event_unique_id}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = { isPublish: true };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("published", response.data.data);
        navigate("/");
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const GetEventDetails = () => {
    var type = "select";
    var event_unique_id = eventIDforUpdate;
    var API_URL =
      API_DOMAIN +
      "event/public?type=" +
      type +
      "&event_unique_id=" +
      event_unique_id;

    axios
      .get(API_URL)
      .then(function (response) {
        console.log("Detaied Event", response.data.data);
        var res = response.data.data;
        setEventTitle(res[0].EventTitle);
        setEventFilePath(res[0].eventFilePath);
        setEventType(res[0].EventType);
        setEventID(res[0].EventID);
        setStartDate(new Date(res[0].StartDate));
        setEndDate(new Date(res[0].EndDate));
        setEventDes(res[0].EventDescription);
        setStartTime(new Date(res[0].StartTime));
        setEndTime(new Date(res[0].EndTime));
        setSelectedTimeZone(res[0].timezoneoffset);
        setEventCreatorTaxID(res[0].EventCreaterTaxId);
        setEventCreatorName(res[0].EventCreaterName);
        setSelectedFiles(res[0].Memorabilia);
        setHomeTeamName(res[0].teamname);
        setTeamType(res[0].teamtype);
        setEventVenue(res[0].venuedetails[0].venuename);
        setShowAvailable(res[0].showAvailableTicket);
        setOwnername(res[0].OwnerName);
        setTransferData(res[0].TransferPercentage);
        setCurrency(res[0].ticket_type);
        setTypeOfToken(res[0].nft_token_type);
        setCryptocurrency(res[0].nft_network);
        setSelectedCryptoCurrency(res[0].nft_currency);
        setNonCrypto(res[0].currency);
        setTicketCurrency(res[0].sell_currency_type);
        setSlectedTicketCurrency(res[0].sell_currency);
        if (
          res[0].ResellPercentageTicketPrice === null &&
          res[0].ResellPercentageTicketProfit === null &&
          res[0].resellfixedprice === null &&
          res[0].resellpriceprofit === null
        ) {
          setResellData(false);
        } else {
          setResellData(true);
        }
        if (res[0].ResellPercentageTicketPrice !== null) {
          setResellOnTicketPrice(true);
        } else if (res[0].ResellPercentageTicketProfit !== null) {
          setResellOnProfit(true);
        } else if (res[0].resellfixedprice !== null) {
          setResellOnResellFixedPrice(true);
        } else if (res[0].resellpriceprofit !== null) {
          setResellOnResellPrice(true);
        }
        setResellTicketPercentage(res[0].ResellPercentageTicketPrice);
        setResellProfitPercentage(res[0].ResellPercentageTicketProfit);
        setResellPriceProfit(res[0].resellpriceprofit);
        setResellFixedProfit(res[0].resellfixedprice);
        setInputFields(res[0]?.virtual_category);
      })
      .catch(function (error) {
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    GetEventDetails();
  }, []);
  const UpdateEventHandle = () => {
    var API_URL = API_DOMAIN + "event/update/virtual";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      event_unique_id: eventIDforUpdate,
      EventTitle: eventTitle,
      EventImageKey: "k",
      EventImagePath: eventFilePath === "" ? "path" : eventFilePath,
      EventType: eventType,
      EventID: eventID === "" ? RandomeText(5) : eventID,
      StartDate: moment.utc(startDate).format("yyyy-MM-DD"),
      EndDate: moment.utc(endDate).format("yyyy-MM-DD"),
      EventDescription: eventDes,
      StartTime: startTime,
      EndTime: endTime,
      // virtual_category: inputFields,
      isvirtual: true,
    };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create", response.data.data.event_unique_id);
        if (response.status === 200) {
          navigate("/profile");
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
    // }
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/org-dashboard">{text_orgdashboard}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/event-type">{text_eveType}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        create virtual event
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>Create Virtual Event</h3>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 col-md-12">
                <div className="wizard-steps-block">
                  <div id="add-event-tab" className="step-app">
                    <div className="step-content">
                      <div
                        className="step-tab-panel step-tab-info active"
                        id="tab_step1"
                      >
                        <div className="tab-from-content">
                          <div className="main-card">
                            <div className="bp-title">
                              <h4>
                                <FaInfoCircle className=" step_icon me-3" />
                                {text_eveDetails}
                              </h4>
                            </div>
                            <div className="p-4 bp-form main-form">
                              <div className="row">
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveImage}
                                    </label>
                                    <button
                                      className="btn btn-default btn-hover steps_btn mt-2"
                                      onClick={handleOpenEveImagePopup}
                                    >
                                      {text_upload}
                                    </button>
                                    {eventFilePath !== "" ? (
                                      <>
                                        <img
                                          src={eventFilePath}
                                          alt=""
                                          className="eventImg"
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveTitle}
                                      <span className="starsty">*</span>
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      value={eventTitle}
                                      placeholder={text_ph_eveTitle}
                                      onChange={(e) => [
                                        setEventTitle(e.target.value),
                                        setErrEventTitle(""),
                                      ]}
                                    />
                                    <span className="starsty">
                                      {errEventTitle}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveType}
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      value={eventType}
                                      placeholder="Enter Event Type"
                                      onChange={(e) =>
                                        setEventType(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveDesc}
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      placeholder={text_ph_eveDesc}
                                      onChange={(e) =>
                                        setEventDes(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_startDate}
                                      <span className="starsty">*</span>
                                    </label>
                                    <DatePicker
                                      className="form-control dateInputSty h_50 inputleaguenight"
                                      minDate={tomorrow}
                                      selected={startDate}
                                      dateFormat="dd MMM,yyyy"
                                      onChange={(date) => [
                                        setStartDate(date),
                                        setEndDate(date),
                                      ]}
                                    />
                                    {/* <br /> <br /> */}
                                    {/* <span className=" ">
                                      <input
                                        type="checkbox"
                                        className="mx-2"
                                        onChange={(e) =>
                                          setFutureEvent(e.target.checked)
                                        }
                                      />
                                      {text_toBeDecided}
                                    </span> */}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_endDate}
                                      <span className="starsty">*</span>
                                    </label>

                                    <DatePicker
                                      className="form-control dateInputSty h_50 inputleaguenight"
                                      selected={endDate}
                                      minDate={startDate}
                                      dateFormat="dd MMM,yyyy"
                                      onChange={(date) => setEndDate(date)}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_sel_TimeZone}
                                    </label>
                                    <select
                                      name=""
                                      id=""
                                      className="form-control h_50 inputleaguenight"
                                      onChange={(e) =>
                                        setSelectedTimeZone(e.target.value)
                                      }
                                    >
                                      <option value="" selected disabled>
                                        {text_timeZone}
                                      </option>
                                      {FinalData?.map((val, i) => {
                                        return (
                                          <>
                                            <option value={val?.utc}>
                                              {val?.label}{" "}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_startTime}
                                      <span className="starsty">*</span>
                                    </label>

                                    <DatePicker
                                      className="form-control dateInputSty h_50 inputleaguenight"
                                      selected={startTime}
                                      onChange={(date) => [
                                        StartTimeSelectManually(date),
                                      ]}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={10}
                                      dateFormat="h:mm aa"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_endTime}
                                      <span className="starsty">*</span>
                                    </label>

                                    <DatePicker
                                      className="form-control dateInputSty h_50 inputleaguenight"
                                      selected={endTime}
                                      onChange={(date) => [
                                        EndTimeSelectManually(date),
                                      ]}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={10}
                                      dateFormat="h:mm aa"
                                      filterTime={filterTime}
                                    />
                                  </div>
                                </div>

                                {/* <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveCreator_taxID}
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      placeholder={text_ph_eveCreator_taxID}
                                      onChange={(e) =>
                                        setEventCreatorTaxID(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveCreatorName}
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      placeholder={text_ph_eveCreatorName}
                                      onChange={(e) =>
                                        setEventCreatorName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div> */}

                                <div className="create-event-stadium">
                                  <center>{/*stadium Img here  */}</center>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_selectleague}{" "}
                                      <span className="starsty">*</span>
                                    </label>
                                    <select
                                      name=""
                                      id=""
                                      className="form-control h_50 inputleaguenight"
                                      onClick={(e) => [
                                        getSelectedLeagueDetails(
                                          e.target.value
                                        ),
                                        setErrSelectLeague(""),
                                      ]}
                                    >
                                      <option value="" disabled selected>
                                        {text_selectleague}
                                      </option>
                                      {leagueDetailsList.map((v) => {
                                        return (
                                          <>
                                            <option value={v.leagueid}>
                                              {v.leaguename}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </select>
                                    <span className="starsty">
                                      {errSelectLeague}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_selteamtype}{" "}
                                      <span className="starsty">*</span>
                                    </label>
                                    {window.location.pathname ===
                                    "/create-virtual-event/edit" ? (
                                      <>
                                        <input
                                          type="text"
                                          disabled
                                          value={teamType}
                                          className="form-control h_50 inputleaguenight"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <select
                                          name=""
                                          id=""
                                          className="form-control h_50 inputleaguenight"
                                          onChange={(e) => [
                                            setTeamType(
                                              e.target.value
                                            ).setErrTeamType(""),
                                          ]}
                                        >
                                          <option value="" disabled selected>
                                            {text_selteamtype}
                                          </option>
                                          <option value="home">
                                            {text_home}
                                          </option>
                                          <option value="away">
                                            {text_away}
                                          </option>
                                          <option value="other">
                                            {text_other}
                                          </option>
                                        </select>
                                        <span className="starsty">
                                          {errTeamType}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_selectteam}1{" "}
                                      <span className="starsty">*</span>
                                    </label>
                                    {window.location.pathname ===
                                    "/create-virtual-event/edit" ? (
                                      <>
                                        <input
                                          type="text"
                                          disabled
                                          value={homeTeamName}
                                          className="form-control h_50 inputleaguenight"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <select
                                          name=""
                                          id=""
                                          className="form-control h_50 inputleaguenight"
                                          onChange={(e) => [
                                            getAwayTeamsList(e.target.value),
                                            setHomeTeamId(e.target.value),
                                            setErrTeam1(""),
                                          ]}
                                        >
                                          <option value="" disabled selected>
                                            {text_selectteam}
                                          </option>
                                          {selectedLeagueGameList.map((g) => {
                                            return (
                                              <>
                                                <option value={g.home_teamid}>
                                                  {g.home}
                                                </option>
                                                {console.log(g.teamname)}
                                              </>
                                            );
                                          })}
                                        </select>
                                        <span className="starsty">
                                          {errTeam1}
                                        </span>
                                      </>
                                    )}{" "}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_selectteam}2{" "}
                                      <span className="starsty">*</span>
                                    </label>
                                    {window.location.pathname ===
                                    "/create-virtual-event/edit" ? (
                                      <>
                                        <input
                                          type="text"
                                          disabled
                                          value={homeTeamName}
                                          className="form-control h_50 inputleaguenight"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <select
                                          name=""
                                          id=""
                                          className="form-control h_50 inputleaguenight"
                                          onChange={(e) => [
                                            setAwayTeamId(e.target.value),
                                            setErrTeam2(""),
                                          ]}
                                        >
                                          <option value="" disabled selected>
                                            {text_selectteam}
                                          </option>
                                          {awayTeamsList.map((g) => {
                                            return (
                                              <>
                                                <option value={g.away_teamid}>
                                                  {g.away}
                                                </option>
                                                {console.log(g.teamname)}
                                              </>
                                            );
                                          })}
                                        </select>
                                        <span className="starsty">
                                          {errTeam2}
                                        </span>
                                      </>
                                    )}{" "}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-4 col-md-12  mt-3">
                                  {window.location.pathname ===
                                  "/create-virtual-event/edit" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        disabled
                                        checked={
                                          showAvailable === true ? true : false
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        onChange={() => [
                                          setShowAvailable("true"),
                                          console.log(!showAvailable),
                                        ]}
                                      />
                                    </>
                                  )}
                                  &nbsp; &nbsp;
                                  {/* <span>{text_showAvailableTicket}</span> */}
                                  <span>Number Of Available Ticket</span>
                                </div>
                                {/* <div className="col-lg-4 col-md-12 mt-3">
                                  {window.location.pathname ===
                                  "/create-virtual-event/edit" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        disabled
                                        checked={
                                          ownername === true ? true : false
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          setOwnername(!ownername)
                                        }
                                      />
                                    </>
                                  )}
                                  &nbsp; &nbsp;
                                  <span>{text_ownername}</span>
                                  <br />
                                </div> */}
                                <div className="col-lg-4  col-md-12  mt-3">
                                  {window.location.pathname ===
                                  "/create-virtual-event/edit" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        disabled
                                        checked={
                                          transferData === null ? false : true
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          setTransferData(!transferData)
                                        }
                                      />
                                    </>
                                  )}
                                  &nbsp; &nbsp;
                                  <span>{text_allow_transfer}</span>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-lg-6  col-md-12  mt-3">
                                  {window.location.pathname ===
                                  "/create-virtual-event/edit" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        disabled
                                        checked={
                                          resellData === true ? true : false
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        onChange={() => [
                                          setResellData(!resellData),
                                          setResellOnTicketPrice(false),
                                          setResellOnProfit(false),
                                          setResellOnResellPrice(false),
                                          setResellOnResellFixedPrice(false),
                                        ]}
                                      />
                                    </>
                                  )}
                                  &nbsp; &nbsp;
                                  <span>{text_allow_resell}</span>
                                  {resellData === true ? (
                                    <>
                                      <div className="d-flex ">
                                        <input
                                          type="radio"
                                          checked={
                                            resellOnTicketPrice ? true : false
                                          }
                                          onChange={() => [
                                            setResellOnTicketPrice(
                                              !resellOnTicketPrice
                                            ),
                                            setResellOnProfit(false),
                                            setResellOnResellFixedPrice(false),
                                            setResellOnResellPrice(false),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-virtual-event/edit"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span className="mx-2">
                                          {text_percentage_ticketPrice}
                                        </span>

                                        <input
                                          type="radio"
                                          checked={
                                            resellOnProfit ? true : false
                                          }
                                          onChange={() => [
                                            setResellOnProfit(!resellOnProfit),
                                            setResellOnResellFixedPrice(false),
                                            setResellOnResellPrice(false),
                                            setResellOnTicketPrice(false),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-virtual-event/edit"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span style={{ marginLeft: "0.5rem" }}>
                                          {text_percentage_profit}
                                        </span>
                                      </div>
                                      <div className="d-flex ">
                                        <input
                                          type="radio"
                                          checked={
                                            resellOnResellPrice ? true : false
                                          }
                                          onChange={() => [
                                            setResellOnResellPrice(
                                              !resellOnResellPrice
                                            ),
                                            setResellOnProfit(false),
                                            setResellOnResellFixedPrice(false),

                                            setResellOnTicketPrice(false),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-virtual-event/edit"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span
                                          style={{
                                            marginRight: "1rem",
                                            marginLeft: "0.5rem",
                                          }}
                                        >
                                          {text_percentage_resell}
                                        </span>

                                        <input
                                          type="radio"
                                          checked={
                                            resellOnResellFixedPrice
                                              ? true
                                              : false
                                          }
                                          onChange={() => [
                                            setResellOnResellFixedPrice(
                                              !resellOnResellFixedPrice
                                            ),
                                            setResellOnProfit(false),

                                            setResellOnResellPrice(false),
                                            setResellOnTicketPrice(false),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-virtual-event/edit"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span style={{ marginLeft: "0.5rem" }}>
                                          {text_fixedPrice}
                                        </span>
                                      </div>
                                      {resellOnTicketPrice === true ? (
                                        <>
                                          <div className="mt-2">
                                            <h5>
                                              {text_percentage_ticketPrice}
                                            </h5>
                                            <input
                                              type="text"
                                              className="form-control h_50 inputleaguenight"
                                              placeholder={
                                                text_ph_percentage_ticketPrice
                                              }
                                              onChange={(e) =>
                                                setResellTicketPercentage(
                                                  e.target.value
                                                )
                                              }
                                              value={resellTicketPercentage}
                                              disabled={
                                                window.location.pathname ===
                                                "/create-virtual-event/edit"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {resellOnProfit === true ? (
                                        <>
                                          <div className="mt-2">
                                            <h5>{text_percentage_profit}</h5>
                                            <input
                                              type="text"
                                              className="form-control h_50 inputleaguenight"
                                              placeholder={
                                                text_ph_percentage_profit
                                              }
                                              onChange={(e) =>
                                                setResellProfitPercentage(
                                                  e.target.value
                                                )
                                              }
                                              value={resellProfitPercentage}
                                              disabled={
                                                window.location.pathname ===
                                                "/create-virtual-event/edit"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {resellOnResellPrice === true ? (
                                        <>
                                          <div className="mt-2">
                                            <h5>
                                              {text_percentage_resellPrice}
                                            </h5>
                                            <input
                                              type="text"
                                              className="form-control h_50 inputleaguenight"
                                              placeholder={
                                                text_ph_percentage_resellPrice
                                              }
                                              onChange={(e) =>
                                                setResellPriceProfit(
                                                  e.target.value
                                                )
                                              }
                                              value={resellPriceProfit}
                                              disabled={
                                                window.location.pathname ===
                                                "/create-virtual-event/edit"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {resellOnResellFixedPrice === true ? (
                                        <>
                                          <div className="mt-2">
                                            <h5>{text_resellFixed}</h5>
                                            <input
                                              type="text"
                                              className="form-control h_50 inputleaguenight"
                                              placeholder={text_ph_resellFixed}
                                              onChange={(e) =>
                                                setResellFixedProfit(
                                                  e.target.value
                                                )
                                              }
                                              value={resellFixedProfit}
                                              disabled={
                                                window.location.pathname ===
                                                "/create-virtual-event/edit"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <div className="row ce-border mt-5">
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form ">
                                    <label className="form-label fs-16">
                                      <b> Virtual Ticket</b>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="text-end mt-3">
                                <button
                                  className="btn btn-default btn-hover steps_btn mt-2"
                                  onClick={handleAddFields}
                                >
                                  Add
                                </button>
                              </div>
                              {inputFields.map((inputField) => {
                                return (
                                  <>
                                    <div className="row  mt-3">
                                      <div className="col-lg-2 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form pb_30">
                                          <label className="form-label fs-16">
                                            Maximum Ticket
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control h_50 inputleaguenight"
                                            placeholder="Enter maximum number for ticket"
                                            value={inputField.virtual_maxLimit}
                                            name="virtual_maxLimit"
                                            onChange={(e) =>
                                              handleChangeInput(
                                                inputField.v_id,
                                                e
                                              )
                                            }
                                            disabled={
                                              window.location.pathname ===
                                              "/create-virtual-event/edit"
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-2 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form">
                                          <label className="form-label fs-16">
                                            Price
                                          </label>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control h_50 inputleaguenight"
                                          placeholder="Enter price for ticket"
                                          onChange={(e) =>
                                            handleChangeInput(
                                              inputField.v_id,
                                              e
                                            )
                                          }
                                          value={inputField.price}
                                          name="price"
                                          disabled={
                                            window.location.pathname ===
                                            "/create-virtual-event/edit"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form">
                                          <label className="form-label fs-16">
                                            Video Streaming Category
                                          </label>
                                        </div>

                                        <select
                                          className="form-control h_50 inputleaguenight"
                                          value={inputField.name}
                                          name="name"
                                          disabled={
                                            window.location.pathname ===
                                            "/create-virtual-event/edit"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeInput(
                                              inputField.v_id,
                                              e
                                            )
                                          }
                                        >
                                          <option value="" selected disabled>
                                            Enter video streaming category
                                          </option>
                                          <option value="virtual_HD">HD</option>
                                          <option value="virtual_FHD">
                                            FHD
                                          </option>
                                          <option value="virtual_4K">4K</option>
                                        </select>
                                      </div>
                                      <div className="col-lg-3 col-md-12 ">
                                        <div className="form-group border_bottom-create profile-night-form">
                                          <label className="form-label fs-16">
                                            Stream URL
                                          </label>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control h_50 inputleaguenight"
                                          placeholder="Enter stream URL"
                                          onChange={(e) =>
                                            handleChangeInput(
                                              inputField.v_id,
                                              e
                                            )
                                          }
                                          value={inputField.streaming_link}
                                          name="streaming_link"
                                          // disabled={
                                          //   window.location.pathname ===
                                          //   "/create-virtual-event/edit"
                                          //     ? true
                                          //     : false
                                          // }
                                        />
                                      </div>
                                      <div className="col-lg-2 col-md-12 text-center ">
                                        <div className="form-group border_bottom-create profile-night-form">
                                          <label className="form-label fs-16"></label>
                                        </div>
                                        <button
                                          onClick={() =>
                                            handleRemoveFields(inputField.v_id)
                                          }
                                          className="removeBtnVenue mt-4"
                                        >
                                          <RxCross2 className="crossIcon" />
                                        </button>
                                      </div>{" "}
                                    </div>
                                  </>
                                );
                              })}
                              {/* Ticket Type Start */}
                              {/* <div className="row ce-border mt-3">
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form ">
                                    <label className="form-label fs-16">
                                      <b> {text_ticketType}</b>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row  mt-3">
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_ticketType}
                                      <span className="starsty">*</span>
                                    </label>

                                    <input
                                      type="text"
                                      className="form-control h_50 inputleaguenight"
                                      placeholder={text_regular}
                                      disabled={true}
                                    />
                                    <span className="starsty">
                                      {errEventTicketType}
                                    </span>
                                  </div>
                                </div>

                                {currency === "crypto" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form">
                                        <label className="form-label fs-16">
                                          {text_typeNftToken}
                                        </label>
                                      </div>
                                      <select
                                        className="form-control h_50 inputleaguenight"
                                        value={typeOfToken}
                                        onChange={(e) =>
                                          setTypeOfToken(e.target.value)
                                        }
                                        disabled={
                                          window.location.pathname ===
                                          "/create-virtual-event/edit"
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="ERC-721" selected>
                                          ERC-721
                                        </option>
                                        <option value="ERC-1155">
                                          ERC-1155
                                        </option>
                                        <option value="ERC-6551">
                                          ERC-6551
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form">
                                        <label className="form-label fs-16">
                                          {text_nftNetwork}{" "}
                                          <span className="Star">*</span>
                                        </label>
                                      </div>
                                      <select
                                        className="form-control h_50 inputleaguenight"
                                        value={Cryptocurrency}
                                        onChange={(e) => [
                                          setCryptocurrency(e.target.value),
                                          setNonCrypto(e.target.value),
                                          setSelectedCryptoCurrency(
                                            e.target.value
                                          ),
                                          setErrTypeNftNetwork(""),
                                        ]}
                                        disabled={
                                          window.location.pathname ===
                                          "/create-virtual-event/edit"
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="" selected disabled>
                                          {text_ph_nftNetwork}
                                        </option>
                                        <option value="matic">Polygon</option>
                                        <option value="eth">Ethereum</option>
                                      </select>
                                      <span className="starsty">
                                        {errTypeNftNetwork}
                                      </span>
                                    </div>

                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form">
                                        <label className="form-label fs-16">
                                          {text_network_currency}
                                        </label>
                                        <input
                                          type="text"
                                          className="mt-1 form-control h_50 mb-2 inputleaguenight"
                                          value={selectedCryptoCurrency}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {currency === "non_crypto" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form pb_30">
                                        <label className="form-label fs-16">
                                          {text_currency}
                                          <span className="starsty">*</span>
                                        </label>

                                        <select
                                          className="mt-1 form-control h_50 inputleaguenight"
                                          value={nonCrypto}
                                          onChange={(e) => [
                                            setNonCrypto(e.target.value),
                                            setErrTypeRegularCurrency(""),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-virtual-event/edit"
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value="" selected disabled>
                                            {text_ph_currency}
                                          </option>
                                          {Currency.map((v) => {
                                            return (
                                              <>
                                                <option value={v?.abbreviation}>
                                                  {v?.currency} ({v?.symbol})
                                                </option>
                                              </>
                                            );
                                          })}
                                        </select>
                                        <span className="starsty">
                                          {errTypeRegularCurrency}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div> */}

                              <div className="row ce-border">
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form ">
                                    <label className="form-label fs-16">
                                      <b> {text_ticket_sellcurrency}</b>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row  mt-3">
                                {/* <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_ticket_sellcurrency}
                                      <span className="starsty">*</span>
                                    </label>
                                    <select
                                      className="mt-1 form-control h_50 inputleaguenight"
                                      name="currency"
                                      value={ticketCurrency}
                                      defaultValue={currency}
                                      onChange={(e) => [
                                        setTicketCurrency(e.target.value),
                                        setSlectedTicketCurrency(""),
                                        setErrSellCurrencyType(""),
                                      ]}
                                      disabled={
                                        window.location.pathname ===
                                        "/create-virtual-event/edit"
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="" selected disabled>
                                        {text_ph_ticketCyrrency}
                                      </option>
                                      <option value="fiat">{text_Fiat} </option>
                                      <option value="crypto">
                                        {text_crypto}{" "}
                                      </option>
                                      <option value="stable">
                                        {text_stableCoin}
                                      </option>
                                    </select>
                                    <span className="starsty">
                                      {errSellCurrencyType}
                                    </span>
                                  </div>
                                </div> */}

                                {ticketCurrency === "fiat" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form pb_30">
                                        <label className="form-label fs-16">
                                          {text_currency}
                                          <span className="starsty">*</span>
                                        </label>
                                        <select
                                          className="mt-1 form-control h_50 inputleaguenight"
                                          name="currency"
                                          onChange={(e) => [
                                            setSlectedTicketCurrency(
                                              e.target.value
                                            ),
                                            setErrSellCurrency(""),
                                          ]}
                                          value={slectedTicketCurrency}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-virtual-event/edit"
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value="" selected disabled>
                                            {text_ph_currency}
                                          </option>
                                          {Currency.map((v) => {
                                            return (
                                              <>
                                                <option value={v?.symbol}>
                                                  {v?.currency} ({v?.symbol})
                                                </option>
                                              </>
                                            );
                                          })}
                                        </select>
                                        <span className="starsty">
                                          {errSellCurrency}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {/* {ticketCurrency === "crypto" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form pb_30">
                                        <label className="form-label fs-16">
                                          {text_crypto}
                                          <span className="starsty">*</span>
                                        </label>
                                        <select
                                          className="mt-1 form-control h_50 inputleaguenight"
                                          name="currency"
                                          onChange={(e) => [
                                            setSlectedTicketCurrency(
                                              e.target.value
                                            ),
                                            setErrSellCurrency(""),
                                          ]}
                                          value={slectedTicketCurrency}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-virtual-event/edit"
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value="" selected disabled>
                                            {text_ph_crypto}
                                          </option>
                                          <option value="eth">ETH</option>
                                          <option value="btc">Bitcoin</option>
                                          <option value="matic">Matic</option>
                                        </select>
                                        <span className="starsty">
                                          {errSellCurrency}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {ticketCurrency === "stable" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form">
                                        <label className="form-label fs-16">
                                          {text_stableCoin}
                                          <span className="starsty">*</span>
                                        </label>
                                      </div>
                                      <select
                                        className="mt-1 form-control h_50 inputleaguenight"
                                        name="currency"
                                        onChange={(e) => [
                                          setSlectedTicketCurrency(
                                            e.target.value
                                          ),
                                          setErrSellCurrency(""),
                                        ]}
                                        value={slectedTicketCurrency}
                                        disabled={
                                          window.location.pathname ===
                                          "/create-virtual-event/edit"
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="" selected disabled>
                                          {text_ph_stableCoin}
                                        </option>
                                        <option value="usdt">USDT </option>
                                      </select>
                                      <span className="starsty">
                                        {errSellCurrency}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap gap-2 justify-content-center mt-4">
                      {/* className="step-footer step-tab-pager mt-4 " */}
                      <button
                        data-direction="prev"
                        className="btn  cancelBtn me-2 "
                        onClick={() => navigate(-1)}
                      >
                        {text_cancel}
                      </button>
                      {window.location.pathname ===
                      "/create-virtual-event/edit" ? (
                        <>
                          <button
                            data-direction="finish"
                            className="btn btn-default btn-hover steps_btn Create-Conf-Btn"
                            onClick={UpdateEventHandle}
                          >
                            {text_update}{" "}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            data-direction="finish"
                            className="btn btn-default btn-hover steps_btn Create-Conf-Btn"
                            onClick={EventCreateHandle}
                          >
                            {text_saveandpublish}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Event Image Poupup */}
      <Modal
        open={openEveImageUploadPopup}
        onClose={handleCloseEveImagePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseEveImagePopup()]}
              />
            </div>
            <h3 className="popup-main-title">{text_upload_image}</h3>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      className="form-control h_50 uploadinput landinginputnight"
                      onChange={EventimguplpoadValidation}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseEveImagePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button onClick={handleEveImgUpload} className="resell-Btn">
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>

      <ToastContainer />
      <Footer />
    </>
  );
}

export default CreateVirtualEvent;
