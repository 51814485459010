import React, { useEffect, useState } from "react";
import "../EventDetails/EventDetails.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdDateRange, MdFileCopy } from "react-icons/md";
import { IoLocationOutline, IoLocationSharp } from "react-icons/io5";
import { AiOutlineShareAlt } from "react-icons/ai";
import { FaBookmark, FaInstagram, FaSearch } from "react-icons/fa";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { IoTime } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FiBookmark } from "react-icons/fi";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import moment from "moment";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BiLogIn } from "react-icons/bi";
import { GiMoneyStack } from "react-icons/gi";
import { BsDot } from "react-icons/bs";
import { EMAIL_REGEX } from "../../utils/Validation";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function EventDetails() {
  const Countdown = ({ targetDate }) => {
    const calculateTimeLeft = () => {
      const difference = new Date(targetDate) - new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearTimeout(timer);
    });

    const { days, hours, minutes, seconds } = timeLeft;

    return (
      <div>
        {days !== undefined && days >= 0 ? (
          <h4
            style={{
              color: "white",
              textTransform: "lowercase",
              fontWeight: "700",
            }}
          >
            {days}
            {text_d} : {hours}
            {text_h} : {minutes}
            {text_m} : {seconds}s
          </h4>
        ) : (
          <h6 style={{ color: "white", fontSize: "18px" }}>
            {text_countdownfinished}!
          </h6>
        )}
      </div>
    );
  };

  const navigate = useNavigate();
  const { eventID } = useParams();
  const [Data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [allEventData, setAllEventData] = useState([]);
  const [indexBookmark, setIndexBookmark] = useState("");
  const [loginAsGuest, setLoginAsGuest] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openloginInfo, setOpenloginInfo] = useState(false);
  const handleCloseloginInfo = () => {
    setOpenloginInfo(false);
    navigate("/signin", {
      state: { pageLand: window.location.pathname },
    });
  };

  // language variable
  const [text_info_please_login, setText_info_please_login] = useState(
    "Please Login To Proceed Further"
  );
  const [text_home, setText_home] = useState("Home");
  const [text_exploreevent, setText_exploreevent] = useState("Explore Events");
  const [text_evedetailview, setText_evedetailview] =
    useState("Event Detail View");
  const [text_save, setText_save] = useState("Save");
  const [text_share, setText_share] = useState("Share");
  const [text_insta, setText_insta] = useState("Instagram");
  const [text_facebook, setText_facebook] = useState("Facebook");
  const [text_twitter, setText_twitter] = useState("Twitter");
  const [text_linkedin, setText_linkedin] = useState("LinkedIn");
  const [text_email, setText_email] = useState("Email");
  const [text_eveDetails, setText_eveDetails] = useState("Event Details");
  const [text_time, setText_time] = useState("Time");
  const [text_location, setText_location] = useState("Location");
  const [text_booknow, setText_booknow] = useState("Book Now");
  const [text_h, setText_h] = useState("h");
  const [text_d, setText_d] = useState("d");
  const [text_m, setText_m] = useState("m");
  const [text_date, setText_date] = useState("Date");
  const [text_countdownfinished, setText_countdownfinished] =
    useState("Countdown finished");
  const [text_copyurl, setText_copyurl] = useState("Copy Event URL");
  const [text_okay, setText_okay] = useState("Okay");
  const [text_search, setText_search] = useState("Search");

  // new language variable

  const [text_price, setText_price] = useState("Price");
  const [text_recommended_events, setText_recommended_events] =
    useState("Recommended Events");
  const [text_viewMore, setText_viewMore] = useState("View More");

  useEffect(() => {
    var type = "select";
    var event_unique_id = eventID;
    var API_URL =
      API_DOMAIN +
      "event/public?type=" +
      type +
      "&event_unique_id=" +
      event_unique_id;

    axios
      .get(API_URL)
      .then(function (response) {
        console.log("Detaied Event", response.data.data);
        var res = response.data.data;
        setData(res);
        setTableData(res[0].TicketDetailsList);
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  }, [eventID]);
  const handleCopyUrl = (sm) => {
    navigator.clipboard.writeText(window.location.href);

    if (sm === "instagram") {
      handleTooltipOpenInsta();
      setTimeout(() => {
        window.open("https://www.instagram.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "linkedin") {
      handleTooltipOpenWhatsApp();
      setTimeout(() => {
        window.open("https://www.linkedin.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "facebook") {
      handleTooltipOpenFacebook();
      setTimeout(() => {
        window.open("https://www.facebook.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "twitter") {
      handleTooltipOpenTwitter();
      setTimeout(() => {
        window.open("https://twitter.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "email") {
      handleTooltipOpenEmail();
      setTimeout(() => {
        window.open("https://gmail.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    }
  };
  const [openInsta, setOpenInsta] = React.useState(false);
  const [openFacebook, setOpenFacebook] = React.useState(false);
  const [openTwitter, setOpenTwitter] = React.useState(false);
  const [openLinkedin, setOpenLinkedin] = React.useState(false);
  const [opentooltip, setOpenTooltip] = useState(false);
  const [openeventcopiedtooltip, setOpeneventcopiedtooltip] = useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenInsta(false);
    setOpenFacebook(false);
    setOpenLinkedin(false);
    setOpenTwitter(false);
    setOpenEmail(false);
    setOpenTooltip(false);
  };

  const handleTooltipOpenWhatsApp = () => {
    setOpenLinkedin(true);
    setTimeout(() => {
      setOpenLinkedin(false);
    }, [1000]);
    setOpenTooltip(true);
  };

  const handleTooltipOpenInsta = () => {
    setOpenInsta(true);
    setTimeout(() => {
      setOpenInsta(false);
    }, [1000]);
    setOpenTooltip(true);
  };
  const handleTooltipOpenFacebook = () => {
    setOpenFacebook(true);
    setTimeout(() => {
      setOpenFacebook(false);
    }, [1000]);
    setOpenTooltip(true);
  };
  const handleTooltipOpenTwitter = () => {
    setOpenTwitter(true);
    setTimeout(() => {
      setOpenTwitter(false);
    }, [1000]);
    setOpenTooltip(true);
  };

  const handleTooltipOpenEmail = () => {
    setOpenEmail(true);
    setTimeout(() => {
      setOpenEmail(false);
    }, [1000]);
    setOpenTooltip(true);
  };
  // more event
  const getEventsCard = () => {
    var type = "selectall";
    var API_URL = API_DOMAIN + "event/public?type=" + type;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("all events ", response.data.data.event);
        var res = response.data.data;
        setAllEventData(res.event);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getEventsCard();
    if (sessionStorage.getItem("token") !== null) {
      console.log("in if", sessionStorage.getItem("token"));
      getBookmarkedEvents();
    }
  }, []);
  const loginCheckHandle = (id, standing) => {
    var token = sessionStorage.getItem("token");
    if (token === null || token === undefined || token === "") {
      setOpenloginInfo(true);
    } else {
      console.log("adasdasd", id);
      if (standing === "standing") {
        navigate("/buy-ticket", {
          state: { event_id: id?.event_unique_id, showstanding: true },
        });
      }else if (standing==="virtual") {
                navigate("/buy-ticket", {
                  state: { event_id: id?.event_unique_id, showvirtual: true },
                });
      } else {
        navigate("/buy-ticket", {
          state: { event_id: id?.event_unique_id },
        });
      }
    }
  };

  const [bookmaredEventIdList, setBookmaredEventIdList] = useState([]);

  const getBookmarkedEvents = () => {
    var API_URL = API_DOMAIN + `event/get/bookmarks`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("Bookmarked Events", response.data.data);
        setBookmaredEventIdList(response.data.data);
        var res = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const isBookmarked = (eventId) => {
    return bookmaredEventIdList.some(
      (event) => event.event_unique_id === eventId
    );
  };

  const AddBookmarkHandle = (eveID, eveData) => {
    var API_URL = API_DOMAIN + `event/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: true,
      event_details: [eveData],
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.message === "Bookmark created") {
          getBookmarkedEvents();
        }
        if (response.data.message === "Please login") {
          toast.info(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const RemoveBookmarkHandle = (eveID) => {
    var API_URL = API_DOMAIN + `event/uncheck/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: false,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Remove", response);
        if (response.data.message === "Bookmark updated") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_date(
        xpath.select1("/resources/string[@name='text_date']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_exploreevent(
        xpath.select1("/resources/string[@name='text_exploreevent']", doc)
          .firstChild.data
      );
      setText_evedetailview(
        xpath.select1("/resources/string[@name='text_evedetailview']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_share(
        xpath.select1("/resources/string[@name='text_share']", doc).firstChild
          .data
      );
      setText_insta(
        xpath.select1("/resources/string[@name='text_insta']", doc).firstChild
          .data
      );
      setText_facebook(
        xpath.select1("/resources/string[@name='text_facebook']", doc)
          .firstChild.data
      );
      setText_twitter(
        xpath.select1("/resources/string[@name='text_twitter']", doc).firstChild
          .data
      );
      setText_linkedin(
        xpath.select1("/resources/string[@name='text_linkedin']", doc)
          .firstChild.data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_eveDetails(
        xpath.select1("/resources/string[@name='text_eveDetails']", doc)
          .firstChild.data
      );
      setText_time(
        xpath.select1("/resources/string[@name='text_time']", doc).firstChild
          .data
      );
      setText_location(
        xpath.select1("/resources/string[@name='text_location']", doc)
          .firstChild.data
      );
      setText_booknow(
        xpath.select1("/resources/string[@name='text_booknow']", doc).firstChild
          .data
      );
      setText_h(
        xpath.select1("/resources/string[@name='text_h']", doc).firstChild.data
      );
      setText_countdownfinished(
        xpath.select1("/resources/string[@name='text_countdownfinished']", doc)
          .firstChild.data
      );
      setText_copyurl(
        xpath.select1("/resources/string[@name='text_copyurl']", doc).firstChild
          .data
      );
      setText_okay(
        xpath.select1("/resources/string[@name='text_okay']", doc).firstChild
          .data
      );
      setText_m(
        xpath.select1("/resources/string[@name='text_h']", doc).firstChild.data
      );
      setText_d(
        xpath.select1("/resources/string[@name='text_h']", doc).firstChild.data
      );
      setText_info_please_login(
        xpath.select1("/resources/string[@name='text_info_please_login']", doc)
          .firstChild.data
      );

      setText_price(
        xpath.select1("/resources/string[@name='text_price']", doc).firstChild
          .data
      );
      setText_recommended_events(
        xpath.select1("/resources/string[@name='text_recommended_events']", doc)
          .firstChild.data
      );
      setText_viewMore(
        xpath.select1("/resources/string[@name='text_viewMore']", doc)
          .firstChild.data
      );
      setText_search(
        xpath.select1("/resources/string[@name='text_search']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  const [guestEmail, setGuestEmail] = useState("");
  const [guestEmailErr, setGuestEmailErr] = useState("");

  const [confirmGuestEmail, setConfirmGuestEmail] = useState("");
  const [confirmguestEmailErr, setConfirmGuestEmailErr] = useState("");

  const guestEmailValidation = () => {
    var isvalid = true;
    if (guestEmail === "") {
      isvalid = false;
      setGuestEmailErr("Please Enter Email");
    }
    if (guestEmail !== "" && EMAIL_REGEX.test(guestEmail) === false) {
      isvalid = false;
      setGuestEmailErr("Please Enter Valid Email");
    }
    if (EMAIL_REGEX.test(guestEmail) === true) {
      isvalid = true;
      setGuestEmailErr("");
    }
    if (confirmGuestEmail === "") {
      isvalid = false;
      setConfirmGuestEmailErr("Please Enter Email");
    }
    if (
      confirmGuestEmail !== "" &&
      EMAIL_REGEX.test(confirmGuestEmail) === false
    ) {
      isvalid = false;
      setConfirmGuestEmailErr("Please Enter Valid Email");
    }
    if (EMAIL_REGEX.test(confirmGuestEmail) === true) {
      isvalid = true;
      setConfirmGuestEmailErr("");
    }
    if (guestEmail !== confirmGuestEmail) {
      isvalid = false;
      setConfirmGuestEmailErr("Email does not match");
    }
    return isvalid;
  };

  const verifyGuestEmail = () => {
    if (guestEmailValidation()) {
      var API_URL = API_DOMAIN + `user/isuserexist?email=${guestEmail}`;

      var data = {};
      axios
        .post(API_URL, data)
        .then(function (response) {
          console.log("verify", response);
          var res = response.data.data;
          if (res.isActive === true) {
            // show login
            setTimeout(() => {
              navigate("/signin");
            }, 2000);
          } else if (res.isActive === false) {
            RegisterGuestUser();
          }
        })
        .catch(function (error) {
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else if (error.response.data.message === "user not found") {
            console.log("user not found");
            RegisterGuestUser();
          } else {
            toast.error(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };
  const RegisterGuestUser = () => {
    var API_URL = API_DOMAIN + "user/createguestuser";

    let headerConfig = {
      headers: {
        accept: "application/json",
      },
    };
    var data = {
      email: guestEmail,
      guestType: Number(100),
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("guest reg res***", response);
        toast.success(response.data.message);
        console.log(response.data.data.token);
        sessionStorage.setItem("token", response.data.data.token);
        sessionStorage.setItem("userid", response.data.data.user_id);
        sessionStorage.setItem("useremail", response.data.data.email);
        setOpenloginInfo(false);
      })
      .catch(function (error) {
        console.log(error.response.data);
      })
      .then(function () {});
  };
  return (
    <>
      <Header />

      <div className="eventdetail-wrapper eventdetail-nightmod" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                        <Link to="/all-event">{text_exploreevent}</Link>
                      </li> */}
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_evedetailview}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="barren-breadcrumb">
                  <div className="league-page-search">
                    <input
                      id="outlined-basic"
                      variant="outlined"
                      className="league-page-search-input"
                      fullWidth
                      placeholder={text_search}
                      label="Search"
                    />
                    <div className="league-page-search-icon">
                      <FaSearch />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Data.map((v) => {
          return (
            <>
              <div className="event-dt-block p-80">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="event-top-dts">
                        <div className="calendar_box">
                          <div className="calendar">
                            <div className="month">
                              {moment.utc(v?.StartDate).format("MMM")}
                            </div>
                            <div className="day">
                              {moment.utc(v?.StartDate).format("DD")}
                            </div>
                          </div>
                        </div>
                        <div className="event-top-dt">
                          <h3 className="event-main-title">{v.EventTitle}</h3>
                          <div className="event-top-info-status">
                            <div className="publish-date mb-2">
                              <span>
                                {moment.utc(v?.StartDate).format("DD MMM yyyy")}
                                &nbsp;
                              </span>
                              <span className="dot dot-size-allevent">
                                <BsDot
                                  style={{
                                    width: "23px",
                                    height: "23px",
                                  }}
                                />
                              </span>
                              <span>
                                {moment.utc(v.StartDate).format("ddd")}{" "}
                                {moment.utc(v?.StartTime).format("h:mmA")}{" "}
                              </span>
                            </div>
                            <div className="duration-price-remaining">
                              {v.venuedetails.length === 0 ? (
                                ""
                              ) : (
                                <>
                                  <IoLocationOutline className="location-icon" />
                                  <span>
                                    {v?.venuedetails[0].venuename}({""}
                                    {v?.venuedetails[0].address},{" "}
                                    {v?.venuedetails[0].city},{" "}
                                    {v?.venuedetails[0].state},{" "}
                                    {v?.venuedetails[0].country},{" "}
                                    {v?.venuedetails[0].zipcode})
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-12">
                      <div className="main-event-dt">
                        <div className="event-img">
                          <div className="countdown">
                            <div className="countdown-item countdownSty">
                              <span id="day" style={{ color: "white" }}>
                                {" "}
                                <Countdown targetDate={v.StartDate} />
                              </span>
                            </div>
                          </div>
                          <img
                            src={
                              v?.EventImagePath === "path"
                                ? img1
                                : v?.EventImagePath
                            }
                            alt=""
                            className="eventdetail-imghight"
                          />
                          <div className="countdown d-flex justify-content-end">
                            <div className="share-save-btns dropdown">
                              <button
                                className="sv-btn me-2"
                                onClick={() => [
                                  setIndexBookmark(!indexBookmark),
                                ]}
                              >
                                {isBookmarked(v.event_unique_id) ? (
                                  <>
                                    <span
                                      onClick={() =>
                                        RemoveBookmarkHandle(v?.event_unique_id)
                                      }
                                    >
                                      <FaBookmark className="bookmarkicon-fill" />
                                      {text_save}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      onClick={() =>
                                        AddBookmarkHandle(v?.event_unique_id, v)
                                      }
                                    >
                                      <FiBookmark className="bookmarkicon" />
                                      {text_save}
                                    </span>
                                  </>
                                )}
                              </button>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                arrow
                                open={opentooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied!"
                                onClose={() => setOpenTooltip(false)}
                              >
                                <button
                                  className="sv-btn me-2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <AiOutlineShareAlt /> {text_share}
                                </button>
                              </Tooltip>

                              <ul className="dropdown-menu">
                                <li
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={openInsta}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  disableInteractive
                                  arrow
                                >
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() => handleCopyUrl("instagram")}
                                  >
                                    <FaInstagram /> {text_insta}
                                  </Link>
                                </li>
                                <li
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={openFacebook}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  disableInteractive
                                  arrow
                                >
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() => handleCopyUrl("facebook")}
                                  >
                                    <FaFacebook /> {text_facebook}
                                  </Link>
                                </li>
                                <li
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={openTwitter}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  disableInteractive
                                  arrow
                                >
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() => handleCopyUrl("twitter")}
                                  >
                                    <FaTwitter /> {text_twitter}
                                  </Link>
                                </li>
                                <li
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={openLinkedin}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  disableInteractive
                                  arrow
                                >
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() => handleCopyUrl("linkedin")}
                                  >
                                    <FaLinkedinIn /> {text_linkedin}
                                  </Link>
                                </li>
                                <li
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={openEmail}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  disableInteractive
                                  arrow
                                >
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() => handleCopyUrl("email")}
                                  >
                                    <MdOutlineMail /> {text_email}
                                  </Link>
                                </li>
                              </ul>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                arrow
                                open={openeventcopiedtooltip}
                                onClose={() => setOpeneventcopiedtooltip(false)}
                                title="Copied!"
                              >
                                <button
                                  className="sv-btn p-2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{ width: "max-content" }}
                                  onClick={() => [
                                    setOpeneventcopiedtooltip(true),
                                    navigator.clipboard.writeText(
                                      window.location.href
                                    ),
                                  ]}
                                >
                                  <MdFileCopy /> {text_copyurl}
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-12">
                      <div className="main-card event-right-dt">
                        <div className="bp-title">
                          <h4>{text_eveDetails}</h4>
                        </div>
                        <div className="event-dt-right-group mt-5">
                          {" "}
                          {/*Remove mt-5 while eableing Organised By */}
                          <div className="event-dt-right-icon">
                            <MdDateRange />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_date}</h4>
                            <h5>
                              {moment
                                .utc(v.StartDate)
                                .format("dddd DD MMMM yyyy")}
                            </h5>
                          </div>
                        </div>
                        <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <IoTime />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_time}</h4>
                            <h5 className="mb-0">
                              {moment.utc(v.StartTime).format("h:mmA")} -{" "}
                              {moment.utc(v.EndTime).format("h:mmA")}
                            </h5>
                          </div>
                        </div>
                        <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <IoLocationSharp />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_location}</h4>
                            <h5 className="mb-0">
                              {v.venuedetails.length === 0 ? (
                                "Online"
                              ) : (
                                <>
                                  {v?.venuedetails[0].venuename}(
                                  {v?.venuedetails[0].address},{" "}
                                  {v?.venuedetails[0].city},{" "}
                                  {v?.venuedetails[0].state},{" "}
                                  {v?.venuedetails[0].country},{" "}
                                  {v?.venuedetails[0].zipcode})
                                </>
                              )}
                            </h5>
                          </div>
                        </div>
                        <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <GiMoneyStack />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_price}</h4>
                            <h5 className="mb-0"></h5>
                          </div>
                        </div>
                        <div className="booking-btn">
                          {v?.isvirtual === true ? (
                            <>
                              <div className="booking-btn">
                                <button
                                  className="main-btn btn-hover w-100"
                                  onClick={() => loginCheckHandle(v, "virtual")}
                                >
                                  Reserve Seats
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => loginCheckHandle(v)}
                                className="main-btn btn-hover w-100"
                              >
                                Reserve Seats
                              </button>
                            </>
                          )}
                        </div>
                        {v?.isstanding === true ? (
                          <>
                            <div className="booking-btn">
                              <button
                                className="main-btn btn-hover w-100"
                                onClick={() => loginCheckHandle(v, "standing")}
                              >
                                Get Standing Tickets
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 ">
                      <div className="more-events">
                        <div className="main-title position-relative">
                          <h3>{text_recommended_events}</h3>
                          <Link
                            to="/all-event"
                            className="view-all-link text-decoration-underline"
                          >
                            {text_viewMore}
                          </Link>
                        </div>
                        <div className="d-flex gap-3 owl-carousel moreEvents-slider owl-theme"></div>
                        <OwlCarousel className="owl-theme" loop margin={10} nav>
                          {allEventData.map((e, index) => {
                            return (
                              <>
                                <div
                                  className="item"
                                  id={index}
                                  onClick={() =>
                                    navigate(
                                      `/event-details/${e?.event_unique_id}`
                                    )
                                  }
                                >
                                  <div className="item">
                                    <div className="main-card mt-4">
                                      <div className="event-thumbnail">
                                        <Link to="#" className="thumbnail-img">
                                          <img
                                            src={
                                              e.EventImagePath === "path"
                                                ? img1
                                                : e.EventImagePath
                                            }
                                            alt=""
                                          />
                                        </Link>
                                        <span
                                          className="bookmark-icon bookmark-content"
                                          title=""
                                          onClick={() => [
                                            setIndexBookmark(index),
                                          ]}
                                        >
                                          {isBookmarked(e.event_unique_id) ? (
                                            <>
                                              <FaBookmark
                                                className="bookmarkicon-fill"
                                                onClick={() =>
                                                  RemoveBookmarkHandle(
                                                    e?.event_unique_id
                                                  )
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <FiBookmark
                                                className="bookmarkicon"
                                                onClick={() =>
                                                  AddBookmarkHandle(
                                                    e?.event_unique_id,
                                                    e
                                                  )
                                                }
                                              />
                                            </>
                                          )}
                                        </span>
                                      </div>
                                      <div className="event-content">
                                        <Link to="#" className="event-title">
                                          {e.EventTitle}
                                        </Link>
                                        <div className="publish-date mb-2">
                                          <span>
                                            {moment
                                              .utc(e?.StartDate)
                                              .format("DD MMM, yyyy")}
                                            &nbsp;
                                          </span>
                                          <span className="dot dot-size-allevent">
                                            <BsDot
                                              style={{
                                                width: "23px",
                                                height: "23px",
                                              }}
                                            />
                                          </span>
                                          <span>
                                            {moment
                                              .utc(e.StartDate)
                                              .format("ddd")}
                                            ,{" "}
                                            {moment
                                              .utc(e?.StartTime)
                                              .format("h:mmA")}{" "}
                                          </span>
                                        </div>
                                        <div className="d-flex gap-1">
                                          {v.venuedetails.length === 0 ? (
                                            ""
                                          ) : (
                                            <>
                                              <IoLocationOutline className="location-icon" />
                                              <span>
                                                {v?.venuedetails[0].venuename} ({" "}
                                                {v?.venuedetails[0].address},{" "}
                                                {v?.venuedetails[0].city},{" "}
                                                {v?.venuedetails[0].state},{" "}
                                                {v?.venuedetails[0].country},{" "}
                                                {v?.venuedetails[0].zipcode})
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <br />
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openloginInfo}
        onClose={() => [setOpenloginInfo(false), setLoginAsGuest(false)]}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <BiLogIn className="Email-icon hometextnight" />
        </DialogTitle>

        <>
          {loginAsGuest === true ? (
            <>
              <DialogContent>
                <DialogContentText>
                  <h3 className="text-center hometextnight">Enter Details</h3>
                </DialogContentText>
                <Divider />
                <div className="form-group mt-4">
                  <label className="form-label">
                    Email
                    <span className="starsty">*</span>
                  </label>
                  <input
                    className="form-control seasontextnight h_40"
                    type="text"
                    placeholder="Please Enter Email ID"
                    onChange={(e) => setGuestEmail(e.target.value)}
                  />
                  <span className="validation-err">{guestEmailErr}</span>
                </div>
                <div className="form-group mt-4">
                  <label className="form-label">
                    Confirm Email
                    <span className="starsty">*</span>
                  </label>
                  <input
                    className="form-control seasontextnight h_40"
                    type="text"
                    placeholder="Please Confirm Email ID"
                    onPaste={"return false"}
                    onChange={(e) => setConfirmGuestEmail(e.target.value)}
                  />
                  <span className="validation-err">{confirmguestEmailErr}</span>
                </div>
                <div className="d-flex justify-content-center mb-3 mt-3">
                  <button
                    className="main-btn btn-hover "
                    onClick={verifyGuestEmail}
                  >
                    Save
                  </button>
                </div>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogContent>
                <DialogContentText>
                  <h3 className="text-center hometextnight">
                    {text_info_please_login}
                  </h3>
                </DialogContentText>
                <Divider />
              </DialogContent>
              <div className="d-flex justify-content-center mb-3 gap-3 ">
                <button
                  className="main-btn btn-hover  "
                  onClick={handleCloseloginInfo}
                >
                  {text_okay}
                </button>
                <button
                  className="guest-btn "
                  onClick={() => setLoginAsGuest(true)}
                >
                  Continue as guest
                </button>
              </div>
            </>
          )}
        </>
      </Dialog>
      <Footer />
    </>
  );
}

export default EventDetails;
