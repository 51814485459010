import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar";
import { Link, useNavigate } from "react-router-dom";
import {
  FaCalendarAlt,
  FaTrashAlt,
  FaTicketAlt,
  FaTag,
  FaChartPie,
} from "react-icons/fa";
import { FaEllipsisVertical, FaEye } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { IoSettingsSharp, IoLocationSharp } from "react-icons/io5";
import { HiDuplicate } from "react-icons/hi";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { toast } from "react-toastify";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BiLogIn } from "react-icons/bi";
import { CgCloseO } from "react-icons/cg";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgEvent() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openDuplicatePopup, setOpenDuplicatePopup] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteEventUniqueID, setDeleteEventUniqueID] = useState("");
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [startDate, setStartDate] = useState(tomorrow);

  const [endDate, setEndDate] = useState(tomorrow);
  //variable
  const [text_yes, setText_yes] = useState("Yes");
  const [text_no, setText_no] = useState("No");
  const [allEventShow, setAllEventShow] = useState("AllEvents");
  const [searchEvent, setSearchEvent] = useState("");
  const [AllEventsData, setAllEventsData] = useState([]);
  const [OnlineEventsData, setOnlineEventsData] = useState([]);
  const navigate = useNavigate();

  const [text_events, setText_events] = useState("Events");
  const [text_allevents, setText_allevents] = useState("All Event");
  const [text_finishedEvent, setText_finishedEvent] =
    useState("Finished Event");
  const [text_noeventfound, setText_noeventfound] = useState("No Event found");
  const [text_manage, setText_manage] = useState("Manage");
  const [text_previewEve, setText_previewEve] = useState("Preview Event");
  const [text_viewReport, setText_viewReport] = useState("View Report");
  const [text_duplicate, setText_duplicate] = useState("Duplicate");
  const [text_delete, setText_delete] = useState("Delete");
  const [text_status, setText_status] = useState("Status");
  const [text_publish, setText_publish] = useState("Publish");
  const [text_pending, setText_pending] = useState("Pending");
  const [text_startson, setText_startson] = useState("Starts on");

  const [text_ticket, setText_ticket] = useState("Ticket");
  const [text_ticketSold, setText_ticketSold] = useState("Tickets sold");
  const [text_searchPh_orgEve, setText_searchPh_orgEve] = useState(
    "Search by event name, status"
  );
  const getAllEvents = () => {
    var type = "selectall";
    var API_URL = API_DOMAIN + "event/my?type=" + type;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data;
        console.log(res);
        setAllEventsData(res);
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getAllEvents();
  }, []);

  useEffect(() => {
    var API_URL = API_DOMAIN + "event/my/expired?type=selectall";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("Old Tickets ", response.data.data);
        setOnlineEventsData(response.data.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  }, []);

  const filteredItems = AllEventsData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(searchEvent.toLowerCase()) !==
      -1
  );

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);

      setText_events(
        xpath.select1("/resources/string[@name='text_events']", doc).firstChild
          .data
      );
      setText_noeventfound(
        xpath.select1("/resources/string[@name='text_noeventfound']", doc)
          .firstChild.data
      );
      setText_delete(
        xpath.select1("/resources/string[@name='text_delete']", doc).firstChild
          .data
      );
      setText_finishedEvent(
        xpath.select1("/resources/string[@name='text_finishedEvent']", doc)
          .firstChild.data
      );
      setText_startson(
        xpath.select1("/resources/string[@name='text_startson']", doc)
          .firstChild.data
      );

      setText_allevents(
        xpath.select1("/resources/string[@name='text_allevents']", doc)
          .firstChild.data
      );
      setText_manage(
        xpath.select1("/resources/string[@name='text_manage']", doc).firstChild
          .data
      );
      setText_previewEve(
        xpath.select1("/resources/string[@name='text_previewEve']", doc)
          .firstChild.data
      );
      setText_viewReport(
        xpath.select1("/resources/string[@name='text_viewReport']", doc)
          .firstChild.data
      );
      setText_duplicate(
        xpath.select1("/resources/string[@name='text_duplicate']", doc)
          .firstChild.data
      );
      setText_status(
        xpath.select1("/resources/string[@name='text_status']", doc).firstChild
          .data
      );

      setText_publish(
        xpath.select1("/resources/string[@name='text_publish']", doc).firstChild
          .data
      );
      setText_pending(
        xpath.select1("/resources/string[@name='text_pending']", doc).firstChild
          .data
      );
      setText_ticket(
        xpath.select1("/resources/string[@name='text_ticket']", doc).firstChild
          .data
      );
      setText_ticketSold(
        xpath.select1("/resources/string[@name='text_ticketSold']", doc)
          .firstChild.data
      );
      setText_searchPh_orgEve(
        xpath.select1("/resources/string[@name='text_ticketSold']", doc)
          .firstChild.data
      );
      setText_yes(
        xpath.select1("/resources/string[@name='text_yes']", doc).firstChild
          .data
      );
      setText_no(
        xpath.select1("/resources/string[@name='text_no']", doc).firstChild.data
      );
    } catch (ert) {}
  };
  const [copiedData, setCopiedData] = useState([]);
  const getduplicateEventData = (eventUniqueId) => {
    var API_URL = API_DOMAIN + `event/copy?event_unique_id=${eventUniqueId}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("copy event details ", response.data.data);
        setCopiedData(response.data.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  };

  console.log(copiedData);
  const submitDuplicateEvent = (eventUniqueId) => {
    var API_URL =
      API_DOMAIN + `event/copy/submit?event_unique_id=${eventUniqueId}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = copiedData;
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("copy event created ", response.data.message);
        if (response.data.message === "event created successfully!") {
          toast.success(response.data.message);
          getAllEvents();
          setOpenDuplicatePopup(false);
          setStartDate(new Date());
          setEndDate(new Date());
          setCopiedData([]);
        } else {
          toast.info(response.data.message);
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  };
  const deleteEvent = () => {
    var API_URL =
      API_DOMAIN +
      `event/delete/byeventuniqueid?event_unique_id=${deleteEventUniqueID}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .delete(API_URL, headerConfig)
      .then(function (response) {
        console.log("delete", response);
        setDeleteEventUniqueID("");
        setOpenDelete(false);
        toast.success(response.data.message);
        getAllEvents();
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  };
  const unpublishEvent = (event_unique_id) => {
    var API_URL = API_DOMAIN + `event/unpublish/byeventuniqueid`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let body = {
      event_unique_id: event_unique_id,
    };
    axios
      .patch(API_URL, body, headerConfig)
      .then(function (response) {
        console.log("Unpublished", response);
        // setDeleteEventUniqueID("");
        // setOpenDelete(false);
        toast.success(response.data.message);
        getAllEvents();
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  };
  return (
    <>
      <Header />
      <OrgDashboardSidebar />
      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-main-title">
                    <h3>
                      <FaCalendarAlt className="me-3" />
                      {text_events}
                    </h3>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main-card mt-5">
                    <div className="dashboard-wrap-content p-4">
                      <h4 className="mb-4 font-sty-event">
                        <b>
                          {text_events} ({AllEventsData.length})
                        </b>
                      </h4>
                      <div className="d-md-flex flex-wrap align-items-center">
                        <div className="dashboard-date-wrap">
                          <div className="form-group">
                            <div className="relative-input position-relative">
                              <input
                                className="form-control h_40"
                                type="text"
                                placeholder={text_searchPh_orgEve}
                                value={searchEvent}
                                onChange={(e) => setSearchEvent(e.target.value)}
                              />
                              <i className="uil uil-search">
                                <CiSearch className="search--icon" />
                              </i>
                            </div>
                          </div>
                        </div>
                        <div className="rs ms-auto mt_r4">
                          <div className="nav custom2-tabs btn-group gap-3">
                            <button
                              className={
                                allEventShow === "AllEvents"
                                  ? "tab-link active event-count-sty"
                                  : "tab-link event-count-sty"
                              }
                              onClick={() => [setAllEventShow("AllEvents")]}
                              type="button"
                              role="tab"
                              aria-controls="all-tab"
                            >
                              {text_allevents} (
                              <span className="total_event_counter">
                                {AllEventsData.length}
                              </span>
                              )
                            </button>
                            <button
                              className={
                                allEventShow === "OnlineEvent"
                                  ? "tab-link active event-count-sty finishbtn"
                                  : "tab-link event-count-sty finishbtn"
                              }
                              onClick={() => [setAllEventShow("OnlineEvent")]}
                              type="button"
                              role="tab"
                              aria-controls="online-tab"
                            >
                              {text_finishedEvent} (
                              <span className="total_event_counter">
                                {OnlineEventsData.length}
                              </span>
                              )
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="event-list">
                    <div className="tab-content">
                      {console.log(
                        allEventShow === "OnlineEvent" && console.log("test")
                      )}
                      {allEventShow === "AllEvents" &&
                        (filteredItems.length === 0 ? (
                          <>
                            <div className="main-card mt-4">
                              <h3 className="text-center font-16 mt-3 fw-bold">
                                {text_noeventfound}
                              </h3>
                            </div>
                          </>
                        ) : (
                          <>
                            {filteredItems.length > 0 &&
                              filteredItems.map((e, i) => {
                                return (
                                  <>
                                    <div
                                      className="tab-pane fade show active"
                                      role="tabpanel"
                                    >
                                      <div className="main-card mt-4">
                                        <div className="contact-list">
                                          <div className="card-top event-top p-4 align-items-center top d-md-flex flex-wrap justify-content-between">
                                            <div className="d-md-flex align-items-center event-top-info">
                                              <div className="card-event-img">
                                                <img
                                                  src={
                                                    e.EventImagePath === "path"
                                                      ? img1
                                                      : e.EventImagePath
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div className="card-event-dt">
                                                <h5>{e.EventTitle}</h5>
                                              </div>
                                            </div>
                                            <div className="dropdown">
                                              <button
                                                className="option-btn"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <FaEllipsisVertical className="search--icon" />
                                              </button>
                                              <div className="dropdown-menu dropdown-menu-right">
                                                <button
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      "/create-event/configuration",
                                                      {
                                                        state: {
                                                          event_id:
                                                            e?.event_unique_id,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  <IoSettingsSharp className="me-3 eye-icon-sty" />
                                                  {text_manage}
                                                </button>
                                                <button
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      "/create-event/edit",
                                                      {
                                                        state: {
                                                          event_id:
                                                            e?.event_unique_id,
                                                          venue_id: e.venueid,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  <IoSettingsSharp className="me-3 eye-icon-sty" />
                                                  Update
                                                </button>
                                                <button
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/event-details/${e?.event_unique_id}`,
                                                      {
                                                        state: {
                                                          event_id:
                                                            e?.event_unique_id,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  <FaEye className="me-3 eye-icon-sty" />
                                                  {text_previewEve}
                                                </button>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/org-report"
                                                  state={{
                                                    event_id:
                                                      e?.event_unique_id,
                                                  }}
                                                >
                                                  <FaChartPie className="me-3 eye-icon-sty" />
                                                  {text_viewReport}
                                                </Link>
                                                <Link
                                                  className="dropdown-item"
                                                  onClick={() => [
                                                    setOpenDuplicatePopup(true),
                                                    getduplicateEventData(
                                                      e?.event_unique_id
                                                    ),
                                                  ]}
                                                >
                                                  <HiDuplicate className="me-3 duplicate-icon" />
                                                  {text_duplicate}
                                                </Link>
                                                {e.isPublish === true ? (
                                                  <></>
                                                ) : (
                                                  <>
                                                    <Link
                                                      className="dropdown-item delete-event"
                                                      onClick={() => [
                                                        setDeleteEventUniqueID(
                                                          e?.event_unique_id
                                                        ),
                                                        setOpenDelete(true),
                                                      ]}
                                                    >
                                                      <FaTrashAlt className="me-3 eye-icon-sty" />
                                                      {text_delete}
                                                    </Link>
                                                  </>
                                                )}
                                                {e.isPublish === true ? (
                                                  <>
                                                    <Link
                                                      className="dropdown-item delete-event"
                                                      onClick={() => [
                                                        unpublishEvent(
                                                          e?.event_unique_id
                                                        ),
                                                      ]}
                                                    >
                                                      <FaTrashAlt className="me-3 eye-icon-sty" />
                                                      Unpublish
                                                    </Link>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="bottom d-flex flex-wrap justify-content-between align-items-center p-4">
                                            <div className="icon-box ">
                                              <span className="icon">
                                                <IoLocationSharp className="location-icon mb-2" />
                                              </span>
                                              <p>{text_status}</p>
                                              <h6 className="coupon-status">
                                                <b>
                                                  {e.isPublish === true ? (
                                                    <>{text_publish}</>
                                                  ) : (
                                                    <>{text_pending}</>
                                                  )}
                                                </b>
                                              </h6>
                                            </div>
                                            <div className="icon-box">
                                              <span className="icon">
                                                <FaCalendarAlt className="mb-2" />
                                              </span>
                                              <p>{text_startson}</p>
                                              <h6 className="coupon-status">
                                                <b>
                                                  {moment
                                                    .utc(e.StartDate)
                                                    .format(
                                                      "DD MMM, yyyy"
                                                    )}{" "}
                                                  {moment
                                                    .utc(e.StartTime)
                                                    .format("hh:mm a")}
                                                </b>
                                              </h6>
                                            </div>
                                            <div className="icon-box">
                                              <span className="icon">
                                                <FaTicketAlt className="mb-2 search--icon" />
                                              </span>
                                              <p>{text_ticket}</p>
                                              <h6 className="coupon-status">
                                                <b>{e.TotalNumberTickets}</b>{" "}
                                              </h6>
                                            </div>
                                            <div className="icon-box">
                                              <span className="icon">
                                                <FaTag className="mb-2" />
                                              </span>
                                              <p>{text_ticketSold}</p>
                                              <h6 className="coupon-status">
                                                <b>
                                                  {e.TicketDetailsList.reduce(
                                                    (data, val) =>
                                                      (data =
                                                        data + val.ticketsold),
                                                    0
                                                  )}
                                                </b>
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        ))}

                      {allEventShow === "OnlineEvent" &&
                        (OnlineEventsData.length === 0 ? (
                          <>
                            {" "}
                            <div className="main-card mt-4">
                              <h3 className="text-center font-16 mt-3 fw-bold">
                                {text_noeventfound}
                              </h3>
                            </div>
                          </>
                        ) : (
                          <>
                            {OnlineEventsData.map((e) => {
                              return (
                                <>
                                  <div>
                                    <div className="main-card mt-4">
                                      <div className="contact-list">
                                        <div className="card-top event-top p-4 align-items-center top d-md-flex flex-wrap justify-content-between">
                                          <div className="d-md-flex align-items-center event-top-info">
                                            <div className="card-event-img">
                                              <img
                                                src={
                                                  e.EventImagePath === "path"
                                                    ? img1
                                                    : e.EventImagePath
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="card-event-dt">
                                              <h5>{e.EventTitle}</h5>
                                            </div>
                                          </div>
                                          <div className="dropdown">
                                            <button
                                              className="option-btn"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              {" "}
                                              <FaEllipsisVertical className="search--icon" />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                              <button
                                                className="dropdown-item"
                                                onClick={() =>
                                                  navigate(
                                                    "/create-event/configuration",
                                                    {
                                                      state: {
                                                        event_id:
                                                          e?.event_unique_id,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                <IoSettingsSharp className="me-3 eye-icon-sty" />
                                                {text_manage}
                                              </button>
                                              <button
                                                className="dropdown-item"
                                                onClick={() =>
                                                  navigate(
                                                    `/event-details/${e?.event_unique_id}`,
                                                    {
                                                      state: {
                                                        event_id:
                                                          e?.event_unique_id,
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                <FaEye className="me-3 eye-icon-sty" />
                                                {text_previewEve}
                                              </button>
                                              <Link className="dropdown-item">
                                                <HiDuplicate className="me-3 duplicate-icon" />
                                                {text_duplicate}
                                              </Link>
                                              {e.isPublish === true ? (
                                                <></>
                                              ) : (
                                                <>
                                                  <Link className="dropdown-item delete-event">
                                                    <FaTrashAlt className="me-3 eye-icon-sty" />
                                                    {text_delete}
                                                  </Link>
                                                </>
                                              )}
                                              {e.isPublish === true ? (
                                                <>
                                                  <Link className="dropdown-item delete-event">
                                                    <FaTrashAlt className="me-3 eye-icon-sty" />
                                                    Unpublish
                                                  </Link>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bottom d-flex flex-wrap justify-content-between align-items-center p-4">
                                          <div className="icon-box ">
                                            <span className="icon">
                                              <IoLocationSharp className="location-icon mb-2" />
                                            </span>
                                            <p>{text_status}</p>
                                            <h6 className="coupon-status">
                                              {e.isPublish === true ? (
                                                <>{text_publish}</>
                                              ) : (
                                                <>{text_pending}</>
                                              )}
                                            </h6>
                                          </div>
                                          <div className="icon-box">
                                            <span className="icon">
                                              <FaCalendarAlt className="mb-2" />
                                            </span>
                                            <p>{text_startson}</p>
                                            <h6 className="coupon-status">
                                              <b>
                                                {moment
                                                  .utc(e.StartDate)
                                                  .format("DD MMM, yyyy")}{" "}
                                                {moment
                                                  .utc(e.StartTime)
                                                  .format("hh:mm a")}
                                              </b>
                                            </h6>
                                          </div>
                                          <div className="icon-box">
                                            <span className="icon">
                                              <FaTicketAlt className="mb-2 search--icon" />
                                            </span>
                                            <p>{text_ticket}</p>
                                            <h6 className="coupon-status">
                                              <b>{e.TotalNumberTickets}</b>{" "}
                                            </h6>
                                          </div>
                                          <div className="icon-box">
                                            <span className="icon">
                                              <FaTag className="mb-2" />
                                            </span>
                                            <p>{text_ticketSold}</p>
                                            <h6 className="coupon-status">
                                              <b>
                                                {e.TicketDetailsList.reduce(
                                                  (data, val) =>
                                                    (data =
                                                      data + val.ticketsold),
                                                  0
                                                )}
                                              </b>
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openDuplicatePopup}
        onClose={() => [setOpenDuplicatePopup(false)]}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title" className="text-center">
          <BiLogIn className="Email-icon hometextnight" />
        </DialogTitle> */}

        <DialogContent>
          <DialogContentText>
            <h3 className="text-center hometextnight">Enter Details</h3>
          </DialogContentText>
          <Divider />
          <div className="form-group mt-4">
            <label className="form-label">
              Event Name
              {/* <span className="starsty">*</span> */}
            </label>
            <input
              className="form-control seasontextnight h_40"
              type="text"
              placeholder="Please Enter Event Name"
              onChange={(e) => [(copiedData.EventTitle = e.target.value)]}
            />
            {/* <span className="validation-err">{guestEmailErr}</span> */}
          </div>
          <div className="form-group mt-4">
            <label className="form-label">
              Start Date
              {/* <span className="starsty">*</span> */}
            </label>
            <DatePicker
              className="form-control dateInputSty h_50 inputleaguenight"
              minDate={tomorrow}
              selected={startDate}
              dateFormat="dd MMM,yyyy"
              onChange={(date) => [
                setStartDate(date),
                setEndDate(date),
                (copiedData.StartDate = moment.utc(date).format("yyyy-MM-DD")),
                (copiedData.EndDate = moment.utc(date).format("yyyy-MM-DD")),
              ]}
            />
            {/* <span className="validation-err">{confirmguestEmailErr}</span> */}
          </div>
          <div className="form-group mt-4">
            <label className="form-label">
              End Date
              {/* <span className="starsty">*</span> */}
            </label>
            <DatePicker
              className="form-control dateInputSty h_50 inputleaguenight"
              selected={endDate}
              minDate={startDate}
              dateFormat="dd MMM,yyyy"
              onChange={(date) => [
                setEndDate(date),
                (copiedData.EndDate = moment.utc(date).format("yyyy-MM-DD")),
              ]}
            />
            {/* <span className="validation-err">{confirmguestEmailErr}</span> */}
          </div>
          <div className="d-flex justify-content-center mb-3 mt-3">
            <button
              className=" me-2 cancelBtnDuplicate "
              onClick={() => setOpenDuplicatePopup(false)}
            >
              Cancel
            </button>
            <button
              className="main-btn btn-hover "
              onClick={() => submitDuplicateEvent(copiedData.event_unique_id)}
            >
              Save
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={openDelete}
        id="MainDiv"
        className="signout-modal"
        onClose={() => setOpenDelete(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-end">
          <CgCloseO
            className="closeIconSty hometextnight"
            onClick={() => [setOpenDelete(false)]}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center hometextnight">
              Are you sure you want to Delete?
            </h3>
          </DialogContentText>
          <Divider />
        </DialogContent>
        <div className="d-flex justify-content-center gap-4 mb-3">
          <button className="popup_btn_yes" onClick={deleteEvent}>
            {text_yes}
          </button>
          <button className="popup_btn_No" onClick={() => setOpenDelete(false)}>
            {text_no}
          </button>
        </div>
      </Dialog>
    </>
  );
}

export default OrgEvent;
