import React, { useState, useEffect } from "react";
import "../Profile/Profile.css";
import {
  FaBookmark,
  FaBox,
  FaCamera,
  FaGlobe,
  FaMoneyBill,
  FaRegEdit,
  FaTicketAlt,
  FaYoutube,
} from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { IoIosCloseCircleOutline, IoMdHome } from "react-icons/io";
import { IoInformationCircle, IoSettingsSharp } from "react-icons/io5";
import { MdOutlineDone } from "react-icons/md";
import { GrUpload } from "react-icons/gr";
import { BiTransferAlt } from "react-icons/bi";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { FaCalendarAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import profileimg from "../../assets/img/dummy_user.png";

import {
  EmailPreferences,
  PrivacySetting,
  timeZonecountries,
} from "../../utils/data";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { AppBar, Box, Divider, Modal, Tab, Tabs } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsCardImage } from "react-icons/bs";
import Loader from "../../utils/Loader";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function Profile() {
  const [openEdit, setOpenEdit] = useState(false);
  const [hasMoreEvents, setHasMoreEvents] = useState();
  const [hasMoreAttendedEvents, setHasMoreAttendedEvents] = useState();

  const [nextToken, setNextToken] = useState("");
  const [OrganisedEvent, setOrganisedEvent] = useState([]);
  const [myorderData, setMyOrderData] = useState([]);
  const [AttenendingEventData, setAttendingEventData] = useState([]);
  const [virtualAttenendingEventData, setVirtualAttendingEventData] = useState(
    []
  );

  const [totalAttendingTicket, setTotalAttendingTicket] = useState("");
  const [virtualAttendingEvents, setVirtualAttendingEvents] = useState("");
  const [totalAttendedTicket, setTotalAttendedTicket] = useState("");
  const [AttenendedEventData, setAttendedEventData] = useState([]);

  const UserName = sessionStorage.getItem("userfirstname");
  const UserLastName = sessionStorage.getItem("userlastname");
  const UserMailID = sessionStorage.getItem("useremail");
  const isOrganisation = sessionStorage.getItem("isOrg");
  const navigate = useNavigate();
  const data = useLocation();
  const dataValue = data.state !== null ? data.state.data : 0;
  const [selectedTab, setSelectedTab] = useState(dataValue);
  const [selectedTicketData, setSelectedTicketData] = useState([]);
  const [openSendTicket, setOpenSendTicket] = useState(false);
  const handleOpenSendTicket = () => setOpenSendTicket(true);
  const handleCloseSendTicket = () => {
    setOpenSendTicket(false);
    setResellExpDate(new Date());
    setResellExpTime(new Date());
  };
  const [transferMobileOrEmail, setTransferMobileOrEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [transferName, setTransferName] = useState("");
  const [resellPrice, setResellPrice] = useState("");
  const [resellExpDate, setResellExpDate] = useState(new Date());
  const [resellExpTime, setResellExpTime] = useState(new Date());
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  var timezones = timeZonecountries[0];
  const FinalData = timezones;

  const [Website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [youtube, setYoutube] = useState("");
  const [firstName, setFirstName] = useState("");
  const [bio, setBio] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [countryID, setCountryID] = useState("");
  const [stateID, setStateID] = useState("");
  const [profileImgUrl, setProfileImgUrl] = useState("");
  const [coverImgUrl, setCoverImgUrl] = useState("");
  const [profileImg, setProfileImg] = useState();
  const [coverImg, setCoverImg] = useState([]);
  const [timeZoneErr, setTimeZoneErr] = useState("");
  const [resellPriceErr, setResellPriceErr] = useState("");
  const [Loading, setLoading] = useState(false);
  const [inviteTeamList, setInviteTeamList] = useState([]);

  const [openProfileImagePopup, setOpenProfileImagePopup] = useState(false);
  const handleOpenProfileImagePopup = () => setOpenProfileImagePopup(true);
  const handleCloseProfileImagePopup = () => setOpenProfileImagePopup(false);
  const [openCoverImagePopup, setOpenCoverImagePopup] = useState(false);
  const handleOpenCoverImagePopup = () => setOpenCoverImagePopup(true);
  const handleCloseCoverImagePopup = () => setOpenCoverImagePopup(false);

  const [openTeam, setOpenTeam] = useState(false);
  const handleOpenTeam = () => setOpenTeam(true);
  const handleCloseTeam = () => setOpenTeam(false);
  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  // language variable
  const [text_tickets, setText_tickets] = useState("Tickets");
  const [text_resell, setText_resell] = useState("Resell");
  const [text_transfer, setText_transfer] = useState("Transfer");
  const [text_transferdisable_event, setText_transferdisable_event] = useState(
    "Transfer is disabled for this event by organizer"
  );
  const [text_reselldisable_event, setText_reselldisable_event] = useState(
    " Resell is disabled for this event by organizer"
  );
  const [text_mobNo, setText_mobNo] = useState("Mobile No.");
  const [text_emailId, setText_emailId] = useState("Email Id");
  const [text_ph_mobileEmail, setText_ph_mobileEmail] = useState(
    "Enter Mobile No. or Email ID"
  );
  const [text_name, setText_name] = useState("Name");
  const [text_ph_name, setText_ph_name] = useState("Enter Name");
  const [text_ticketPrice, setText_ticketPrice] = useState("Ticket Price");
  const [text_close, setText_close] = useState("Close");
  const [text_resell_price, setText_resell_price] =
    useState("Price For Resell");
  const [text_ph_resell_price, setText_ph_resell_price] = useState(
    "Enter Price For Resell"
  );

  const [text_err_timezone, setText_err_timezone] = useState(
    "Please Select TimeZone"
  );
  const [text_err_resellPrice, setText_err_resellPrice] = useState(
    "Please Enter Valid Price"
  );
  const [text_orignal_price, setText_orignal_price] =
    useState("Original Price ");
  const [text_seat, setText_seat] = useState("Seat");
  const [text_sel_TimeZone, setText_sel_TimeZone] =
    useState("Select time zone");
  const [text_commission_ticket, setText_commission_ticket] = useState(
    "Commission On TicketPrice"
  );
  const [text_commission_profit, setText_commission_profit] = useState(
    "Commission On Profit"
  );
  const [text_resell_validTill, setText_resell_validTill] =
    useState("Resell Valid Till");
  const [text_expDate, setText_expDate] = useState("Expiry Date");
  const [text_expTime, setText_expTime] = useState("Expiry Time");
  const [text_timeZone, setText_timeZone] = useState("Time Zone");
  const [text_upload_image, setText_upload_image] = useState("Upload Image");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_download, setText_download] = useState("Download");
  const [text_invoice, setText_invoice] = useState("Invoice");
  const [text_seatamount, setText_seatamount] = useState("Seat Amount");
  const [text_total_ticket, setText_total_ticket] = useState("Total Tickets");
  const [text_eventstartson, setText_eventstartson] =
    useState("Event Starts on");
  const [text_noeventfound, setText_noeventfound] = useState("No Event found");
  const [text_privacysetting, setText_privacysetting] =
    useState("Privacy Settings");
  const [text_updatepass, setText_updatepass] = useState("Update Password");
  const [text_confirmnewpass, setText_confirmnewpass] = useState(
    "Confirm new password"
  );
  const [text_Newpassword, setText_Newpassword] = useState("New password");
  const [text_enterpass, setText_enterpass] = useState("Enter your password");
  const [text_Currentpassword, setText_Currentpassword] =
    useState("Current password");
  const [text_ph_city, setText_ph_city] = useState("Select City");
  const [text_ph_state, setText_ph_state] = useState("Select State");
  const [text_ph_country, setText_ph_country] = useState("Select Country");
  const [text_noticepass, setText_noticepass] = useState(
    "You can update your password from here. If you can't remember your current password, just log out and click on Forgot password."
  );
  const [text_Changepassword, setText_Changepassword] =
    useState("Change password");
  const [text_passwordsettings, setText_passwordsettings] =
    useState("Password Settings");
  const [text_emailpre, setText_emailpre] = useState("Email Preferences");
  const [text_profiton, setText_profiton] = useState("Profit on Resell Price");
  const [text_fixcomm, setText_fixcomm] = useState(
    "Fixed commission on resell"
  );
  const [text_teansferresell, setText_transferresell] =
    useState("Transfer / Resell");
  const [text_manageve, setText_manageeve] = useState("Manage Event");
  const [text_view, setText_view] = useState("View");
  const [text_attendedeve, setText_attendedeve] = useState("Attended Events");
  const [text_attendingeve, setText_attendingeve] =
    useState("Attending Events");
  const [text_organizedeve, setText_organizedeve] =
    useState("Organised Events");
  const [text_savedeve, setText_savedeve] = useState("Saved Events");
  const [text_myorders, setText_myorders] = useState("My Orders");
  const [text_Setting, setText_Setting] = useState("Setting");
  const [text_myorg, setText_myorg] = useState("My Organization");
  const [text_hey1, setText_hey1] = useState("Hey I am a");
  const [text_changeimg, setText_changeimg] = useState("Change Image");
  const [text_bio, setText_bio] = useState("Bio");
  const [text_address, setText_address] = useState("Address");
  const [text_followus, setText_followus] = useState("Follow Us");
  const [text_noticeorgabout, setText_noticeorgabout] = useState(
    "Tell us about yourself and let people know who you are"
  );
  const [text_about, setText_about] = useState("About");
  const [text_edit, setText_edit] = useState("Edit");
  const [text_BrowseMore, setTtext_BrowseMore] = useState("Browse More Events");

  const [text_viewtickets, setText_viewtickets] = useState("View Tickets");
  const [text_owner_Name, setText_owner_Name] = useState("Owner Name");
  const [text_events, setText_events] = useState("Events");
  const [text_home, setText_home] = useState("Home");
  const [text_update, setText_update] = useState("Update");
  const [text_zipcode, setText_zipcode] = useState("Zipcode");
  const [text_city, setText_city] = useState("City");
  const [text_state, setText_state] = useState("State");
  const [text_country, setText_country] = useState("Country");
  const [text_youtube, setText_youtube] = useState("Youtube");
  const [text_insta, setText_insta] = useState("Instagram");
  const [text_facebook, setText_facebook] = useState("Facebook");
  const [text_twitter, setText_twitter] = useState("Twitter");
  const [text_linkedin, setText_linkedin] = useState("LinkedIn");
  const [text_website, setText_website] = useState("Website");
  const [text_phone, setText_phone] = useState("Phone");
  const [text_lastname, setText_lastname] = useState("Last Name");
  const [text_firstname, setText_firstname] = useState("First Name");
  const [text_details, setText_details] = useState("Details");
  const [text_viewteaminvites, setText_viewteaminvites] =
    useState("View Team Invites");
  const [text_noinvitesteam, setText_noinvitesteam] =
    useState("No Invites Team");
  const [text_accept, setText_accept] = useState("Accept");
  const [text_reject, setText_reject] = useState("Reject");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setEmailErr("");
    setTimeZoneErr("");
    setResellPriceErr("");
  };
  const handlesendpopup = (ticketData) => {
    var ddata = [ticketData];
    setSelectedTicketData(ddata);
    handleOpenSendTicket();
  };

  const [savedEvent, setSavedEvent] = useState([]);
  const styleTicket = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  //Attending Event
  const getPurchaseCard = () => {
    var API_URL = API_DOMAIN + "ticket/mybought";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        var res = data?.tickets;
        console.log(res);
        setHasMoreEvents(data?.hasMoreEvents);
        setNextToken(data?.nexttoken);
        setTotalAttendingTicket(data?.ticketFinalTotalCount);
        setAttendingEventData(res);
        var OrderData = [];
        for (var i = 0; i < res.length; i++) {
          OrderData.push(res[i]);
        }

        const uniqUser = [
          ...OrderData.reduce(
            (map, obj) => map.set(obj.event_unique_id, obj),
            new Map()
          ).values(),
        ];
        setMyOrderData(uniqUser);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          if (totalAttendingTicket !== "") {
            toast.error(error.response.data.message);
          }
        }
      })
      .then(function () {});
  };
  const getVirtualPurchaseCard = () => {
    var API_URL =
      API_DOMAIN +
      `virtual/seat/getall/userid?owner=${sessionStorage.getItem(
        "userid"
      )}&category=virtual`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;

        console.log("virtual bought", data, data.length);
        // setHasMoreEvents(data?.hasMoreEvents);
        // setNextToken(data?.nexttoken);
        setVirtualAttendingEvents(data.length);
        setVirtualAttendingEventData(data);
        var OrderData = [];
        // for (var i = 0; i < res.length; i++) {
        //   OrderData.push(res[i]);
        // }

        const uniqUser = [
          ...OrderData.reduce(
            (map, obj) => map.set(obj.event_unique_id, obj),
            new Map()
          ).values(),
        ];
        setMyOrderData(uniqUser);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          if (totalAttendingTicket !== "") {
            toast.error(error.response.data.message);
          }
        }
      })
      .then(function () {});
  };
  const getExpiredPurchaseCard = () => {
    var API_URL = API_DOMAIN + "ticket/mybought/expired";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        var res = data?.tickets;
        console.log(" ateended events", res);
        setTotalAttendedTicket(data?.ticketFinalTotalCount);
        setAttendedEventData(res);
        var OrderData = [];
        for (var i = 0; i < res.length; i++) {
          OrderData.push(res[i]);
        }

        const uniqUser = [
          ...OrderData.reduce(
            (map, obj) => map.set(obj.event_unique_id, obj),
            new Map()
          ).values(),
        ];
        setMyOrderData(uniqUser);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          if (totalAttendingTicket !== "") {
            toast.error(error.response.data.message);
          }
        }
      })
      .then(function () {});
  };
  const getBookmarkedEvents = () => {
    var API_URL = API_DOMAIN + `event/get/bookmarks`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("Bookmarked Events", response);
        setSavedEvent(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const RemoveBookmarkHandle = (eveID) => {
    var API_URL = API_DOMAIN + `event/uncheck/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: false,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Remove", response);
        if (response.data.message === "Bookmark updated") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getPurchaseCard();
    getVirtualPurchaseCard();
    getProfielInfo();
    getBookmarkedEvents();
    getExpiredPurchaseCard();
    getInviteTeamInfo();
  }, []);
  const getMorePurchaseCard = (nextToken, D) => {
    var API_URL =
      API_DOMAIN + `ticket/mybought?limit=12&nexttoken=${nextToken}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data?.tickets;
        for (let i = 0; i < res.length; i++) {
          D.push(res[i]);
          setAttendingEventData(D);
        }
        setHasMoreEvents(response?.data?.data?.hasMoreEvents);
        setNextToken(response?.data?.data?.nexttoken);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const TransferValidation = () => {
    var isValidTransfer = true;
    if (!transferMobileOrEmail) {
      isValidTransfer = false;
      setEmailErr("* Please Enter Email or Mobile Number !");
    }
    return isValidTransfer;
  };

  const TransferTicketHandle = (ticketId) => {
    if (TransferValidation()) {
      var API_URL = API_DOMAIN + "ticket/transferto";

      let headerConfig = {
        headers: {
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        emailOrMobile: transferMobileOrEmail,
        ticket_unique_id: ticketId,
      };

      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log(response?.data.message);

          handleCloseSendTicket();
          setTimeout(() => {
            toast.success(response?.data.message);
          }, 1000);
          getPurchaseCard();
          getVirtualPurchaseCard();
        })
        .catch(function (error) {
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };

  const resellTicketValidation = () => {
    var resellForm = true;
    if (selectedTimeZone === "") {
      resellForm = false;
      setTimeZoneErr(<>* {text_err_timezone}!</>);
    }
    if (resellPrice === "") {
      setResellPriceErr(<>* {text_err_resellPrice}!</>);
    }
    return resellForm;
  };
  const ResellTicketHandle = (ticketId, eveId) => {
    if (resellTicketValidation()) {
      var getHours = selectedTimeZone.slice(1, 3);
      var getMinitus = selectedTimeZone.slice(4, 6);
      var hourtoMinitus = Math.floor(getHours * 60);
      var FinalTimezoneOffset = -(Number(hourtoMinitus) + Number(getMinitus));
      var API_URL = API_DOMAIN + "ticket/resell";

      let headerConfig = {
        headers: {
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        ticket_unique_id: ticketId,
        ticket_resell_price: Number(resellPrice),
        event_unique_id: eveId,
        expiredate: moment(resellExpDate).format("yyyy-MM-D"),
        expiretime: moment(resellExpTime).format("h:mmA"),
        timezoneoffset: FinalTimezoneOffset,
      };

      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log(response?.data.message);

          handleCloseSendTicket();
          setTimeout(() => {
            toast.success(response?.data.message);
          }, 1000);
          getPurchaseCard();
          getVirtualPurchaseCard();
        })
        .catch(function (error) {
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };

  //Organised Event
  const getEventsCard = () => {
    var type = "selectall";
    var API_URL = API_DOMAIN + "event/my?type=" + type;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data;
        console.log("Organised", res);
        setOrganisedEvent(res);
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  useEffect(() => {
    getEventsCard();
  }, []);
  const AboutDetails = [
    {
      name: "Joginder Singh",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt interdum nunc et auctor. Phasellus quis pharetra sapien. Integer ligula sem, sodales vitae varius in, varius eget augue.",
      address: "00 Challis St, Newport, Victoria, 0000, Australia",
    },
  ];

  const handleModalclose = () => {
    document.getElementById("aboutModal").classList.remove("show");
    document.getElementById("aboutModal").removeAttribute("aria-labelledby");
    document.getElementById("aboutModal").removeAttribute("role");
    document.getElementById("aboutModal").removeAttribute("data-bs-toggle");
    document.getElementById("aboutModal").style.display = "none";
  };

  const imguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setProfileImg(file);
        // You can perform further actions with the selected file here
      } else {
        setProfileImg(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };
  const handleProfileImgUpload = () => {
    var API_URL = API_DOMAIN + "file/profile";
    var formData = new FormData();
    formData.append("att_file", profileImg);
    console.log(formData);

    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, formData, headerConfig)
      .then(function (response) {
        console.log("Profile Img Upload", response);
        if (response.status === 200) {
          handleUpdateProfileurl(response?.data?.data?.Location);
          handleCloseProfileImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setTimeout(() => {
            setLoading(false);
            handleCloseProfileImagePopup();
            setProfileImg("");
          });
        }
      })
      .then(function () {});
  };
  const handleUpdateProfileurl = (url) => {
    var API_URL = API_DOMAIN + "user/profile/image/edit";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      ProfileURL: url,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Url Update", response);
        if (response.status === 200) {
          getProfielInfo();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const coverimguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setCoverImg(file);
        // You can perform further actions with the selected file here
      } else {
        setCoverImg(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };

  const handleProfileCoverImgUpload = () => {
    var API_URL = API_DOMAIN + "file/profile/cover";
    var imgdata = new FormData();
    imgdata.append("att_file", coverImg);
    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, imgdata, headerConfig)
      .then(function (response) {
        console.log("Profile Cover Upload", response);
        if (response.status === 200) {
          handleUpdatecoverurl(response.data.data.Location);
          handleCloseCoverImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setTimeout(() => {
            setLoading(false);
            handleCloseCoverImagePopup();
            setCoverImg("");
          });
        }
      })
      .then(function () {});
  };
  const handleUpdatecoverurl = (url) => {
    var API_URL = API_DOMAIN + "user/profile/coverimage/edit";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      ProfileCoverURL: url,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Url Update", response);
        if (response.status === 200) {
          getProfielInfo();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const handleUpdateProfile = (url) => {
    var API_URL = API_DOMAIN + "user/profile/edit";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      Website: Website,
      Facebook: facebook,
      Instagram: instagram,
      Twitter: twitter,
      LinkedIn: linkedin,
      Youtube: youtube,
      firstname: firstName,
      lastname: lastName,
      country: country,
      orgaddress: address,
      orgstate: state,
      zip: zipCode,
      city: city,
      Bio: bio,
      orgDetails: [
        {
          firstname: firstName,
          lastname: lastName,
          orgaddress: address,
          country: country,
          orgstate: state,
          zip: zipCode,
          city: city,
          Bio: bio,
        },
      ],
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Profile Data Update", response);
        if (response.status === 200) {
          sessionStorage.setItem("useremail", response.data.data.email);
          sessionStorage.setItem("usermobile", response.data.data.mobile);
          sessionStorage.setItem("userfirstname", response.data.data.firstname);
          sessionStorage.setItem("userlastname", response.data.data.lastname);

          getProfielInfo();
          handleModalclose();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const getProfielInfo = () => {
    var API_URL = API_DOMAIN + "user/profile/get";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        console.log("profile data", data);
        setWebsite(data?.Website);
        setFacebook(data?.Facebook);
        setInstagram(data?.Instagram);
        setTwitter(data?.Twitter);
        setLinkedin(data?.LinkedIn);
        setYoutube(data?.Youtube);
        setProfileImgUrl(data?.ProfileURL);
        setCoverImgUrl(data?.ProfileCoverURL);
        setFirstName(data?.firstname);
        setLastName(data?.lastname);
        setAddress(data?.orgDetails[0].orgaddress);
        setCountry(data?.orgDetails[0].country);
        setState(data?.orgDetails[0].orgstate);
        setCity(data?.orgDetails[0].city);
        setZipCode(data?.orgDetails[0].zip);
        setBio(data?.Bio);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          if (totalAttendingTicket !== "") {
            toast.error(error.response.data.message);
          }
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_changeimg(
        xpath.select1("/resources/string[@name='text_changeimg']", doc)
          .firstChild.data
      );
      setText_upload_image(
        xpath.select1("/resources/string[@name='text_upload_image']", doc)
          .firstChild.data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_about(
        xpath.select1("/resources/string[@name='text_about']", doc).firstChild
          .data
      );
      setText_Setting(
        xpath.select1("/resources/string[@name='text_Setting']", doc).firstChild
          .data
      );
      setText_myorders(
        xpath.select1("/resources/string[@name='text_myorders']", doc)
          .firstChild.data
      );
      setText_savedeve(
        xpath.select1("/resources/string[@name='text_savedeve']", doc)
          .firstChild.data
      );
      setText_organizedeve(
        xpath.select1("/resources/string[@name='text_organizedeve']", doc)
          .firstChild.data
      );
      setText_attendingeve(
        xpath.select1("/resources/string[@name='text_attendingeve']", doc)
          .firstChild.data
      );
      setText_attendedeve(
        xpath.select1("/resources/string[@name='text_attendedeve']", doc)
          .firstChild.data
      );
      setText_myorg(
        xpath.select1("/resources/string[@name='text_myorg']", doc).firstChild
          .data
      );
      setText_followus(
        xpath.select1("/resources/string[@name='text_followus']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_view(
        xpath.select1("/resources/string[@name='text_view']", doc).firstChild
          .data
      );
      setText_noeventfound(
        xpath.select1("/resources/string[@name='text_noeventfound']", doc)
          .firstChild.data
      );
      setText_events(
        xpath.select1("/resources/string[@name='text_events']", doc).firstChild
          .data
      );
      setText_manageeve(
        xpath.select1("/resources/string[@name='text_manageve']", doc)
          .firstChild.data
      );
      setText_seat(
        xpath.select1("/resources/string[@name='text_seat']", doc).firstChild
          .data
      );
      setText_owner_Name(
        xpath.select1("/resources/string[@name='text_owner_Name']", doc)
          .firstChild.data
      );
      setText_viewtickets(
        xpath.select1("/resources/string[@name='text_viewtickets']", doc)
          .firstChild.data
      );
      setText_transferresell(
        xpath.select1("/resources/string[@name='text_teansferresell']", doc)
          .firstChild.data
      );
      setText_tickets(
        xpath.select1("/resources/string[@name='text_tickets']", doc).firstChild
          .data
      );
      setText_transfer(
        xpath.select1("/resources/string[@name='text_transfer']", doc)
          .firstChild.data
      );
      setText_transferdisable_event(
        xpath.select1(
          "/resources/string[@name='text_transferdisable_event']",
          doc
        ).firstChild.data
      );
      setText_resell(
        xpath.select1("/resources/string[@name='text_resell']", doc).firstChild
          .data
      );
      setText_reselldisable_event(
        xpath.select1(
          "/resources/string[@name='text_reselldisable_event']",
          doc
        ).firstChild.data
      );

      setText_name(
        xpath.select1("/resources/string[@name='text_name']", doc).firstChild
          .data
      );
      setText_noticeorgabout(
        xpath.select1("/resources/string[@name='text_noticeorgabout']", doc)
          .firstChild.data
      );
      setText_edit(
        xpath.select1("/resources/string[@name='text_edit']", doc).firstChild
          .data
      );
      setText_address(
        xpath.select1("/resources/string[@name='text_address']", doc).firstChild
          .data
      );
      setText_firstname(
        xpath.select1("/resources/string[@name='text_firstname']", doc)
          .firstChild.data
      );
      setText_lastname(
        xpath.select1("/resources/string[@name='text_lastname']", doc)
          .firstChild.data
      );
      setText_bio(
        xpath.select1("/resources/string[@name='text_bio']", doc).firstChild
          .data
      );
      setText_phone(
        xpath.select1("/resources/string[@name='text_phone']", doc).firstChild
          .data
      );
      setText_website(
        xpath.select1("/resources/string[@name='text_website']", doc).firstChild
          .data
      );
      setText_facebook(
        xpath.select1("/resources/string[@name='text_facebook']", doc)
          .firstChild.data
      );
      setText_insta(
        xpath.select1("/resources/string[@name='text_insta']", doc).firstChild
          .data
      );
      setText_twitter(
        xpath.select1("/resources/string[@name='text_twitter']", doc).firstChild
          .data
      );
      setText_linkedin(
        xpath.select1("/resources/string[@name='text_linkedin']", doc)
          .firstChild.data
      );
      setText_youtube(
        xpath.select1("/resources/string[@name='text_youtube']", doc).firstChild
          .data
      );
      setText_country(
        xpath.select1("/resources/string[@name='text_country']", doc).firstChild
          .data
      );
      setText_ph_country(
        xpath.select1("/resources/string[@name='text_ph_country']", doc)
          .firstChild.data
      );
      setText_state(
        xpath.select1("/resources/string[@name='text_state']", doc).firstChild
          .data
      );
      setText_ph_state(
        xpath.select1("/resources/string[@name='text_ph_state']", doc)
          .firstChild.data
      );
      setText_city(
        xpath.select1("/resources/string[@name='text_city']", doc).firstChild
          .data
      );
      setText_ph_city(
        xpath.select1("/resources/string[@name='text_ph_city']", doc).firstChild
          .data
      );
      setText_zipcode(
        xpath.select1("/resources/string[@name='text_zipcode']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_update(
        xpath.select1("/resources/string[@name='text_update']", doc).firstChild
          .data
      );
      setText_emailpre(
        xpath.select1("/resources/string[@name='text_emailpre']", doc)
          .firstChild.data
      );
      setText_passwordsettings(
        xpath.select1("/resources/string[@name='text_passwordsettings']", doc)
          .firstChild.data
      );
      setText_Changepassword(
        xpath.select1("/resources/string[@name='text_Changepassword']", doc)
          .firstChild.data
      );
      setText_noticepass(
        xpath.select1("/resources/string[@name='text_noticepass']", doc)
          .firstChild.data
      );
      setText_Currentpassword(
        xpath.select1("/resources/string[@name='text_Currentpassword']", doc)
          .firstChild.data
      );
      setText_enterpass(
        xpath.select1("/resources/string[@name='text_enterpass']", doc)
          .firstChild.data
      );
      setText_Newpassword(
        xpath.select1("/resources/string[@name='text_Newpassword']", doc)
          .firstChild.data
      );
      setText_confirmnewpass(
        xpath.select1("/resources/string[@name='text_confirmnewpass']", doc)
          .firstChild.data
      );
      setText_updatepass(
        xpath.select1("/resources/string[@name='text_updatepass']", doc)
          .firstChild.data
      );
      setText_eventstartson(
        xpath.select1("/resources/string[@name='text_eventstartson']", doc)
          .firstChild.data
      );
      setText_total_ticket(
        xpath.select1("/resources/string[@name='text_total_ticket']", doc)
          .firstChild.data
      );
      setText_seatamount(
        xpath.select1("/resources/string[@name='text_seatamount']", doc)
          .firstChild.data
      );
      setText_invoice(
        xpath.select1("/resources/string[@name='text_invoice']", doc).firstChild
          .data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_fixcomm(
        xpath.select1("/resources/string[@name='text_fixcomm']", doc).firstChild
          .data
      );
      setText_mobNo(
        xpath.select1("/resources/string[@name='text_mobNo']", doc).firstChild
          .data
      );
      setText_ph_mobileEmail(
        xpath.select1("/resources/string[@name='text_ph_mobileEmail']", doc)
          .firstChild.data
      );
      setText_ph_name(
        xpath.select1("/resources/string[@name='text_ph_name']", doc).firstChild
          .data
      );
      setText_ticketPrice(
        xpath.select1("/resources/string[@name='text_ticketPrice']", doc)
          .firstChild.data
      );
      setText_close(
        xpath.select1("/resources/string[@name='text_close']", doc).firstChild
          .data
      );

      setText_resell_price(
        xpath.select1("/resources/string[@name='text_resell_price']", doc)
          .firstChild.data
      );
      setText_ph_resell_price(
        xpath.select1("/resources/string[@name='text_ph_resell_price']", doc)
          .firstChild.data
      );
      setText_err_timezone(
        xpath.select1("/resources/string[@name='text_err_timezone']", doc)
          .firstChild.data
      );
      setText_err_resellPrice(
        xpath.select1("/resources/string[@name='text_err_resellPrice']", doc)
          .firstChild.data
      );
      setText_orignal_price(
        xpath.select1("/resources/string[@name='text_orignal_price']", doc)
          .firstChild.data
      );

      setText_resell_validTill(
        xpath.select1("/resources/string[@name='text_resell_validTill']", doc)
          .firstChild.data
      );
      setText_expDate(
        xpath.select1("/resources/string[@name='text_expDate']", doc).firstChild
          .data
      );
      setText_expTime(
        xpath.select1("/resources/string[@name='text_expTime']", doc).firstChild
          .data
      );
      setText_timeZone(
        xpath.select1("/resources/string[@name='text_timeZone']", doc)
          .firstChild.data
      );
      setText_privacysetting(
        xpath.select1("/resources/string[@name='text_privacysetting']", doc)
          .firstChild.data
      );
      setText_profiton(
        xpath.select1("/resources/string[@name='text_profiton']", doc)
          .firstChild.data
      );
      setText_details(
        xpath.select1("/resources/string[@name='text_details']", doc).firstChild
          .data
      );
      setText_hey1(
        xpath.select1("/resources/string[@name='text_hey1']", doc).firstChild
          .data
      );
      setText_commission_profit(
        xpath.select1("/resources/string[@name='text_commission_profit']", doc)
          .firstChild.data
      );
      setText_commission_ticket(
        xpath.select1("/resources/string[@name='text_commission_ticket']", doc)
          .firstChild.data
      );
      setTtext_BrowseMore(
        xpath.select1("/resources/string[@name='text_BrowseMore']", doc)
          .firstChild.data
      );
      setText_viewteaminvites(
        xpath.select1("/resources/string[@name='text_viewteaminvites']", doc)
          .firstChild.data
      );
      setText_noinvitesteam(
        xpath.select1("/resources/string[@name='text_noinvitesteam']", doc)
          .firstChild.data
      );
      setText_accept(
        xpath.select1("/resources/string[@name='text_accept']", doc).firstChild
          .data
      );
      setText_reject(
        xpath.select1("/resources/string[@name='text_reject']", doc).firstChild
          .data
      );
      setText_sel_TimeZone(
        xpath.select1("/resources/string[@name='text_sel_TimeZone']", doc)
          .firstChild.data
      );
      setText_emailId(
        xpath.select1("/resources/string[@name='text_emailId']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  const getInviteTeamInfo = () => {
    var API_URL = API_DOMAIN + "team/myinvitation";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response.data.data;
        console.log("View Invite Team List", data);
        const filteredData = data.filter((v) => v.isexpired === false);
        setInviteTeamList(filteredData);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        }
      })
      .then(function () {});
  };

  const handleActiveOrg = (id) => {
    console.log(id);
    var API_URL = API_DOMAIN + `team/verifylink?key=${id}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response;
        console.log("active", res);
        if (res.status === 200) {
          toast.success(res.data.message);
          handleCloseTeam();
          getInviteTeamInfo();
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  };

  const handleRejectOrg = (id) => {
    console.log(id);
    var API_URL = API_DOMAIN + `team/rejectinvit?key=${id}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response;
        console.log("reject", res);
        if (res.status === 200) {
          toast.success(res.data.message);
          handleCloseTeam();
          getInviteTeamInfo();
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  };
  return (
    <>
      <Header />
      <div
        className="modal-org modal-edit-popup modal-edit-popup-responsive fade"
        id="aboutModal"
        tabindex="-1"
        aria-labelledby={openEdit ? "aboutModalLabel" : ""}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-profile">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="aboutModalLabel">
                {text_edit} {text_details}
              </h5>
              <button
                type="button"
                className="close-model-btn"
                data-bs-dismiss="modal-org"
                data-bs-target="#aboutModal"
                data-bs-toggle="modal"
                aria-label="Close"
              >
                <RxCross1 />
              </button>
            </div>
            <div className="modal-body">
              <div className="model-content main-form">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_firstname}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_lastname}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_bio}
                        <span className="starsty">*</span>
                      </label>
                      <textarea
                        className="form-textarea"
                        placeholder=""
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_emailId}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={sessionStorage.getItem("useremail")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_phone}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={sessionStorage.getItem("usermobile")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_website}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={Website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_facebook}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={facebook}
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_insta}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={instagram}
                        onChange={(e) => setInstagram(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_twitter}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={twitter}
                        onChange={(e) => setTwitter(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_linkedin}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={linkedin}
                        onChange={(e) => setLinkedin(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_youtube}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={youtube}
                        onChange={(e) => setYoutube(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <h4 className="address-title">{text_address}</h4>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_address}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group main-form mt-4">
                      <label className="form-label">
                        {text_country}
                        <span className="starsty">*</span>
                      </label>
                      <CountrySelect
                        onChange={(e) => [
                          setCountryID(e.id),
                          setCountry(e.name),
                        ]}
                        showFlag={false}
                        placeHolder={text_ph_country}
                        className="form-control seasontextnight h_40"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_state}
                        <span className="starsty">*</span>
                      </label>
                      <StateSelect
                        countryid={countryID}
                        onChange={(e) => [setStateID(e.id), setState(e.name)]}
                        placeHolder={text_ph_state}
                        className="form-control seasontextnight h_40"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_city}
                        <span className="starsty">*</span>
                      </label>
                      <CitySelect
                        countryid={countryID}
                        stateid={stateID}
                        onChange={(e) => [setCity(e.name)]}
                        placeHolder={text_ph_city}
                        className="form-control seasontextnight h_40"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">
                        {text_zipcode}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="co-main-btn min-width"
                data-bs-target="#aboutModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal-org"
                onClick={() => handleModalclose()}
              >
                {text_cancel}
              </button>
              <button
                type="button"
                className="main-btn min-width btn-hover h_40 profile-update-btn"
                data-bs-target="#aboutModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal-org"
                onClick={handleUpdateProfile}
              >
                {text_update}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper eventdetail-nightmod" id="otherPages">
        <div className="profile-banner">
          <div className="hero-cover-block">
            <div className="hero-cover">
              <div className="hero-cover-img">
                <img
                  src={coverImgUrl === "" ? profileImg : coverImgUrl}
                  alt=""
                  className="hero-cover-img"
                />
              </div>
            </div>
            <div className="upload-cover">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div
                      className="cover-img-btn"
                      onClick={handleOpenCoverImagePopup}
                    >
                      <input type="file" id="" />
                      <label for="cover-img">
                        <BsCardImage /> {text_changeimg}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="user-dt-block">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-12">
                  <div className="main-card user-left-dt">
                    <div className="user-avatar-img">
                      <img
                        src={profileImgUrl === "" ? profileimg : profileImgUrl}
                        alt=""
                      />
                      <div
                        className="avatar-img-btn"
                        onClick={() => handleOpenProfileImagePopup()}
                      >
                        <input type="file" />
                        <label for="avatar-img">
                          <FaCamera />
                        </label>
                      </div>
                    </div>
                    <div className="user-dts">
                      <h4 className="user-name">
                        {UserName}
                        <span className="verify-badge">
                          <MdOutlineDone className="verify-person" />
                        </span>
                      </h4>
                      <span className="user-email">{UserMailID}</span>
                    </div>
                    <div className="user-description">
                      <p>
                        {text_hey1} {UserName}
                      </p>
                    </div>
                    {isOrganisation === "false" ||
                    isOrganisation === "undefined" ||
                    isOrganisation === undefined ||
                    isOrganisation === null ? (
                      ""
                    ) : (
                      <>
                        {" "}
                        <div className="user-btns">
                          <Link
                            to="/org-dashboard"
                            className="co-main-btn co-btn-width min-width d-inline-block h_40 profile-lin-style"
                          >
                            {text_myorg} &nbsp;
                            <FaArrowRightArrowLeft />
                          </Link>
                        </div>
                        <br />
                      </>
                    )}
                    <div className="">
                      <button
                        className="co-main-btn co-btn-width min-width d-inline-block h_40 invite-style"
                        data-bs-toggle="modal"
                        data-bs-target="#bankModal"
                        onClick={handleOpenTeam}
                      >
                        {text_viewteaminvites}
                      </button>
                    </div>
                    <div className="profile-social-link">
                      <h6>{text_followus}</h6>
                      <div className="social-links">
                        <Link
                          to="#"
                          className="social-link"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Facebook"
                        >
                          <FaFacebookSquare />
                        </Link>
                        <Link
                          to="#"
                          className="social-link"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Instagram"
                        >
                          <FaInstagram />
                        </Link>
                        <Link
                          to="#"
                          className="social-link"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Twitter"
                        >
                          <FaTwitter />
                        </Link>
                        <Link
                          to="#"
                          className="social-link"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="LinkedIn"
                        >
                          <FaLinkedinIn />
                        </Link>
                        <Link
                          to="#"
                          className="social-link"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Youtube"
                        >
                          <FaYoutube />
                        </Link>
                        <Link
                          to="#"
                          className="social-link"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Website"
                        >
                          <FaGlobe />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-7 col-md-12">
                  <div className="right-profile">
                    <div className="profile-tabs">
                      <ul
                        className="nav nav-pills nav-fill p-2 garren-line-tab"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <Link
                            className="nav-link active"
                            id="feed-tab"
                            data-bs-toggle="tab"
                            to="#feed"
                            role="tab"
                            aria-controls="feed"
                            aria-selected="true"
                          >
                            <IoMdHome /> {text_home}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            id="about-tab"
                            data-bs-toggle="tab"
                            to="#about"
                            role="tab"
                            aria-controls="about"
                            aria-selected="false"
                          >
                            <IoInformationCircle /> {text_about}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            id="setting-tab"
                            data-bs-toggle="tab"
                            to="#setting"
                            role="tab"
                            aria-controls="setting"
                            aria-selected="false"
                          >
                            <IoSettingsSharp /> {text_Setting}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            id="orders-tab"
                            to="#orders"
                            data-bs-toggle="tab"
                            onClick={() => getPurchaseCard()}
                            role="tab"
                            aria-controls="orders"
                            aria-selected="false"
                          >
                            <FaBox /> {text_myorders}
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade active show"
                          id="feed"
                          role="tabpanel"
                          aria-labelledby="feed-tab"
                        >
                          <div
                            className="nav my-event-tabs mt-4"
                            role="tablist"
                          >
                            {isOrganisation === "false" ||
                            isOrganisation === "undefined" ||
                            isOrganisation === undefined ||
                            isOrganisation === null ? (
                              <>
                                <button
                                  className="event-link active"
                                  data-bs-toggle="tab"
                                  data-bs-target="#saved"
                                  type="button"
                                  role="tab"
                                  aria-controls="saved"
                                  aria-selected="true"
                                >
                                  <span className="event-count">
                                    {savedEvent.length}
                                  </span>
                                  <span>{text_savedeve}</span>
                                </button>

                                <button
                                  className="event-link"
                                  data-bs-toggle="tab"
                                  data-bs-target="#attending"
                                  type="button"
                                  role="tab"
                                  aria-controls="attending"
                                  aria-selected="false"
                                >
                                  <span className="event-count">
                                    {totalAttendingTicket === ""
                                      ? 0
                                      : totalAttendingTicket}
                                  </span>
                                  <span>{text_attendingeve}</span>
                                </button>
                                <button
                                  className="event-link-org"
                                  data-bs-toggle="tab"
                                  data-bs-target="#virtualattending"
                                  type="button"
                                  role="tab"
                                  aria-controls="virtualattending"
                                  aria-selected="false"
                                >
                                  <span className="event-count">
                                    {virtualAttendingEvents === ""
                                      ? 0
                                      : virtualAttendingEvents}
                                  </span>
                                  <span>Virtual {text_attendingeve}</span>
                                </button>
                                <button
                                  className="event-link"
                                  data-bs-toggle="tab"
                                  data-bs-target="#attended"
                                  type="button"
                                  role="tab"
                                  aria-controls="attended"
                                  aria-selected="false"
                                >
                                  <span className="event-count">
                                    {totalAttendedTicket === ""
                                      ? 0
                                      : totalAttendedTicket}
                                  </span>
                                  <span>{text_attendedeve}</span>
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="event-link-org active"
                                  data-bs-toggle="tab"
                                  data-bs-target="#saved"
                                  type="button"
                                  role="tab"
                                  aria-controls="saved"
                                  aria-selected="true"
                                >
                                  <span className="event-count">
                                    {savedEvent.length}
                                  </span>
                                  <span>{text_savedeve}</span>
                                </button>

                                <button
                                  className="event-link-org"
                                  data-bs-toggle="tab"
                                  data-bs-target="#organised"
                                  type="button"
                                  role="tab"
                                  aria-controls="organised"
                                  aria-selected="false"
                                >
                                  <span className="event-count">
                                    {OrganisedEvent.length}
                                  </span>
                                  <span>{text_organizedeve}</span>
                                </button>

                                <button
                                  className="event-link-org"
                                  data-bs-toggle="tab"
                                  data-bs-target="#attending"
                                  type="button"
                                  role="tab"
                                  aria-controls="attending"
                                  aria-selected="false"
                                >
                                  <span className="event-count">
                                    {totalAttendingTicket === ""
                                      ? 0
                                      : totalAttendingTicket}
                                  </span>
                                  <span>{text_attendingeve}</span>
                                </button>
                                <button
                                  className="event-link-org"
                                  data-bs-toggle="tab"
                                  data-bs-target="#virtualattending"
                                  type="button"
                                  role="tab"
                                  aria-controls="virtualattending"
                                  aria-selected="false"
                                >
                                  <span className="event-count">
                                    {virtualAttendingEvents === ""
                                      ? 0
                                      : virtualAttendingEvents}
                                  </span>
                                  <span>Virtual {text_attendingeve}</span>
                                </button>

                                <button
                                  className="event-link-org"
                                  data-bs-toggle="tab"
                                  data-bs-target="#attended"
                                  type="button"
                                  role="tab"
                                  aria-controls="attended"
                                  aria-selected="false"
                                >
                                  <span className="event-count">
                                    {totalAttendedTicket === ""
                                      ? 0
                                      : totalAttendedTicket}
                                  </span>
                                  <span>{text_attendedeve}</span>
                                </button>
                              </>
                            )}
                          </div>
                          <div className="tab-content">
                            <div
                              className="tab-pane fade show active"
                              id="saved"
                              role="tabpanel"
                            >
                              {savedEvent.length === 0 ? (
                                <>
                                  <div className="main-card mt-4">
                                    <h3 className="text-center font-16 mt-3 fw-bold">
                                      {text_noeventfound}
                                    </h3>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row scroll-attend-event scroll-org-event">
                                    {savedEvent.map((v) => {
                                      return (
                                        <>
                                          <div className="col-md-12">
                                            <div className="main-card mt-4">
                                              <div className="card-top p-4">
                                                <div className="card-event-img card-event-img-responsive">
                                                  <img
                                                    src={
                                                      v?.event_details[0]
                                                        .EventImagePath ===
                                                      "path"
                                                        ? img1
                                                        : v?.event_details[0]
                                                            .EventImagePath
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="card-event-dt">
                                                  <h5>
                                                    {
                                                      v.event_details[0]
                                                        .EventTitle
                                                    }
                                                  </h5>
                                                  <div className="evnt-time">
                                                    {moment
                                                      .utc(
                                                        v?.event_details[0]
                                                          .StartDate
                                                      )
                                                      .format(
                                                        "ddd MMM DD, yyyy"
                                                      )}{" "}
                                                    {moment
                                                      .utc(
                                                        v?.event_details[0]
                                                          .StartTime
                                                      )
                                                      .format("h:mmA")}{" "}
                                                    -{" "}
                                                    {moment
                                                      .utc(
                                                        v?.event_details[0]
                                                          .EndDate
                                                      )
                                                      .format(
                                                        "ddd MMM DD, yyyy"
                                                      )}{" "}
                                                    {moment
                                                      .utc(
                                                        v?.event_details[0]
                                                          .EndTime
                                                      )
                                                      .format("h:mmA")}
                                                  </div>
                                                  <div className="event-btn-group">
                                                    <button
                                                      className="esv-btn saved-btn me-2"
                                                      onClick={() =>
                                                        RemoveBookmarkHandle(
                                                          v?.event_unique_id
                                                        )
                                                      }
                                                    >
                                                      <FaBookmark
                                                        className="bookmarkicon"
                                                        style={{ scale: "0.7" }}
                                                      />
                                                      {text_save}
                                                    </button>
                                                    <button className="esv-btn me-2">
                                                      <Link
                                                        to={`/event-details/${v?.event_unique_id}`}
                                                        className="profile-event-view"
                                                      >
                                                        <GrUpload /> {text_view}
                                                      </Link>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </div>

                            <div
                              className="tab-pane fade"
                              id="organised"
                              role="tabpanel"
                            >
                              {OrganisedEvent.length === 0 ? (
                                <>
                                  <div className="main-card mt-4">
                                    <h3 className="text-center font-16 mt-3 fw-bold">
                                      {text_noeventfound}
                                    </h3>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row scroll-attend-event scroll-org-event">
                                    {OrganisedEvent.map((v) => {
                                      return (
                                        <>
                                          <div className="col-md-12">
                                            <div className="main-card mt-4">
                                              <div className="card-top p-4">
                                                <div className="card-event-img">
                                                  <img
                                                    src={
                                                      v?.EventImagePath ===
                                                      "path"
                                                        ? img1
                                                        : v?.EventImagePath
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="card-event-dt">
                                                  <h5>{v.EventTitle}</h5>
                                                  {v?.isvirtual===true?<>
                                                  <div className="d-flex
                                                  ">
                                                    <p className="owner-title-sty">
                                                      Online{" "}
                                                    </p>
                                                  </div>
                                                  </>:<>
                                                  <div className="d-flex">
                                                    <p className="owner-title-sty">
                                                      {text_events}:{" "}
                                                    </p>
                                                    <span className="mx-2 owner-name">
                                                      {v.venuedetails.length ===
                                                      0 ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          {
                                                            v?.venuedetails[0]
                                                              .venuename
                                                          }{" "}
                                                          ({" "}
                                                          {
                                                            v?.venuedetails[0]
                                                              .address
                                                          }
                                                          ,{" "}
                                                          {
                                                            v?.venuedetails[0]
                                                              .city
                                                          }
                                                          ,{" "}
                                                          {
                                                            v?.venuedetails[0]
                                                              .state
                                                          }
                                                          ,{" "}
                                                          {
                                                            v?.venuedetails[0]
                                                              .country
                                                          }
                                                          ,{" "}
                                                          {
                                                            v?.venuedetails[0]
                                                              .zipcode
                                                          }
                                                          )
                                                        </>
                                                      )}
                                                    </span>
                                                  </div>

                                                  </>}
                                                  <div className="mx-1 owner-name">
                                                    {moment
                                                      .utc(v?.StartDate)
                                                      .format(
                                                        "ddd MMM DD, yyyy"
                                                      )}{" "}
                                                    {moment
                                                      .utc(v?.StartTime)
                                                      .format("h:mmA")}{" "}
                                                    -{" "}
                                                    {moment
                                                      .utc(v?.EndDate)
                                                      .format(
                                                        "ddd MMM DD, yyyy"
                                                      )}{" "}
                                                    {moment
                                                      .utc(v?.EndTime)
                                                      .format("h:mmA")}
                                                  </div>

                                                  <div className="event-btn-group">
                                                    <button
                                                      className="esv-btn me-2"
                                                      onClick={() =>
                                                        navigate(
                                                          "/create-event/configuration",
                                                          {
                                                            state: {
                                                              event_id:
                                                                v?.event_unique_id,
                                                            },
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <IoSettingsSharp />
                                                      {text_manageve}
                                                    </button>
                                                    <button
                                                      className="esv-btn me-2"
                                                      onClick={
                                                        v?.isvirtual === true
                                                          ? () =>
                                                              navigate(
                                                                "/create-virtual-event/edit",
                                                                {
                                                                  state: {
                                                                    event_id:
                                                                      v?.event_unique_id,
                                                                    venue_id:
                                                                      v.venueid,
                                                                  },
                                                                }
                                                              )
                                                          : () =>
                                                              navigate(
                                                                "/create-event/edit",
                                                                {
                                                                  state: {
                                                                    event_id:
                                                                      v?.event_unique_id,
                                                                    venue_id:
                                                                      v.venueid,
                                                                  },
                                                                }
                                                              )
                                                      }
                                                    >
                                                      <IoSettingsSharp />
                                                      {text_update}
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </div>
                            <div
                              className="tab-pane fade"
                              id="attending"
                              role="tabpanel"
                            >
                              {totalAttendingTicket === "" ? (
                                <>
                                  <div className="main-card mt-4">
                                    <h3 className="text-center font-16 mt-3 fw-bold">
                                      {text_noeventfound}
                                    </h3>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row scroll-attend-event">
                                    {AttenendingEventData.length > 0 &&
                                      AttenendingEventData.map((v) => {
                                        return (
                                          <>
                                            <div className="col-md-12">
                                              <div className="main-card mt-4">
                                                <div className="card-top p-4">
                                                  <div className="card-event-img">
                                                    <img
                                                      src={
                                                        v?.eventsdetails[0]
                                                          ?.EventImagePath ===
                                                        "path"
                                                          ? img1
                                                          : v?.eventsdetails[0]
                                                              ?.EventImagePath
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="card-event-dt">
                                                    <h5>
                                                      {
                                                        v?.eventsdetails[0]
                                                          ?.EventTitle
                                                      }
                                                    </h5>
                                                    {v?.seat_name === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        <div className="evnt-time d-flex">
                                                          <p className="owner-title-sty">
                                                            {text_seat}:
                                                          </p>{" "}
                                                          <p className="mx-2 owner-name">
                                                            {" "}
                                                            {v?.seat_name}
                                                          </p>
                                                        </div>
                                                      </>
                                                    )}
                                                    {v?.ownername === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        <div className="evnt-time d-flex">
                                                          <p className="owner-title-sty">
                                                            {text_owner_Name}:
                                                          </p>{" "}
                                                          <p className="mx-2 owner-name">
                                                            {" "}
                                                            {v?.ownername}
                                                          </p>
                                                        </div>
                                                      </>
                                                    )}
                                                    <div className="d-flex">
                                                      {v?.eventsdetails[0]
                                                        .venuedetails[0]
                                                        .venuename === "" ||
                                                      v?.eventsdetails[0]
                                                        .venuedetails[0]
                                                        .venuename ===
                                                        undefined ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <p className="owner-title-sty">
                                                            {text_events}:{" "}
                                                          </p>
                                                          <span className="mx-2 owner-name">
                                                            {v?.eventsdetails[0]
                                                              .venuedetails[0]
                                                              .venuename ===
                                                              "" ||
                                                            v?.eventsdetails[0]
                                                              .venuedetails[0]
                                                              .venuename ===
                                                              undefined ? (
                                                              ""
                                                            ) : (
                                                              <>
                                                                {
                                                                  v
                                                                    ?.eventsdetails[0]
                                                                    .venuedetails[0]
                                                                    .venuename
                                                                }{" "}
                                                                ~{" "}
                                                                {
                                                                  v
                                                                    ?.eventsdetails[0]
                                                                    .venuedetails[0]
                                                                    .address
                                                                }
                                                                {", "}
                                                                {
                                                                  v
                                                                    ?.eventsdetails[0]
                                                                    .venuedetails[0]
                                                                    .city
                                                                }
                                                                {", "}
                                                                <br />{" "}
                                                                {
                                                                  v
                                                                    ?.eventsdetails[0]
                                                                    .venuedetails[0]
                                                                    .state
                                                                }
                                                                {", "}
                                                                {
                                                                  v
                                                                    ?.eventsdetails[0]
                                                                    .venuedetails[0]
                                                                    .country
                                                                }
                                                                {", "}
                                                                {
                                                                  v
                                                                    ?.eventsdetails[0]
                                                                    .venuedetails[0]
                                                                    .zipcode
                                                                }
                                                              </>
                                                            )}
                                                          </span>
                                                        </>
                                                      )}
                                                    </div>
                                                    <div className="mx-1 owner-name mt-1">
                                                      {moment
                                                        .utc(
                                                          v?.eventsdetails[0]
                                                            ?.StartDate
                                                        )
                                                        .format(
                                                          "ddd, MMM DD, yyyy"
                                                        )}{" "}
                                                      {moment
                                                        .utc(
                                                          v?.eventsdetails[0]
                                                            ?.StartTime
                                                        )
                                                        .format("h:mmA")}{" "}
                                                      -{" "}
                                                      {moment
                                                        .utc(
                                                          v?.eventsdetails[0]
                                                            ?.EndDate
                                                        )
                                                        .format(
                                                          "ddd, MMM DD, yyyy"
                                                        )}{" "}
                                                      {moment
                                                        .utc(
                                                          v?.eventsdetails[0]
                                                            ?.EndTime
                                                        )
                                                        .format("h:mmA")}
                                                    </div>
                                                    <div className="d-flex">
                                                      {" "}
                                                      <div className="event-btn-group">
                                                        <button
                                                          className="esv-btn me-2"
                                                          onClick={() => [
                                                            navigate(
                                                              "/view-ticket-invoice"
                                                            ),
                                                            sessionStorage.setItem(
                                                              "ticketDataProfile",
                                                              JSON.stringify(v)
                                                            ),
                                                            sessionStorage.setItem(
                                                              "EveDetail",
                                                              null
                                                            ),
                                                            sessionStorage.setItem(
                                                              "ownerDetails",
                                                              null
                                                            ),
                                                            sessionStorage.setItem(
                                                              "SeatsDetails",
                                                              null
                                                            ),
                                                          ]}
                                                        >
                                                          <GrUpload />
                                                          {text_viewtickets}
                                                        </button>
                                                      </div>
                                                      <div className="event-btn-group">
                                                        <button
                                                          className="transfer-btn  me-2"
                                                          onClick={() =>
                                                            handlesendpopup(v)
                                                          }
                                                        >
                                                          <BiTransferAlt />{" "}
                                                          {text_teansferresell}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <Modal
                                              open={openSendTicket}
                                              onClose={() => [
                                                handleCloseSendTicket(),
                                                setEmailErr(""),
                                                setTimeZoneErr(""),
                                                setResellPriceErr(""),
                                              ]}
                                              aria-labelledby="modal-modal-title"
                                              aria-describedby="modal-modal-description"
                                              className="resell-Modal"
                                            >
                                              <Box
                                                sx={styleTicket}
                                                className="responsivelogin"
                                                id="header-registermodal"
                                              >
                                                <div className="d-flex justify-content-end">
                                                  <IoIosCloseCircleOutline
                                                    className="closeIcon-venue hometextnight"
                                                    onClick={() => [
                                                      handleCloseSendTicket(),
                                                      setEmailErr(""),
                                                      setTimeZoneErr(""),
                                                      setResellPriceErr(""),
                                                    ]}
                                                  />
                                                </div>
                                                <h3 className="text-center hometextnight">
                                                  {text_tickets}
                                                </h3>
                                                <AppBar
                                                  position="static"
                                                  className="tab_bar  tabBG profile-appbar"
                                                  id="mobBoxtab"
                                                >
                                                  <Tabs
                                                    value={selectedTab}
                                                    onChange={handleChange}
                                                  >
                                                    <Tab
                                                      label={text_transfer}
                                                    />
                                                    <Tab label={text_resell} />
                                                  </Tabs>
                                                </AppBar>
                                                {selectedTab === 0 && (
                                                  <>
                                                    {selectedTicketData.map(
                                                      (Tdata) => {
                                                        return (
                                                          <>
                                                            {Tdata
                                                              ?.eventsdetails[0]
                                                              .TransferPercentage ===
                                                            null ? (
                                                              <>
                                                                <h3 className="text-center mt-3 hometextnight">
                                                                  <>
                                                                    {
                                                                      text_transferdisable_event
                                                                    }
                                                                  </>
                                                                </h3>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <div className="row mt-4 p-2">
                                                                  <h4 className="text-center pb-2 hometextnight">
                                                                    <b className="">
                                                                      {" "}
                                                                      {
                                                                        text_seat
                                                                      }{" "}
                                                                      :{" "}
                                                                    </b>
                                                                    {
                                                                      Tdata?.seat_name
                                                                    }
                                                                  </h4>
                                                                  <div className="col-md-6">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_mobNo
                                                                      }
                                                                      /
                                                                      {
                                                                        text_emailId
                                                                      }
                                                                    </h4>
                                                                    <input
                                                                      type="text"
                                                                      className="form-control h_50 landinginputnight"
                                                                      placeholder={
                                                                        text_ph_mobileEmail
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => [
                                                                        setTransferMobileOrEmail(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        ),
                                                                        setEmailErr(
                                                                          ""
                                                                        ),
                                                                      ]}
                                                                    />
                                                                    <p className="validation-err">
                                                                      {emailErr}
                                                                    </p>
                                                                  </div>
                                                                  <div className="col-md-6">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_name
                                                                      }
                                                                    </h4>
                                                                    <input
                                                                      type="text"
                                                                      className="form-control h_50 landinginputnight"
                                                                      placeholder={
                                                                        text_ph_name
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        setTransferName(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </div>
                                                                <div className="row mt-4 p-2">
                                                                  <h4 className="hometextnight">
                                                                    {
                                                                      text_ticketPrice
                                                                    }{" "}
                                                                    :{" "}
                                                                    {
                                                                      Tdata
                                                                        ?.eventsdetails[0]
                                                                        .sell_currency
                                                                    }{" "}
                                                                    {
                                                                      Tdata?.seat_price
                                                                    }
                                                                  </h4>
                                                                </div>
                                                                <div className="d-flex justify-content-center gap-3">
                                                                  <button
                                                                    className="cancelBtn mt-3"
                                                                    onClick={() => [
                                                                      handleCloseSendTicket(),
                                                                      setEmailErr(
                                                                        ""
                                                                      ),
                                                                    ]}
                                                                  >
                                                                    {text_close}
                                                                  </button>
                                                                  <button
                                                                    className="resell-Btn mt-3"
                                                                    onClick={() =>
                                                                      TransferTicketHandle(
                                                                        v?.seat_unique_id
                                                                      )
                                                                    }
                                                                  >
                                                                    {
                                                                      text_transfer
                                                                    }
                                                                  </button>
                                                                </div>
                                                              </>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                )}
                                                {selectedTab === 1 && (
                                                  <>
                                                    {selectedTicketData.map(
                                                      (Tdata) => {
                                                        return (
                                                          <>
                                                            {Tdata
                                                              ?.eventsdetails[0]
                                                              .ResellPercentageTicketPrice ===
                                                              null &&
                                                            Tdata
                                                              ?.eventsdetails[0]
                                                              .ResellPercentageTicketProfit ===
                                                              null &&
                                                            Tdata
                                                              ?.eventsdetails[0]
                                                              .resellfixedprice ===
                                                              null &&
                                                            Tdata
                                                              ?.eventsdetails[0]
                                                              .resellpriceprofit ===
                                                              null ? (
                                                              <>
                                                                {" "}
                                                                <h3 className="text-center mt-3 hometextnight">
                                                                  {" "}
                                                                  <>
                                                                    {
                                                                      text_reselldisable_event
                                                                    }
                                                                  </>
                                                                </h3>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <div className="row mt-4 p-2 mb-3">
                                                                  <h4 className="text-center pb-2 hometextnight">
                                                                    <b>
                                                                      {
                                                                        text_seat
                                                                      }{" "}
                                                                      :{" "}
                                                                    </b>
                                                                    {
                                                                      Tdata?.seat_name
                                                                    }
                                                                  </h4>
                                                                  <div className="col-lg-6 col-md-12">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_resell_price
                                                                      }
                                                                    </h4>
                                                                    <div className="d-flex">
                                                                      <input
                                                                        type="text"
                                                                        className="form-control h_50 currencyresellInput landinginputnight"
                                                                        disabled
                                                                        value={
                                                                          Tdata
                                                                            ?.eventsdetails[0]
                                                                            .sell_currency
                                                                        }
                                                                      />
                                                                      <input
                                                                        type="text"
                                                                        className="form-control h_50 priceresellInput landinginputnight"
                                                                        placeholder={
                                                                          text_ph_resell_price
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) => [
                                                                          setResellPrice(
                                                                            e
                                                                              .target
                                                                              .value
                                                                          ),
                                                                          setResellPriceErr(
                                                                            ""
                                                                          ),
                                                                        ]}
                                                                      />
                                                                    </div>
                                                                    <p className="validation-err">
                                                                      {
                                                                        resellPriceErr
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                  <div className="col-lg-6 col-md-12 mt-2">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_orignal_price
                                                                      }{" "}
                                                                      :{" "}
                                                                      {
                                                                        Tdata
                                                                          ?.eventsdetails[0]
                                                                          .sell_currency
                                                                      }{" "}
                                                                      {
                                                                        Tdata?.seat_price
                                                                      }
                                                                    </h4>
                                                                    <h4 className="mt-2 hometextnight">
                                                                      {v
                                                                        ?.eventsdetails[0]
                                                                        .ResellPercentageTicketPrice !==
                                                                      null ? (
                                                                        <>
                                                                          {
                                                                            text_commission_ticket
                                                                          }{" "}
                                                                          :{" "}
                                                                          {
                                                                            v
                                                                              ?.eventsdetails[0]
                                                                              .ResellPercentageTicketPrice
                                                                          }
                                                                          %
                                                                        </>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                      {v
                                                                        ?.eventsdetails[0]
                                                                        .ResellPercentageTicketProfit !==
                                                                      null ? (
                                                                        <>
                                                                          {
                                                                            text_commission_profit
                                                                          }
                                                                          :
                                                                          {
                                                                            v
                                                                              ?.eventsdetails[0]
                                                                              .ResellPercentageTicketProfit
                                                                          }
                                                                        </>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                      {v
                                                                        ?.eventsdetails[0]
                                                                        .resellfixedprice !==
                                                                      null ? (
                                                                        <>
                                                                          {
                                                                            text_fixcomm
                                                                          }{" "}
                                                                          :{" "}
                                                                          {
                                                                            v
                                                                              ?.eventsdetails[0]
                                                                              .resellfixedprice
                                                                          }
                                                                        </>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                      {v
                                                                        ?.eventsdetails[0]
                                                                        .resellpriceprofit !==
                                                                      null ? (
                                                                        <>
                                                                          {
                                                                            text_profiton
                                                                          }
                                                                          :
                                                                          {
                                                                            v
                                                                              ?.eventsdetails[0]
                                                                              .resellpriceprofit
                                                                          }
                                                                        </>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </h4>
                                                                  </div>
                                                                </div>
                                                                <div className="row mt-4 p-2">
                                                                  <h4 className="hometextnight">
                                                                    {
                                                                      text_resell_validTill
                                                                    }
                                                                  </h4>
                                                                  <br />
                                                                  <br />
                                                                  <div className="col-md-4 mt-3">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_expDate
                                                                      }
                                                                    </h4>
                                                                    <DatePicker
                                                                      selected={
                                                                        resellExpDate
                                                                      }
                                                                      className="mt-1 form-control h_50 landinginputnight"
                                                                      dateFormat="d MMM, yyyy"
                                                                      onChange={(
                                                                        date
                                                                      ) => [
                                                                        setResellExpDate(
                                                                          date
                                                                        ),
                                                                      ]}
                                                                    />{" "}
                                                                  </div>
                                                                  <div className="col-md-4 mt-3">
                                                                    {" "}
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_expTime
                                                                      }
                                                                    </h4>
                                                                    <DatePicker
                                                                      selected={
                                                                        resellExpTime
                                                                      }
                                                                      className="mt-1 form-control h_50 landinginputnight"
                                                                      showTimeSelect
                                                                      showTimeSelectOnly
                                                                      timeIntervals={
                                                                        15
                                                                      }
                                                                      timeCaption="Time"
                                                                      dateFormat=" hh:mm aa"
                                                                      onChange={(
                                                                        date
                                                                      ) => [
                                                                        setResellExpTime(
                                                                          date
                                                                        ),
                                                                      ]}
                                                                    />{" "}
                                                                  </div>
                                                                  <div className="col-md-4 mt-3">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_timeZone
                                                                      }
                                                                    </h4>
                                                                    <select
                                                                      onChange={(
                                                                        e
                                                                      ) => [
                                                                        setSelectedTimeZone(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        ),
                                                                        setTimeZoneErr(
                                                                          ""
                                                                        ),
                                                                      ]}
                                                                      className="mt-1 form-control h_50 timeZoneInput landinginputnight"
                                                                    >
                                                                      <option
                                                                        selected
                                                                        disabled
                                                                      >
                                                                        {
                                                                          text_sel_TimeZone
                                                                        }
                                                                      </option>
                                                                      {FinalData?.map(
                                                                        (
                                                                          val,
                                                                          i
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <option
                                                                                value={
                                                                                  val?.utc
                                                                                }
                                                                              >
                                                                                {
                                                                                  val?.label
                                                                                }{" "}
                                                                              </option>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                    </select>
                                                                    <p className="validation-err">
                                                                      {
                                                                        timeZoneErr
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </div>

                                                                <div className="d-flex justify-content-center gap-3">
                                                                  <button
                                                                    className="cancelBtn mt-3"
                                                                    onClick={() => [
                                                                      handleCloseSendTicket(),
                                                                      setTimeZoneErr(
                                                                        ""
                                                                      ),
                                                                      setResellPriceErr(
                                                                        ""
                                                                      ),
                                                                    ]}
                                                                  >
                                                                    {text_close}
                                                                  </button>
                                                                  <button
                                                                    className="resell-Btn mt-3"
                                                                    onClick={() => [
                                                                      ResellTicketHandle(
                                                                        Tdata?.seat_unique_id,
                                                                        Tdata?.event_unique_id
                                                                      ),
                                                                    ]}
                                                                  >
                                                                    {
                                                                      text_resell
                                                                    }
                                                                  </button>
                                                                </div>
                                                              </>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                )}
                                              </Box>
                                            </Modal>
                                          </>
                                        );
                                      })}
                                    {hasMoreEvents === true ? (
                                      <>
                                        <div className="browse-btn">
                                          <button
                                            onClick={() =>
                                              getMorePurchaseCard(
                                                nextToken,
                                                AttenendingEventData
                                              )
                                            }
                                            className="main-btn btn-hover "
                                          >
                                            {text_BrowseMore}
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            <div
                              className="tab-pane fade"
                              id="virtualattending"
                              role="tabpanel"
                            >
                              {virtualAttendingEvents === "" ? (
                                <>
                                  <div className="main-card mt-4">
                                    <h3 className="text-center font-16 mt-3 fw-bold">
                                      {text_noeventfound}
                                    </h3>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row scroll-attend-event">
                                    {virtualAttenendingEventData.length > 0 &&
                                      virtualAttenendingEventData.map((v) => {
                                        return (
                                          <>
                                            <div className="col-md-12">
                                              <div className="main-card mt-4">
                                                <div className="card-top p-4">
                                                  <div className="card-event-img">
                                                    <img
                                                      src={
                                                        v?.EventImagePath ===
                                                          "path" ||
                                                        v?.EventImagePath === ""
                                                          ? img1
                                                          : v?.EventImagePath
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="card-event-dt">
                                                    <h5>{v?.EventTitle}</h5>

                                                    {v?.ownername === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        <div className="evnt-time d-flex">
                                                          <p className="owner-title-sty">
                                                            {text_owner_Name}:
                                                          </p>{" "}
                                                          <p className="mx-2 owner-name">
                                                            {" "}
                                                            {v?.ownername}
                                                          </p>
                                                        </div>
                                                      </>
                                                    )}

                                                    <div className="mx-1 owner-name mt-1">
                                                      {moment
                                                        .utc(v?.StartDate)
                                                        .format(
                                                          "ddd, MMM DD, yyyy"
                                                        )}{" "}
                                                      {moment
                                                        .utc(v?.StartTime)
                                                        .format("h:mmA")}{" "}
                                                      -{" "}
                                                      {moment
                                                        .utc(v?.EndDate)
                                                        .format(
                                                          "ddd, MMM DD, yyyy"
                                                        )}{" "}
                                                      {moment
                                                        .utc(v?.EndTime)
                                                        .format("h:mmA")}
                                                    </div>
                                                    <div className="d-flex">
                                                      {" "}
                                                      <div className="event-btn-group">
                                                        <button
                                                          className="esv-btn me-2"
                                                          onClick={() => [
                                                           navigate(
                                                              "/view-virtual-invoice"
                                                            ),
                                                            sessionStorage.setItem(
                                                              "ticketDataProfile",
                                                              JSON.stringify(v)
                                                            ),
                                                            sessionStorage.setItem(
                                                              "EveDetail",
                                                              null
                                                            ),
                                                            sessionStorage.setItem(
                                                              "ownerDetails",
                                                              null
                                                            ),
                                                            sessionStorage.setItem(
                                                              "SeatsDetails",
                                                              null
                                                            ),
                                                          ]}
                                                        >
                                                          <GrUpload />
                                                          {text_viewtickets}
                                                        </button>
                                                      </div>
                                                      {/* <div className="event-btn-group">
                                                        <button
                                                          className="transfer-btn  me-2"
                                                          onClick={() =>
                                                            handlesendpopup(v)
                                                          }
                                                        >
                                                          <BiTransferAlt />{" "}
                                                          {text_teansferresell}
                                                        </button>
                                                      </div> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <Modal
                                              open={openSendTicket}
                                              onClose={() => [
                                                handleCloseSendTicket(),
                                                setEmailErr(""),
                                                setTimeZoneErr(""),
                                                setResellPriceErr(""),
                                              ]}
                                              aria-labelledby="modal-modal-title"
                                              aria-describedby="modal-modal-description"
                                              className="resell-Modal"
                                            >
                                              <Box
                                                sx={styleTicket}
                                                className="responsivelogin"
                                                id="header-registermodal"
                                              >
                                                <div className="d-flex justify-content-end">
                                                  <IoIosCloseCircleOutline
                                                    className="closeIcon-venue hometextnight"
                                                    onClick={() => [
                                                      handleCloseSendTicket(),
                                                      setEmailErr(""),
                                                      setTimeZoneErr(""),
                                                      setResellPriceErr(""),
                                                    ]}
                                                  />
                                                </div>
                                                <h3 className="text-center hometextnight">
                                                  {text_tickets}
                                                </h3>
                                                <AppBar
                                                  position="static"
                                                  className="tab_bar  tabBG profile-appbar"
                                                  id="mobBoxtab"
                                                >
                                                  <Tabs
                                                    value={selectedTab}
                                                    onChange={handleChange}
                                                  >
                                                    <Tab
                                                      label={text_transfer}
                                                    />
                                                    <Tab label={text_resell} />
                                                  </Tabs>
                                                </AppBar>
                                                {selectedTab === 0 && (
                                                  <>
                                                    {selectedTicketData.map(
                                                      (Tdata) => {
                                                        return (
                                                          <>
                                                            {Tdata
                                                              ?.eventsdetails[0]
                                                              .TransferPercentage ===
                                                            null ? (
                                                              <>
                                                                <h3 className="text-center mt-3 hometextnight">
                                                                  <>
                                                                    {
                                                                      text_transferdisable_event
                                                                    }
                                                                  </>
                                                                </h3>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <div className="row mt-4 p-2">
                                                                  <h4 className="text-center pb-2 hometextnight">
                                                                    <b className="">
                                                                      {" "}
                                                                      {
                                                                        text_seat
                                                                      }{" "}
                                                                      :{" "}
                                                                    </b>
                                                                    {
                                                                      Tdata?.seat_name
                                                                    }
                                                                  </h4>
                                                                  <div className="col-md-6">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_mobNo
                                                                      }
                                                                      /
                                                                      {
                                                                        text_emailId
                                                                      }
                                                                    </h4>
                                                                    <input
                                                                      type="text"
                                                                      className="form-control h_50 landinginputnight"
                                                                      placeholder={
                                                                        text_ph_mobileEmail
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => [
                                                                        setTransferMobileOrEmail(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        ),
                                                                        setEmailErr(
                                                                          ""
                                                                        ),
                                                                      ]}
                                                                    />
                                                                    <p className="validation-err">
                                                                      {emailErr}
                                                                    </p>
                                                                  </div>
                                                                  <div className="col-md-6">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_name
                                                                      }
                                                                    </h4>
                                                                    <input
                                                                      type="text"
                                                                      className="form-control h_50 landinginputnight"
                                                                      placeholder={
                                                                        text_ph_name
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        setTransferName(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </div>
                                                                <div className="row mt-4 p-2">
                                                                  <h4 className="hometextnight">
                                                                    {
                                                                      text_ticketPrice
                                                                    }{" "}
                                                                    :{" "}
                                                                    {
                                                                      Tdata
                                                                        ?.eventsdetails[0]
                                                                        .sell_currency
                                                                    }{" "}
                                                                    {
                                                                      Tdata?.seat_price
                                                                    }
                                                                  </h4>
                                                                </div>
                                                                <div className="d-flex justify-content-center gap-3">
                                                                  <button
                                                                    className="cancelBtn mt-3"
                                                                    onClick={() => [
                                                                      handleCloseSendTicket(),
                                                                      setEmailErr(
                                                                        ""
                                                                      ),
                                                                    ]}
                                                                  >
                                                                    {text_close}
                                                                  </button>
                                                                  <button
                                                                    className="resell-Btn mt-3"
                                                                    onClick={() =>
                                                                      TransferTicketHandle(
                                                                        v?.seat_unique_id
                                                                      )
                                                                    }
                                                                  >
                                                                    {
                                                                      text_transfer
                                                                    }
                                                                  </button>
                                                                </div>
                                                              </>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                )}
                                                {selectedTab === 1 && (
                                                  <>
                                                    {selectedTicketData.map(
                                                      (Tdata) => {
                                                        return (
                                                          <>
                                                            {Tdata
                                                              ?.eventsdetails[0]
                                                              .ResellPercentageTicketPrice ===
                                                              null &&
                                                            Tdata
                                                              ?.eventsdetails[0]
                                                              .ResellPercentageTicketProfit ===
                                                              null &&
                                                            Tdata
                                                              ?.eventsdetails[0]
                                                              .resellfixedprice ===
                                                              null &&
                                                            Tdata
                                                              ?.eventsdetails[0]
                                                              .resellpriceprofit ===
                                                              null ? (
                                                              <>
                                                                {" "}
                                                                <h3 className="text-center mt-3 hometextnight">
                                                                  {" "}
                                                                  <>
                                                                    {
                                                                      text_reselldisable_event
                                                                    }
                                                                  </>
                                                                </h3>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <div className="row mt-4 p-2 mb-3">
                                                                  <h4 className="text-center pb-2 hometextnight">
                                                                    <b>
                                                                      {
                                                                        text_seat
                                                                      }{" "}
                                                                      :{" "}
                                                                    </b>
                                                                    {
                                                                      Tdata?.seat_name
                                                                    }
                                                                  </h4>
                                                                  <div className="col-lg-6 col-md-12">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_resell_price
                                                                      }
                                                                    </h4>
                                                                    <div className="d-flex">
                                                                      <input
                                                                        type="text"
                                                                        className="form-control h_50 currencyresellInput landinginputnight"
                                                                        disabled
                                                                        value={
                                                                          Tdata
                                                                            ?.eventsdetails[0]
                                                                            .sell_currency
                                                                        }
                                                                      />
                                                                      <input
                                                                        type="text"
                                                                        className="form-control h_50 priceresellInput landinginputnight"
                                                                        placeholder={
                                                                          text_ph_resell_price
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) => [
                                                                          setResellPrice(
                                                                            e
                                                                              .target
                                                                              .value
                                                                          ),
                                                                          setResellPriceErr(
                                                                            ""
                                                                          ),
                                                                        ]}
                                                                      />
                                                                    </div>
                                                                    <p className="validation-err">
                                                                      {
                                                                        resellPriceErr
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                  <div className="col-lg-6 col-md-12 mt-2">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_orignal_price
                                                                      }{" "}
                                                                      :{" "}
                                                                      {
                                                                        Tdata
                                                                          ?.eventsdetails[0]
                                                                          .sell_currency
                                                                      }{" "}
                                                                      {
                                                                        Tdata?.seat_price
                                                                      }
                                                                    </h4>
                                                                    <h4 className="mt-2 hometextnight">
                                                                      {v
                                                                        ?.eventsdetails[0]
                                                                        .ResellPercentageTicketPrice !==
                                                                      null ? (
                                                                        <>
                                                                          {
                                                                            text_commission_ticket
                                                                          }{" "}
                                                                          :{" "}
                                                                          {
                                                                            v
                                                                              ?.eventsdetails[0]
                                                                              .ResellPercentageTicketPrice
                                                                          }
                                                                          %
                                                                        </>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                      {v
                                                                        ?.eventsdetails[0]
                                                                        .ResellPercentageTicketProfit !==
                                                                      null ? (
                                                                        <>
                                                                          {
                                                                            text_commission_profit
                                                                          }
                                                                          :
                                                                          {
                                                                            v
                                                                              ?.eventsdetails[0]
                                                                              .ResellPercentageTicketProfit
                                                                          }
                                                                        </>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                      {v
                                                                        ?.eventsdetails[0]
                                                                        .resellfixedprice !==
                                                                      null ? (
                                                                        <>
                                                                          {
                                                                            text_fixcomm
                                                                          }{" "}
                                                                          :{" "}
                                                                          {
                                                                            v
                                                                              ?.eventsdetails[0]
                                                                              .resellfixedprice
                                                                          }
                                                                        </>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                      {v
                                                                        ?.eventsdetails[0]
                                                                        .resellpriceprofit !==
                                                                      null ? (
                                                                        <>
                                                                          {
                                                                            text_profiton
                                                                          }
                                                                          :
                                                                          {
                                                                            v
                                                                              ?.eventsdetails[0]
                                                                              .resellpriceprofit
                                                                          }
                                                                        </>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </h4>
                                                                  </div>
                                                                </div>
                                                                <div className="row mt-4 p-2">
                                                                  <h4 className="hometextnight">
                                                                    {
                                                                      text_resell_validTill
                                                                    }
                                                                  </h4>
                                                                  <br />
                                                                  <br />
                                                                  <div className="col-md-4 mt-3">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_expDate
                                                                      }
                                                                    </h4>
                                                                    <DatePicker
                                                                      selected={
                                                                        resellExpDate
                                                                      }
                                                                      className="mt-1 form-control h_50 landinginputnight"
                                                                      dateFormat="d MMM, yyyy"
                                                                      onChange={(
                                                                        date
                                                                      ) => [
                                                                        setResellExpDate(
                                                                          date
                                                                        ),
                                                                      ]}
                                                                    />{" "}
                                                                  </div>
                                                                  <div className="col-md-4 mt-3">
                                                                    {" "}
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_expTime
                                                                      }
                                                                    </h4>
                                                                    <DatePicker
                                                                      selected={
                                                                        resellExpTime
                                                                      }
                                                                      className="mt-1 form-control h_50 landinginputnight"
                                                                      showTimeSelect
                                                                      showTimeSelectOnly
                                                                      timeIntervals={
                                                                        15
                                                                      }
                                                                      timeCaption="Time"
                                                                      dateFormat=" hh:mm aa"
                                                                      onChange={(
                                                                        date
                                                                      ) => [
                                                                        setResellExpTime(
                                                                          date
                                                                        ),
                                                                      ]}
                                                                    />{" "}
                                                                  </div>
                                                                  <div className="col-md-4 mt-3">
                                                                    <h4 className="hometextnight">
                                                                      {
                                                                        text_timeZone
                                                                      }
                                                                    </h4>
                                                                    <select
                                                                      onChange={(
                                                                        e
                                                                      ) => [
                                                                        setSelectedTimeZone(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        ),
                                                                        setTimeZoneErr(
                                                                          ""
                                                                        ),
                                                                      ]}
                                                                      className="mt-1 form-control h_50 timeZoneInput landinginputnight"
                                                                    >
                                                                      <option
                                                                        selected
                                                                        disabled
                                                                      >
                                                                        {
                                                                          text_sel_TimeZone
                                                                        }
                                                                      </option>
                                                                      {FinalData?.map(
                                                                        (
                                                                          val,
                                                                          i
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <option
                                                                                value={
                                                                                  val?.utc
                                                                                }
                                                                              >
                                                                                {
                                                                                  val?.label
                                                                                }{" "}
                                                                              </option>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                    </select>
                                                                    <p className="validation-err">
                                                                      {
                                                                        timeZoneErr
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </div>

                                                                <div className="d-flex justify-content-center gap-3">
                                                                  <button
                                                                    className="cancelBtn mt-3"
                                                                    onClick={() => [
                                                                      handleCloseSendTicket(),
                                                                      setTimeZoneErr(
                                                                        ""
                                                                      ),
                                                                      setResellPriceErr(
                                                                        ""
                                                                      ),
                                                                    ]}
                                                                  >
                                                                    {text_close}
                                                                  </button>
                                                                  <button
                                                                    className="resell-Btn mt-3"
                                                                    onClick={() => [
                                                                      ResellTicketHandle(
                                                                        Tdata?.seat_unique_id,
                                                                        Tdata?.event_unique_id
                                                                      ),
                                                                    ]}
                                                                  >
                                                                    {
                                                                      text_resell
                                                                    }
                                                                  </button>
                                                                </div>
                                                              </>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                )}
                                              </Box>
                                            </Modal>
                                          </>
                                        );
                                      })}
                                    {hasMoreEvents === true ? (
                                      <>
                                        <div className="browse-btn">
                                          <button
                                            onClick={() =>
                                              getMorePurchaseCard(
                                                nextToken,
                                                AttenendingEventData
                                              )
                                            }
                                            className="main-btn btn-hover "
                                          >
                                            {text_BrowseMore}
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            <div
                              className="tab-pane fade"
                              id="attended"
                              role="tabpanel"
                            >
                              {totalAttendedTicket === "" ? (
                                <>
                                  <div className="main-card mt-4">
                                    <h3 className="text-center font-16 mt-3 fw-bold">
                                      {text_noeventfound}
                                    </h3>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row scroll-attend-event">
                                    {AttenendedEventData.length > 0 &&
                                      AttenendedEventData.map((v) => {
                                        return (
                                          <>
                                            <div className="col-md-12">
                                              <div className="main-card mt-4">
                                                <div className="card-top p-4">
                                                  <div className="card-event-img">
                                                    <img
                                                      src={
                                                        v?.eventsdetails[0]
                                                          ?.EventImagePath ===
                                                        "path"
                                                          ? img1
                                                          : v?.eventsdetails[0]
                                                              ?.EventImagePath
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="card-event-dt">
                                                    <h5>
                                                      {
                                                        v?.eventsdetails[0]
                                                          ?.EventTitle
                                                      }
                                                    </h5>
                                                    {v?.seat_name === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        <div className="evnt-time d-flex">
                                                          <p className="owner-title-sty">
                                                            {text_seat}:
                                                          </p>{" "}
                                                          <p className="mx-2 owner-name">
                                                            {" "}
                                                            {v?.seat_name}
                                                          </p>
                                                        </div>
                                                      </>
                                                    )}
                                                    {v?.ownername === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        <div className="evnt-time d-flex">
                                                          <p className="owner-title-sty">
                                                            {text_owner_Name}:
                                                          </p>{" "}
                                                          <p className="mx-2 owner-name">
                                                            {" "}
                                                            {v?.ownername}
                                                          </p>
                                                        </div>
                                                      </>
                                                    )}
                                                    <div className="d-flex">
                                                      {v.venuedetails ===
                                                        undefined ||
                                                      v.venuedetails === null ||
                                                      v.venuedetails.length ===
                                                        0 ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          {v?.eventsdetails[0]
                                                            .venuedetails[0]
                                                            .venuename === "" ||
                                                          v?.eventsdetails[0]
                                                            .venuedetails[0]
                                                            .venuename ===
                                                            undefined ? (
                                                            ""
                                                          ) : (
                                                            <>
                                                              {" "}
                                                              <p className="owner-title-sty">
                                                                {text_events}:{" "}
                                                              </p>
                                                              <span className="mx-2 owner-name">
                                                                {v
                                                                  ?.eventsdetails[0]
                                                                  .venuedetails[0]
                                                                  .venuename ===
                                                                  "" ||
                                                                v
                                                                  ?.eventsdetails[0]
                                                                  .venuedetails[0]
                                                                  .venuename ===
                                                                  undefined ? (
                                                                  ""
                                                                ) : (
                                                                  <>
                                                                    {
                                                                      v
                                                                        ?.eventsdetails[0]
                                                                        .venuedetails[0]
                                                                        .venuename
                                                                    }{" "}
                                                                    ~{" "}
                                                                    {
                                                                      v
                                                                        ?.eventsdetails[0]
                                                                        .venuedetails[0]
                                                                        .address
                                                                    }
                                                                    {", "}
                                                                    {
                                                                      v
                                                                        ?.eventsdetails[0]
                                                                        .venuedetails[0]
                                                                        .city
                                                                    }
                                                                    {", "}
                                                                    <br />{" "}
                                                                    {
                                                                      v
                                                                        ?.eventsdetails[0]
                                                                        .venuedetails[0]
                                                                        .state
                                                                    }
                                                                    {", "}
                                                                    {
                                                                      v
                                                                        ?.eventsdetails[0]
                                                                        .venuedetails[0]
                                                                        .country
                                                                    }
                                                                    {", "}
                                                                    {
                                                                      v
                                                                        ?.eventsdetails[0]
                                                                        .venuedetails[0]
                                                                        .zipcode
                                                                    }
                                                                  </>
                                                                )}
                                                              </span>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                    <div className="mx-1 owner-name mt-1">
                                                      {moment
                                                        .utc(
                                                          v?.eventsdetails[0]
                                                            ?.StartDate
                                                        )
                                                        .format(
                                                          "ddd, MMM DD, yyyy"
                                                        )}{" "}
                                                      {moment
                                                        .utc(
                                                          v?.eventsdetails[0]
                                                            ?.StartTime
                                                        )
                                                        .format("h:mmA")}{" "}
                                                      -{" "}
                                                      {moment
                                                        .utc(
                                                          v?.eventsdetails[0]
                                                            ?.EndDate
                                                        )
                                                        .format(
                                                          "ddd, MMM DD, yyyy"
                                                        )}{" "}
                                                      {moment
                                                        .utc(
                                                          v?.eventsdetails[0]
                                                            ?.EndTime
                                                        )
                                                        .format("h:mmA")}
                                                    </div>
                                                    <div className="d-flex">
                                                      {" "}
                                                      <div className="event-btn-group">
                                                        <button
                                                          className="esv-btn me-2"
                                                          onClick={() => [
                                                            navigate(
                                                              "/view-ticket-invoice"
                                                            ),
                                                            sessionStorage.setItem(
                                                              "ticketDataProfile",
                                                              JSON.stringify(v)
                                                            ),
                                                            sessionStorage.setItem(
                                                              "EveDetail",
                                                              null
                                                            ),
                                                            sessionStorage.setItem(
                                                              "ownerDetails",
                                                              null
                                                            ),
                                                            sessionStorage.setItem(
                                                              "SeatsDetails",
                                                              null
                                                            ),
                                                          ]}
                                                        >
                                                          <GrUpload />
                                                          {text_viewtickets}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    {hasMoreAttendedEvents === true ? (
                                      <>
                                        <div className="browse-btn">
                                          <button className="main-btn btn-hover ">
                                            {text_BrowseMore}
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="about"
                          role="tabpanel"
                          aria-labelledby="about-tab"
                        >
                          <div className="main-card mt-4">
                            <div className="bp-title position-relative">
                              <h4>{text_about}</h4>
                              <button
                                className="main-btn btn-hover ms-auto edit-btn me-3 pe-4 ps-4 h_40"
                                data-bs-toggle="modal"
                                data-bs-target="#aboutModal"
                                onClick={() => setOpenEdit(true)}
                              >
                                <FaRegEdit /> {text_edit}
                              </button>
                            </div>
                            {AboutDetails.map((v) => {
                              return (
                                <>
                                  <div className="about-details">
                                    <div className="about-step">
                                      <h5>{text_name}</h5>
                                      <span>
                                        {" "}
                                        {UserName + " " + UserLastName}
                                      </span>
                                    </div>
                                    <div className="about-step">
                                      <h5>{text_noticeorgabout}</h5>
                                      <p className="mb-0">{bio}</p>
                                    </div>
                                    <div className="about-step">
                                      <h5>{text_followus}</h5>
                                      <div className="social-links">
                                        <Link
                                          to="#"
                                          className="social-link"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Facebook"
                                        >
                                          <FaFacebookSquare />
                                        </Link>
                                        <Link
                                          to="#"
                                          className="social-link"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Instagram"
                                        >
                                          <FaInstagram />
                                        </Link>
                                        <Link
                                          to="#"
                                          className="social-link"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Twitter"
                                        >
                                          <FaTwitter />
                                        </Link>
                                        <Link
                                          to="#"
                                          className="social-link"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="LinkedIn"
                                        >
                                          <FaLinkedinIn />
                                        </Link>
                                        <Link
                                          to="#"
                                          className="social-link"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Youtube"
                                        >
                                          <FaYoutube />
                                        </Link>
                                        <Link
                                          to="#"
                                          className="social-link"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Website"
                                        >
                                          <FaGlobe />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="about-step">
                                      <h5>{text_address}</h5>
                                      <p className="mb-0">
                                        {address}, {city}, {state}, {zipCode},{" "}
                                        {country}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="setting"
                          role="tabpanel"
                          aria-labelledby="setting-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="main-card mt-4 p-0">
                                <div className="nav custom-tabs" role="tablist">
                                  <button
                                    className="tab-link active tab-link-setting"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tab-01"
                                    type="button"
                                    role="tab"
                                    aria-controls="tab-01"
                                    aria-selected="true"
                                  >
                                    <i className="fa-solid fa-envelope me-3"></i>
                                    {text_emailpre}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="main-card mt-4">
                                <div className="tab-content">
                                  <div
                                    className="tab-pane fade show active"
                                    id="tab-01"
                                    role="tabpanel"
                                  >
                                    <div className="bp-title">
                                      <h4>{text_emailpre}</h4>
                                    </div>
                                    <div className="profile-setting p-4">
                                      {EmailPreferences.map((v) => {
                                        return (
                                          <>
                                            <div className="setting-step pt-4">
                                              <div className="d-flex align-items-center justify-content-between">
                                                <h3 className="setting-title">
                                                  {v.title}
                                                </h3>
                                                <label className="btn-switch m-0 ml-2">
                                                  <input
                                                    type="checkbox"
                                                    checked={v.checked}
                                                  />
                                                  <span className="checkbox-slider"></span>
                                                </label>
                                              </div>
                                              <p className="mt-1 mb-4">
                                                {v.desc}
                                              </p>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id="tab-02"
                                    role="tabpanel"
                                  >
                                    <div className="bp-title">
                                      <h4>{text_passwordsettings}</h4>
                                    </div>
                                    <div className="password-setting p-4">
                                      <div className="password-des">
                                        <h4>{text_Changepassword}</h4>
                                        <p>{text_noticepass}</p>
                                      </div>
                                      <div className="change-password-form">
                                        <div className="form-group profile-night-form mt-4">
                                          <label className="form-label ">
                                            {text_Currentpassword}*
                                          </label>
                                          <div className="loc-group position-relative">
                                            <input
                                              className="form-control h_50"
                                              type="password"
                                              placeholder={text_enterpass}
                                            />
                                            <span className="pass-show-eye">
                                              <i className="fas fa-eye-slash"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="form-group profile-night-form mt-4">
                                          <label className="form-label">
                                            {text_Newpassword}*
                                          </label>
                                          <div className="loc-group position-relative">
                                            <input
                                              className="form-control h_50"
                                              type="password"
                                              placeholder={text_enterpass}
                                            />
                                            <span className="pass-show-eye">
                                              <i className="fas fa-eye-slash"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="form-group profile-night-form mt-4">
                                          <label className="form-label">
                                            {text_confirmnewpass}*
                                          </label>
                                          <div className="loc-group position-relative">
                                            <input
                                              className="form-control h_50"
                                              type="password"
                                              placeholder={text_enterpass}
                                            />
                                            <span className="pass-show-eye">
                                              <i className="fas fa-eye-slash"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <button
                                          className="main-btn btn-hover w-100 mt-5"
                                          type="submit"
                                        >
                                          {text_updatepass}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id="tab-03"
                                    role="tabpanel"
                                  >
                                    <div className="bp-title">
                                      <h4>{text_privacysetting}</h4>
                                    </div>
                                    <div className="privacy-setting p-4">
                                      {PrivacySetting.map((v) => {
                                        return (
                                          <>
                                            <div className="setting-step pt-4">
                                              <div className="d-flex align-items-center justify-content-between">
                                                <h3 className="setting-title">
                                                  {v.title}
                                                </h3>
                                                <label className="btn-switch m-0 ml-2">
                                                  <input type="checkbox" />
                                                  <span className="checkbox-slider"></span>
                                                </label>
                                              </div>
                                              <p className="mt-1 mb-4">
                                                {v.desc}
                                              </p>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="orders"
                          role="tabpanel"
                          aria-labelledby="orders-tab"
                        >
                          {myorderData.length === 0 ? (
                            <>
                              <div className="main-card mt-4">
                                <h3 className="text-center font-16 mt-3 fw-bold">
                                  {text_noeventfound}
                                </h3>
                              </div>
                            </>
                          ) : (
                            <>
                              {myorderData.map((v) => {
                                return (
                                  <>
                                    <div className="main-card mt-4">
                                      <div className="card-top p-4">
                                        <div className="card-event-img  card-event-img-responsive">
                                          <img
                                            src={
                                              v?.eventsdetails[0]
                                                ?.EventImagePath === "path"
                                                ? img1
                                                : v?.eventsdetails[0]
                                                    ?.EventImagePath
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="card-event-dt">
                                          <h5>
                                            {v?.eventsdetails[0]?.EventTitle}
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="card-bottom">
                                        <div className="card-bottom-item">
                                          <div className="card-icon">
                                            <FaCalendarAlt />
                                          </div>
                                          <div className="card-dt-text">
                                            <h6>{text_eventstartson}</h6>
                                            <span>
                                              {" "}
                                              {moment
                                                .utc(
                                                  v?.eventsdetails[0]?.StartDate
                                                )
                                                .format("DD MMMM yyyy")}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="card-bottom-item">
                                          <div className="card-icon">
                                            <FaTicketAlt />
                                          </div>
                                          <div className="card-dt-text">
                                            <h6>{text_total_ticket}</h6>
                                            <span>
                                              {
                                                AttenendingEventData.filter(
                                                  (e) =>
                                                    e.event_unique_id ===
                                                    v.event_unique_id
                                                ).length
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <div className="card-bottom-item">
                                          <div className="card-icon">
                                            <FaMoneyBill />
                                          </div>
                                          <div className="card-dt-text">
                                            <h6>{text_seatamount}</h6>
                                            <span>
                                              {v?.currency}{" "}
                                              {v?.seat_price *
                                                AttenendingEventData.filter(
                                                  (e) =>
                                                    e.event_unique_id ===
                                                    v.event_unique_id
                                                ).length}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="card-bottom-item">
                                          <div className="card-icon">
                                            <FaMoneyBill />
                                          </div>
                                          <div className="card-dt-text">
                                            <h6>{text_invoice}</h6>
                                            <button
                                              onClick={() =>
                                                navigate("/invoice-profile", {
                                                  state: {
                                                    profile_ticket_data:
                                                      v.event_unique_id,
                                                  },
                                                })
                                              }
                                              className="profile-download download-btn-profile"
                                            >
                                              {text_download}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openProfileImagePopup}
        onClose={handleCloseProfileImagePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue hometextnight"
                onClick={() => [handleCloseProfileImagePopup()]}
              />
            </div>
            <h3 className="hometextnight">{text_upload_image}</h3>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control h_50 uploadinput landinginputnight"
                      onChange={imguplpoadValidation}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseProfileImagePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button
                    onClick={handleProfileImgUpload}
                    className="resell-Btn"
                  >
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <Modal
        open={openCoverImagePopup}
        onClose={handleCloseCoverImagePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue hometextnight"
                onClick={() => [handleCloseCoverImagePopup()]}
              />
            </div>
            <h3 className="hometextnight">{text_upload_image}</h3>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control h_50 uploadinput landinginputnight"
                      onChange={coverimguplpoadValidation}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseCoverImagePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button
                    onClick={handleProfileCoverImgUpload}
                    className="resell-Btn"
                  >
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={openTeam}
        onClose={handleCloseTeam}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue hometextnight"
                onClick={() => [handleCloseTeam()]}
              />
            </div>
            <h3 className="hometextnight">{text_viewteaminvites}</h3>
            <Divider />
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {inviteTeamList.length === 0 ? (
                  <>
                    <h3 className="text-center mt-3 p-2 hometextnight">
                      {text_noinvitesteam}
                    </h3>
                  </>
                ) : (
                  <>
                    {" "}
                    {inviteTeamList.map((v) => {
                      return (
                        <>
                          <div className="form-group d-flex justify-content-between mt-4 p-2">
                            <label className="form-label fw-bold mt-2 hometextnight">
                              {v.teamname}
                            </label>
                            <div className="d-flex gap-2">
                              <button
                                className="DeactiveBtn"
                                onClick={() => handleRejectOrg(v.invit_id)}
                              >
                                {text_reject}
                              </button>
                              <button
                                className="ActiveBtn"
                                onClick={() => handleActiveOrg(v.invit_id)}
                              >
                                {text_accept}
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <br />
            <Divider />
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button onClick={() => handleCloseTeam()} className="cancelBtn">
                {" "}
                {text_close}
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Footer />
      <ToastContainer />
    </>
  );
}

export default Profile;
