import html2pdf from "html2pdf.js";
import { API_DOMAIN } from "../../utils/GlobalConst";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

export async function generateAndSendPDF(newArray) {
  try {
    // Create the HTML content dynamically
    // 1️⃣ Create the HTML content dynamically
    let container = document.createElement("div");
    container.id = "pdf-content";
    container.style.position = "absolute";
    container.style.left = "-9999px"; // Hide from view
    container.style.padding = "20px";
    container.style.background = "white";
    container.style.fontFamily = "Arial";
    container.style.width = "210mm"; // A4 width
    // 2️⃣ Add your HTML content
    container.innerHTML = `
            <div style="text-align:center;">
                <h2>Payment Receipt</h2>
                <p>Amount: <strong>$100</strong></p>
                <p>Transaction ID: <strong>1234567890</strong></p>
                <p>Event Name: <strong>Some Event</strong></p>
                <p>Match Date: <strong>${moment().format(
                  "YYYY-MM-DD"
                )}</strong></p>
                <p>Match Time: <strong>${moment().format(
                  "hh:mm A"
                )}</strong></p>
                <p>Thank you for your payment!</p>
            </div>
        `;

    // Append the element to body temporarily
        document.body.appendChild(container);

    // 2️⃣ Convert to PDF Blob
 const pdfBlob = await new Promise((resolve, reject) => {
   html2pdf()
     .from(container)
     .set({
       margin: [10, 10, 10, 10], // Top, Right, Bottom, Left
       filename: "receipt.pdf",
       image: { type: "jpeg", quality: 0.98 },
       html2canvas: { scale: 2, useCORS: true, logging: true }, // Ensure good resolution
       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
     })
     .toPdf()
     .get("pdf")
     .then((pdf) => resolve(pdf.output("blob")))
     .catch(reject);
 });
    // Remove element after conversion
        document.body.removeChild(container);

    // Convert Blob to File
            const pdfFile = new File([pdfBlob], "receipt.pdf", {
              type: "application/pdf",
            });

    // Send PDF via API
    await sendPDF(pdfFile, newArray);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
}

// Convert HTML to PDF Blob
async function htmlToPdfBlob(element) {
  return new Promise((resolve, reject) => {
    html2pdf()
      .from(element)
      .set({
        margin: 10,
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true, logging: false },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      })
      .toPdf()
      .get("pdf")
      .then((pdf) => resolve(pdf.output("blob")))
      .catch(reject);
  });
}

// Upload PDF to API
async function sendPDF(pdfFile, newArray) {
  try {
    const API_URL = API_DOMAIN + "user/sendticket";
    const emailList = JSON.stringify(newArray);
    const data = new FormData();
    const EveDetail = JSON.parse(sessionStorage.getItem("EveDetail")) || [];

    if (!EveDetail.length) {
      console.error("EveDetail is missing or empty.");
      return;
    }

    console.log("Sending Data:", newArray, emailList, EveDetail);

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    // Ensure pdfFile is a valid File object
    // if (!(pdfFile instanceof File)) {
    //   pdfFile = new File([pdfFile], "ticket.pdf", { type: "application/pdf" });
    // }

    data.append("att_file", pdfFile);
    data.append("userdetails", emailList);
    data.append(
      "eventname",
      JSON.stringify(EveDetail[0]?.EventTitle || "Unknown Event")
    );
    data.append(
      "match_date",
      JSON.stringify(moment.utc(EveDetail[0]?.StartDate).format("YYYY-MM-DD"))
    );
    data.append(
      "match_time",
      JSON.stringify(moment.utc(EveDetail[0]?.StartTime).format("hh:mm A"))
    );

    const response = await axios.post(API_URL, data, headerConfig);
    console.log("PDF Sent Successfully:", response.data);
  } catch (error) {
    if (error.response?.status === 427) {
      sessionStorage.clear();
      localStorage.clear();
      setTimeout(() => {
        // navigate("/");
      }, 1000);
    } else {
      console.error(
        "Error Sending PDF:",
        error.response?.data?.message || error
      );
      toast.error(error.response?.data?.message || "An error occurred");
    }
  }
}