import React, { useEffect, useState } from "react";
import { API_DOMAIN } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { IoLocationOutline } from "react-icons/io5";
import { BsDot } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function WatchEvent() {
  const navigate = useNavigate();
  const { eventID } = useParams();
  const [Data, setData] = useState([]);
  const ticketData = JSON.parse(sessionStorage.getItem("ticketDataProfile"));
  const EveDetail = JSON.parse(sessionStorage.getItem("EveDetail"));
  
  const selectedVirtualCategory = JSON.parse(sessionStorage.getItem("selectedVirtualCategory"));

  useEffect(() => {
    var type = "select";
    var event_unique_id = "89642cbd-fdb8-47df-9538-553bb8106a87";
    var API_URL =
      API_DOMAIN +
      "event/public?type=" +
      type +
      "&event_unique_id=" +
      event_unique_id;

    axios
      .get(API_URL)
      .then(function (response) {
        console.log("Detaied Event", response.data.data);
        var res = response.data.data;
        setData(res);
        // setTableData(res[0].TicketDetailsList);
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  }, [eventID]);
  return (
    <>
      <Header />
      <div className="eventdetail-wrapper eventdetail-nightmod" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      ></li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="barren-breadcrumb">
                  <div className="league-page-search">
                    <input
                      id="outlined-basic"
                      variant="outlined"
                      className="league-page-search-input"
                      fullWidth
                      placeholder="Search"
                      label="Search"
                    />
                    <div className="league-page-search-icon">
                      <FaSearch />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            {/* {Data.map((v) => {
              return (
                <> */}
            {sessionStorage.getItem("EveDetail") === "" ||
            sessionStorage.getItem("EveDetail") === null ||
            sessionStorage.getItem("EveDetail") === "null" ||

            sessionStorage.getItem("EveDetail") === undefined ? (
              <>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="event-top-dts">
                      <div className="calendar_box">
                        <div className="calendar">
                          <div className="month">
                            {moment.utc(ticketData?.StartDate).format("MMM")}
                          </div>
                          <div className="day">
                            {moment.utc(ticketData?.StartDate).format("DD")}
                          </div>
                        </div>
                      </div>
                      <div className="event-top-dt">
                        <h3 className="event-main-title">
                          {ticketData?.EventTitle}
                        </h3>

                        <div className="event-top-info-status">
                          <div className="publish-date mb-2">
                            <span>
                              {moment
                                .utc(ticketData?.StartDate)
                                .format("DD MMM, yyyy")}
                              &nbsp;
                            </span>
                            <span className="dot dot-size-allevent">
                              <BsDot
                                style={{
                                  width: "23px",
                                  height: "23px",
                                }}
                              />
                            </span>
                            <span>
                              {moment.utc(ticketData?.StartDate).format("ddd")},{" "}
                              {moment
                                .utc(ticketData?.StartTime)
                                .format("hh:mm a")}{" "}
                            </span>
                          </div>
                          <div className="duration-price-remaining">
                            {/* <IoLocationOutline className="" /> */}
                            {/* <span>
                                {v?.venuedetails[0].venuename} ({" "}
                                {v?.venuedetails[0].address},{" "}
                                {v?.venuedetails[0].city},{" "}
                                {v?.venuedetails[0].state},{" "}
                                {v?.venuedetails[0].country},{" "}
                                {v?.venuedetails[0].zipcode})
                              </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="watch-video mt-3">
              <iframe
                width="100%"
                height="600"
                src={ticketData?.streaming_link}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                style={{ borderRadius: "15px" }}
              ></iframe>
            </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="event-top-dts">
                      <div className="calendar_box">
                        <div className="calendar">
                          <div className="month">
                            {moment.utc(EveDetail[0]?.StartDate).format("MMM")}
                          </div>
                          <div className="day">
                            {moment.utc(EveDetail[0]?.StartDate).format("DD")}
                          </div>
                        </div>
                      </div>
                      <div className="event-top-dt">
                        <h3 className="event-main-title">
                          {EveDetail[0]?.EventTitle}
                        </h3>

                        <div className="event-top-info-status">
                          <div className="publish-date mb-2">
                            <span>
                              {moment
                                .utc(EveDetail[0]?.StartDate)
                                .format("DD MMM, yyyy")}
                              &nbsp;
                            </span>
                            <span className="dot dot-size-allevent">
                              <BsDot
                                style={{
                                  width: "23px",
                                  height: "23px",
                                }}
                              />
                            </span>
                            <span>
                              {moment
                                .utc(EveDetail[0]?.StartDate)
                                .format("ddd")}
                              ,{" "}
                              {moment
                                .utc(EveDetail[0]?.StartTime)
                                .format("hh:mm a")}{" "}
                            </span>
                          </div>
                          <div className="duration-price-remaining">
                            {/* <IoLocationOutline className="" /> */}
                            {/* <span>
                                {v?.venuedetails[0].venuename} ({" "}
                                {v?.venuedetails[0].address},{" "}
                                {v?.venuedetails[0].city},{" "}
                                {v?.venuedetails[0].state},{" "}
                                {v?.venuedetails[0].country},{" "}
                                {v?.venuedetails[0].zipcode})
                              </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="watch-video mt-3">
              <iframe
                width="100%"
                height="600"
                src={selectedVirtualCategory?.streaming_link}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                style={{ borderRadius: "15px" }}
              ></iframe>
            </div>
                </div>
              </>
            )}
            {/* </> */}
            {/* //   );
            // })} */}
          </div>
        </div>
        <br />
      </div>
      <Footer />
    </>
  );
}

export default WatchEvent;
