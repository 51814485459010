import React, { useEffect, useState } from "react";
import "./AllEvent.css";
import { FiBookmark } from "react-icons/fi";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { FaBookmark } from "react-icons/fa6";
import { BsDot } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { IoLocationOutline } from "react-icons/io5";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function AllEventCard() {
  const navigate = useNavigate();
  const [allEventData, setAllEventData] = useState([]);
  const [nextToken, setNextToken] = useState("");
  const [hasMoreEvents, setHasMoreEvents] = useState();
  const [indexBookmark, setIndexBookmark] = useState("");
  const [text_BrowseMore, setTtext_BrowseMore] = useState("Browse More Events");

  const getEventsCard = () => {
    var type = "selectall";
    var API_URL = API_DOMAIN + "event/public?type=" + type;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("all events ", response.data.data.event);
        var res = response.data.data;
        const sortdata = res.event;
        sortdata.sort(
          (a, b) => new Date(a?.StartDateSort) - new Date(b?.StartDateSort)
        );
        console.log("sortdata", sortdata);
        setAllEventData(sortdata);

        setNextToken(res.nexttoken);
        setHasMoreEvents(res.hasMoreEvents);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getEventsCard();
    if (sessionStorage.getItem("token") !== null) {
      console.log("in if", sessionStorage.getItem("token"));
      getBookmarkedEvents();
    }
  }, []);

  const getMoreEventCard = (nextToken, D) => {
    var type = "selectall";
    var API_URL =
      API_DOMAIN + `event/public?type=${type}&limit=12&nexttoken=${nextToken}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data?.event;
        for (let i = 0; i < res.length; i++) {
          D.push(res[i]);
          const sortdata = D;
          sortdata.sort(
            (a, b) => new Date(a?.StartDateSort) - new Date(b?.StartDateSort)
          );
          setAllEventData(sortdata);
        }

        setHasMoreEvents(res.hasMoreEvents);
        setNextToken(res.nexttoken);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const AddBookmarkHandle = (eveID, eveData) => {
    var API_URL = API_DOMAIN + `event/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: true,
      event_details: [eveData],
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.message === "Bookmark created") {
          getBookmarkedEvents();
        }
         if (response.data.message === "Please login") {
           toast.info(response.data.message);
         }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const RemoveBookmarkHandle = (eveID) => {
    var API_URL = API_DOMAIN + `event/uncheck/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: false,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Remove", response);
        if (response.data.message === "Bookmark updated") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [bookmaredEventIdList, setBookmaredEventIdList] = useState([]);

  const getBookmarkedEvents = () => {
    var API_URL = API_DOMAIN + `event/get/bookmarks`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        // var res = response?.data?.data?.event;
        console.log("Bookmarked Events", response.data.data);
        setBookmaredEventIdList(response.data.data);
        var res = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const isBookmarked = (eventId) => {
    return bookmaredEventIdList.some(
      (event) => event.event_unique_id === eventId
    );
  };

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setTtext_BrowseMore(
        xpath.select1("/resources/string[@name='text_BrowseMore']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };

  console.log("first", bookmaredEventIdList, allEventData);
  return (
    <>
      <div
        className="scroll_comp"
        data-ref="event-filter-content"
        id="otherPages"
      >
        {allEventData.length > 0 &&
          allEventData.map((e, index) => {
            return (
              <>
                <div data-ref="mixitup-target" id={index}>
                  <div className="main-card home-scroll mt-4">
                    <span
                      className="bookmark-icon bookmark-content"
                      title=""
                      onClick={() => [setIndexBookmark(index)]}
                    >
                      {isBookmarked(e.event_unique_id) ? (
                        <>
                          <FaBookmark
                            className="bookmarkicon-fill"
                            onClick={() =>
                              RemoveBookmarkHandle(e?.event_unique_id)
                            }
                          />
                        </>
                      ) : (
                        <>
                          <FiBookmark
                            className="bookmarkicon"
                            onClick={() =>
                              AddBookmarkHandle(e?.event_unique_id, e)
                            }
                          />
                        </>
                      )}
                    </span>
                    <div
                      className="event-thumbnail"
                      onClick={() =>
                        navigate(`/event-details/${e?.event_unique_id}`)
                      }
                    >
                      <Link
                        to={`/event-details/${e?.event_unique_id}`}
                        className="thumbnail-img"
                      >
                        <img
                          src={
                            e.EventImagePath === "path"
                              ? img1
                              : e.EventImagePath
                          }
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="event-content">
                      <Link
                        to={`/event-details/${e?.event_unique_id}`}
                        className="event-title event-css"
                      >
                        {e.EventTitle}
                      </Link>
                      <div className="publish-date mb-2">
                        <span>
                          {" "}
                          {moment.utc(e?.StartDate).format("DD MMM, yyyy")}
                          &nbsp;
                        </span>
                        <span className="dot dot-size-allevent">
                          <BsDot style={{ width: "23px", height: "23px" }} />
                        </span>
                        <span>
                          {moment.utc(e.StartDate).format("ddd")},{" "}
                          {moment.utc(e?.StartTime).format("h:mmA")}{" "}
                        </span>
                      </div>
                      <div className="d-flex gap-1">
                        {e.venuedetails.length === 0 ? (
                          ""
                        ) : (
                          <>
                          <h4>
                            <IoLocationOutline className="location-icon" />
                            </h4>
                            <span>
                              {e?.venuedetails[0].venuename} ({" "}
                              {e?.venuedetails[0].address},{" "}
                              {e?.venuedetails[0].city},{" "}
                              {e?.venuedetails[0].state},{" "}
                              {e?.venuedetails[0].country},{" "}
                              {e?.venuedetails[0].zipcode})
                            </span>
                           
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </>
            );
          })}
        {hasMoreEvents ? (
          <>
            <div className="browse-btn">
              <button
                onClick={() => getMoreEventCard(nextToken, allEventData)}
                className="main-btn btn-hover "
              >
                {text_BrowseMore}
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <ToastContainer />
    </>
  );
}

export default AllEventCard;
