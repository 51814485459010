import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Header/Header";
import "../LeagueCreate/LeagueCreate.css";
import mainlogo from "../../assets/img/banner.jpg";
import { MdDateRange } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { BsFilterLeft } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../Footer/Footer";
import { FaXTwitter } from "react-icons/fa6";
import {
  FaFacebookSquare,
  FaYoutube,
  FaInstagram,
  FaTiktok,
  FaLink,
} from "react-icons/fa";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { toast } from "react-toastify";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function LeaguePage() {
  const [searchParams] = useSearchParams();
  const leagueid = searchParams.get("leagueid");
  const navigate = useNavigate();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [startDate, setStartDate] = useState(tomorrow);
  const [leagueData, setLeagueData] = useState([]);
  const [leagueEvents, setLeagueEvents] = useState([]);
  const [aboutContainer, setAboutContainer] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const [teamList, setTeamList] = useState([]);
  const [events, setevents] = useState([]);
  const [dataforfilter, setDataforfilter] = useState([]);
  const [eventsallgame, seteventsallgame] = useState([]);
  const [calendarview, setCalendarview] = useState(false);

  const [text_about, setText_about] = useState("About");
  const [text_teams, setText_teams] = useState("Teams");

  // new
  const [text_calendar, setText_calendar] = useState("Calendar");
  const [text_all_games, setText_all_games] = useState("All Games");
  const [text_calendarview, setText_calendarview] = useState("Calendar View");
  const [text_Sort, setText_Sort] = useState("Sort");
  const [text_Upcoming, setText_Upcoming] = useState("Upcoming");
  const [text_mostpopular, setText_mostpopular] = useState("Most Popular");
  const [text_Recommended, setText_Recommended] = useState("Recommended");
  const [text_noeventfound, setText_noeventfound] = useState("No Event Found");
  const [text_buytickets, setText_buytickets] = useState("Buy Tickets");
  const [text_location, setText_location] = useState("Location");
  const [text_search, setText_search] = useState("Search");
  const [text_home, setText_home] = useState("Home");
  const [text_leagues, setText_leagues] = useState("Leagues");

  const Breadcrumbs = ({ breadcrumbs }) => {
    return (
      <div className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <span key={index}>
            {index > 0 && <span className="breadcrumbs-separator">/</span>}
            {breadcrumb.link ? (
              <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
            ) : (
              <span>{breadcrumb.label}</span>
            )}
          </span>
        ))}
      </div>
    );
  };
  const breadcrumbs = [
    { label: text_home, link: "/" },
    { label: text_leagues, link: `/league-page?leagueid=${leagueid}` },
    { label: leagueData?.leaguename },
  ];
  const handleDatesSet = (dateInfo) => {
    const startDate = new Date(dateInfo.start); // Start of visible range
    const formattedMonth = moment(startDate).format("MM-YYYY"); // Format for the API
    console.log(formattedMonth);

    const url =
      API_DOMAIN +
      `event/get/byleague/calendar?leagueid=${leagueid}&month=${formattedMonth}`;
    console.log(url);

    leagueCalendarData(url, formattedMonth);
  };
  const leagueCalendarData = (url, formattedMonth) => {
    var API_URL = url;
    var headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("monthly calendar", response.data.data[formattedMonth]);
        var res = response.data.data[formattedMonth];
        const formattedEvents = res.map((event) => ({
          id: event.event_unique_id,
          title: event.EventTitle, // Adjust based on your API response
          start: event.StartDate, // Ensure date is in ISO 8601 format
          end: event.EndDate,
          starttime: event.StartTime, // Ensure date is in ISO 8601 format
          endtime: event.EndTime, // Optional
          url: `/event-details/${event.event_unique_id}`,
        }));
        console.log(formattedEvents);

        setevents(formattedEvents);
      })
      .catch(function (error) {
        console.log("API Not Response", error.response.data.message);
      })
      .then(function () {});
  };
  const handleEventClick = (info) => {
    info.jsEvent.preventDefault(); // Prevent default behavior
    const eventUrl = info.event.url; // Access the custom URL
    console.log(eventUrl);

    if (eventUrl) {
      navigate(eventUrl); // Redirect to the event details page
    }
  };
  const getLeagueDetails = () => {
    var API_URL = API_DOMAIN + `league/get?leagueid=${leagueid}`;
    var headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("league data ", response.data.data);
        var res = response.data.data;
        setLeagueData(res);
        setTeamList(res.leagueteams);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const getLeagueEvent = (id) => {
    var API_URL = API_DOMAIN + `event/get/byleagueid?leagueid=${leagueid}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log(" league events", response.data.data[0]);
        setLeagueEvents(response.data.data[0]);
        setDataforfilter(response.data.data[0]);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getLeagueDetails();
    getLeagueEvent();
  }, [leagueid]);
  const handleDateSetAllgame = (dateInfo) => {
    const startDate = new Date(dateInfo.start); // Start of visible range
    const formattedMonth = moment(startDate).format("MM YYYY"); // Format for the API
    console.log(formattedMonth);

    const url = API_DOMAIN + `event/get/byleagueid?leagueid=${leagueid}`;
    console.log(url);

    getCalendarDataAllgame(url);
  };
  const getCalendarDataAllgame = (url) => {
    var API_URL = url;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("month wise events ", response.data.data[0]);

        var res = response.data.data[0];
        const formattedEvents = res.map((event) => ({
          id: event.event_unique_id,
          title: event.EventTitle, // Adjust based on your API response
          start: event.StartDate, // Ensure date is in ISO 8601 format
          end: event.EndDate,
          starttime: event.StartTime, // Ensure date is in ISO 8601 format
          endtime: event.EndTime, // Optional
          url: `/event-details/${event.event_unique_id}`,
        }));
        console.log(formattedEvents);

        seteventsallgame(formattedEvents);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const handleEventClickAllgame = (info) => {
    info.jsEvent.preventDefault(); // Prevent default behavior
    const eventUrl = info.event.url; // Access the custom URL
    console.log(eventUrl);

    if (eventUrl) {
      navigate(eventUrl); // Redirect to the event details page
    }
  };
  const dateSelectFilter = (date) => {
    console.log(moment.utc(date).format("DD MMM, yyyy"));
    const arr = dataforfilter.filter(
      (e) =>
        moment.utc(e?.StartDate).format("DD MMM, yyyy") ===
        moment.utc(date).format("DD MMM, yyyy")
    );
    setLeagueEvents(arr);
    console.log(arr);
  };
  const filterDataTeam = (selteamid) => {
    const arr = dataforfilter.filter(
      (e) => e.team02 === selteamid || e.team01 === selteamid
    );
    console.log(arr);
  };

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_about(
        xpath.select1("/resources/string[@name='text_about']", doc).firstChild
          .data
      );
      setText_teams(
        xpath.select1("/resources/string[@name='text_teams']", doc).firstChild
          .data
      );
      setText_calendar(
        xpath.select1("/resources/string[@name='text_calendar']", doc)
          .firstChild.data
      );
      setText_all_games(
        xpath.select1("/resources/string[@name='text_all_games']", doc)
          .firstChild.data
      );
      setText_calendarview(
        xpath.select1("/resources/string[@name='text_calendarview']", doc)
          .firstChild.data
      );
      setText_Sort(
        xpath.select1("/resources/string[@name='text_Sort']", doc).firstChild
          .data
      );
      setText_Upcoming(
        xpath.select1("/resources/string[@name='text_Upcoming']", doc)
          .firstChild.data
      );
      setText_mostpopular(
        xpath.select1("/resources/string[@name='text_mostpopular']", doc)
          .firstChild.data
      );
      setText_Recommended(
        xpath.select1("/resources/string[@name='text_Recommended']", doc)
          .firstChild.data
      );
      setText_noeventfound(
        xpath.select1("/resources/string[@name='text_noeventfound']", doc)
          .firstChild.data
      );
      setText_buytickets(
        xpath.select1("/resources/string[@name='text_buytickets']", doc)
          .firstChild.data
      );
      setText_location(
        xpath.select1("/resources/string[@name='text_location']", doc)
          .firstChild.data
      );
      setText_search(
        xpath.select1("/resources/string[@name='text_search']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_leagues(
        xpath.select1("/resources/string[@name='text_leagues']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="wrapper" id="otherPages">
        <div className="hero-banner-landing">
          <img className="team-landing-main-img" src={mainlogo} alt="" />
          <div className="container d-flex justify-content-between">
            <div className="league-page-search league-page-search_league">
              <input
                id="outlined-basic"
                variant="outlined"
                className="league-page-search-input"
                fullWidth
                placeholder={text_search}
                label="Search"
              />
              <div className="league-page-search-icon">
                <FaSearch />
              </div>
            </div>
            <div className="league-page-search-breadcrumb league-page-search-breadcrumb-legue">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          </div>
          <div className="league-page-search-logo container">
            <img src={leagueData?.leagueLogo} alt="" className="team-logo" />
          </div>
        </div>

        <div className="container">
          <div>
            {" "}
            <h2 className="container ">
              <b>{leagueData?.leaguename}</b>
            </h2>
            <p className="container">{leagueData?.leagueDescription}</p>
          </div>
          <div className="d-flex gap-3 mt-4">
            <button
              className={
                showCalendar === true ? "new-main-tab selected" : "new-main-tab"
              }
              data-bs-toggle="tab"
              data-bs-target="#tab-01"
              type="button"
              role="tab"
              aria-controls="tab-01"
              aria-selected="true"
              onClick={() => [setAboutContainer(false), setShowCalendar(true)]}
            >
              {text_calendar}
            </button>
            <button
              className={
                aboutContainer === true
                  ? "new-main-tab selected"
                  : "new-main-tab"
              }
              data-bs-toggle="tab"
              data-bs-target="#tab-02"
              type="button"
              role="tab"
              aria-controls="tab-02"
              aria-selected="false"
              onClick={() => [setAboutContainer(true), setShowCalendar(false)]}
            >
              {text_about}
            </button>
          </div>
        </div>
        {aboutContainer && (
          <>
            {leagueData?.twitter === "" &&
            leagueData?.Facebook === "" &&
            leagueData?.YouTube === "" &&
            leagueData?.TikTok === "" &&
            leagueData?.Instagram === "" &&
            leagueData?.website === "" ? (
              <></>
            ) : (
              <>
                <div className="league-page-about container">
                  {leagueData?.twitter === "" ? (
                    <></>
                  ) : (
                    <>
                      <Link
                        to={`https://x.com/${leagueData?.twitter}`}
                        target="_blank"
                        className="about-social-card"
                      >
                        <FaXTwitter className="about-social-card-icon" />
                        <span>@{leagueData?.twitter}</span>
                      </Link>
                    </>
                  )}
                  {leagueData?.Facebook === "" ? (
                    <></>
                  ) : (
                    <>
                      <Link
                        to={`https://www.facebook.com/${leagueData?.Facebook}`}
                        target="_blank"
                        className="about-social-card"
                      >
                        <FaFacebookSquare className="about-social-card-icon" />
                        <span>{leagueData?.Facebook}</span>
                      </Link>
                    </>
                  )}
                  {leagueData?.YouTube === "" ? (
                    <></>
                  ) : (
                    <>
                      <Link
                        to={`https://www.youtube.com/${leagueData?.YouTube}`}
                        target="_blank"
                        className="about-social-card"
                      >
                        <FaYoutube className="about-social-card-icon" />
                        <span>{leagueData?.YouTube}</span>
                      </Link>
                    </>
                  )}
                  {leagueData?.Instagram === "" ? (
                    <></>
                  ) : (
                    <>
                      <Link
                        to={`https://www.instagram.com/${leagueData?.Instagram}`}
                        target="_blank"
                        className="about-social-card"
                      >
                        <FaInstagram className="about-social-card-icon" />
                        <span>{leagueData?.Instagram}</span>
                      </Link>
                    </>
                  )}
                  {leagueData?.TikTok === "" ? (
                    <></>
                  ) : (
                    <>
                      <Link
                        to={`https://www.tiktok.com/${leagueData?.TikTok}`}
                        target="_blank"
                        className="about-social-card"
                      >
                        <FaTiktok className="about-social-card-icon" />
                        <span>@{leagueData?.TikTok}</span>
                      </Link>
                    </>
                  )}
                  {leagueData?.website === "" ? (
                    <></>
                  ) : (
                    <>
                      <Link
                        to={leagueData?.website}
                        target="_blank"
                        className="about-social-card"
                      >
                        <FaLink className="about-social-card-icon" />
                        <span>{leagueData?.website}</span>
                      </Link>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {showCalendar ? (
          <>
            <div className="container">
              <h1 className="mt-4">{text_calendar}</h1>
              <div className="month-calendar-style">
                <FullCalendar
                  // showNonCurrentDates={false}
                  events={events}
                  defaultView="dayGridMonth"
                  plugins={[dayGridPlugin, interactionPlugin, momentPlugin]}
                  dayHeaderContent={(args) => {
                    // Format weekdays as 'Mon', 'Tue', etc.
                    return new Intl.DateTimeFormat("en", {
                      weekday: "short",
                    }).format(args.date);
                  }}
                  dayCellContent={(args) => {
                    // Format and display only the day number
                    return args.date.getDate(); // Get only the day (e.g., 1, 2, 3, etc.)
                  }}
                  weekends={true}
                  eventClick={handleEventClick}
                  datesSet={handleDatesSet}
                  eventContent={(eventInfo) => (
                    <div className="calendar-event">
                      <h4>
                        <span className="calendar-event-title pb-3">
                          {eventInfo.event.title}
                        </span>
                        <br />
                        <span className="calendar-event-time">
                          {moment(eventInfo.event.starttime).format("h:mmA")}
                        </span>
                      </h4>
                    </div>
                  )} // Customize event rendering if needed
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-xl-12 col-lg-12 col-md-12 container">
              <div className="main-title mt-3">
                <h4 className="fs-2 fw-bold">{text_teams}</h4>
              </div>
            </div>
            <div className="league-page-team-container container">
              {teamList.map((item, index) => {
                return (
                  <div className="league-page-team-card" key={index}>
                    <img
                      src={item.teamLogo}
                      alt=""
                      className="league-page-team-card-img"
                    />
                    <p className="league-page-team-card-name text-black">
                      {item.leagueteame}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="main-title position-relative container mt-5">
              <h4 className="fs-2 fw-bold">{text_all_games}</h4>
              <div className="d-flex justify-content-between flex-wrap gap-2">
                <div className="d-flex gap-3 ">
                  <div className="form-control filter-team d-flex">
                    <MdDateRange className="icon-team" />
                    <DatePicker
                      className="h_40 mx-1 form-control form-control-BR w-100"
                      selected={startDate}
                      dateFormat="dd MMM,yyyy"
                      onChange={(date) => [
                        setStartDate(date),
                        dateSelectFilter(date),
                      ]}
                    />
                  </div>
                  <div className="form-control filter-team d-flex">
                    <IoLocationOutline className="icon-team" />
                    <input
                      type="text"
                      className="h_40 form-control form-control-BR w-100"
                      name=""
                      id=""
                      placeholder={text_location}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap justify-content-end gap-3">
                  <div className="form-control filter-team d-flex">
                    <BsFilterLeft className="icon-team" />
                    <select
                      name=""
                      id=""
                      onChange={(e) => filterDataTeam(e.target.value)}
                      className="h_40 form-control form-control-BR w-100"
                    >
                      <option value=""> {text_teams}</option>
                      {teamList.map((v) => {
                        return (
                          <>
                            <option
                              value={v.teamid}
                              className="form-control-BR"
                            >
                              {" "}
                              {v.leagueteame}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-control filter-team d-flex">
                    <BsFilterLeft className="icon-team" />
                    {calendarview === true ? (
                      <>
                        <button
                          className="h_40 form-control form-control-BR w-100 text-start"
                          onClick={() => setCalendarview(false)}
                        >
                          {text_all_games}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="h_40 form-control form-control-BR w-100 text-start"
                          onClick={() => setCalendarview(true)}
                        >
                          {text_calendarview}
                        </button>
                      </>
                    )}
                  </div>
                  <div className="form-control filter-team d-flex">
                    <BsFilterLeft className="icon-team" />
                    <select
                      name=""
                      id=""
                      className="h_40 form-control form-control-BR w-100"
                    >
                      <option value="">{text_Sort}</option>
                      <option value="upcoming" className="form-control-BR">
                        {" "}
                        {text_Upcoming}
                      </option>
                      <option value="mostpopular" className="form-control-BR">
                        {" "}
                        {text_mostpopular}
                      </option>
                      <option value="recommended" className="form-control-BR">
                        {" "}
                        {text_Recommended}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-3">
              {calendarview === true ? (
                <>
                  <div className="month-calendar-style">
                    <FullCalendar
                      timeZone="UTC"
                      plugins={[dayGridPlugin, interactionPlugin]}
                      initialView="dayGridMonth"
                      events={eventsallgame}
                      eventClick={handleEventClickAllgame}
                      datesSet={handleDateSetAllgame}
                      dayHeaderContent={(args) => {
                        // Format weekdays as 'Mon', 'Tue', etc.
                        return new Intl.DateTimeFormat("en", {
                          weekday: "short",
                        }).format(args.date);
                      }}
                      dayCellContent={(args) => {
                        // Format and display only the day number
                        return args.date.getDate(); // Get only the day (e.g., 1, 2, 3, etc.)
                      }}
                      weekends={true} // Show weekends
                      eventContent={(eventInfo) => (
                        <div className="calendar-event">
                          <h4 className="calendar-event-title mt-2">
                            {eventInfo.event.title}
                            {/* <br />
                            <p>
                              {" "}
                              {moment(eventInfo.event.starttime).format(
                                "h:mmA"
                              )}
                            </p> */}
                          </h4>
                        </div>
                      )}
                    />
                  </div>
                </>
              ) : (
                <>
                  {leagueEvents.length === 0 ? (
                    <>
                      <div className="container text-center mt-5">
                        <h2>{text_noeventfound}</h2>{" "}
                      </div>
                    </>
                  ) : (
                    <>
                      {leagueEvents.map((event) => {
                        return (
                          <>
                            <div className="new-card-team d-flex flex-wrap justify-content-between mt-3">
                              <div className="d-flex gap-2 flex-wrap">
                                <img
                                  src={
                                    event?.EventImagePath === "path"
                                      ? img1
                                      : event?.EventImagePath
                                  }
                                  alt=""
                                  className="card-img-team"
                                />
                                <div className="m-2 responsive-team-card">
                                  <h4>{event?.EventTitle}</h4>
                                  <span>
                                    {moment
                                      .utc(event?.StartDate)
                                      .format("DD MMM, YYYY • ddd")}
                                    ,{" "}
                                    {moment
                                      .utc(event?.StartTime)
                                      .format("h:mmA")}
                                  </span>{" "}
                                  <br />
                                  {event.venuedetails.length === 0 ? (
                                    ""
                                  ) : (
                                    <>
                                        <IoLocationOutline className="location-icon" />
                                      <span>
                                        {event?.venuedetails[0].venuename} ({" "}
                                        {event?.venuedetails[0].address},{" "}
                                        {event?.venuedetails[0].city},{" "}
                                        {event?.venuedetails[0].state},{" "}
                                        {event?.venuedetails[0].country},{" "}
                                        {event?.venuedetails[0].zipcode})
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                              <button
                                className="buybtn-team mx-3"
                                onClick={() =>
                                  navigate(
                                    `/event-details/${event?.event_unique_id}`
                                  )
                                }
                              >
                                {text_buytickets}
                              </button>
                            </div>{" "}
                          </>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default LeaguePage;
